import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";

import SecTitle from "../SecTitle";

import Times from "./Times";

import close from "../../../images/close-modal.png";
import remove from "../../../images/remove.png";

import "../../../styles/timeSlots.css";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default class TimeSlots extends Component {
  state = {
    listingId: "",
    openingHours: [],

    day: "-- Select Day --",
    noOfSlots: "-- Select No of Time Slots --",

    slot11: "0800",
    slot12: "2200",
    slot21: "0800",
    slot22: "2200",
    slot31: "0800",
    slot32: "2200",
    slot41: "0800",
    slot42: "2200",
    slot51: "0800",
    slot52: "2200",

    showslot11: false,
    showslot12: false,
    showslot21: false,
    showslot22: false,
    showslot31: false,
    showslot32: false,
    showslot41: false,
    showslot42: false,
    showslot51: false,
    showslot52: false,

    // show - hide
    status: "",

    // erors
    dayError: "",
    noOfSlotsError: "",

    // loading
    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    // get open hours
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          openingHours: res.data[0].deliveryDetail.orderAcceptingHours
            ? res.data[0].deliveryDetail.orderAcceptingHours
            : [],
          status: res.data[0].status,
        });

        const arr = res.data[0].deliveryDetail.orderAcceptingHours
          ? res.data[0].deliveryDetail.orderAcceptingHours.sort(
              (a, b) => days.indexOf(a.day) - days.indexOf(b.day)
            )
          : [];
        this.setState({ openingHours: arr });
      })
      .catch((err) => console.log(err));
  };

  // validate
  validate = () => {
    let dayError = "";
    let noOfSlotsError = "";

    if (this.state.day === "-- Select Day --") {
      console.log("bye");
      dayError = "Day is required";
    }

    if (this.state.noOfSlots === "-- Select No of Time Slots --") {
      noOfSlotsError = "No of time slots are required";
    }

    if (dayError || noOfSlotsError) {
      this.setState({
        dayError,
        noOfSlotsError,
      });
      return false;
    }

    return true;
  };

  addTimeSlots = () => {
    if (this.validate()) {
      this.setState({ dayError: "", noOfSlotsError: "" });

      this.slot1();
    }
  };

  slot1 = () => {
    let obj = {
      closeTime: this.state.slot12,
      day: this.state.day,
      openTime: this.state.slot11,
    };

    // sort days
    const arr = [...this.state.openingHours, obj].sort(
      (a, b) => days.indexOf(a.day) - days.indexOf(b.day)
    );

    if (this.state.noOfSlots > 1) {
      this.setState({ openingHours: arr }, () => this.slot2());
    } else {
      this.setState({
        openingHours: arr,
        day: "-- Select Day --",
        noOfSlots: "-- Select No of Time Slots --",
      });

      document.getElementById("time-slot-modal-close").click();
    }
  };

  slot2 = () => {
    let obj = {
      closeTime: this.state.slot22,
      day: this.state.day,
      openTime: this.state.slot21,
    };

    // sort days
    const arr = [...this.state.openingHours, obj].sort(
      (a, b) => days.indexOf(a.day) - days.indexOf(b.day)
    );

    if (this.state.noOfSlots > 2) {
      this.setState({ openingHours: arr }, () => this.slot3());
    } else {
      this.setState({
        openingHours: arr,
        day: "-- Select Day --",
        noOfSlots: "-- Select No of Time Slots --",
      });

      document.getElementById("time-slot-modal-close").click();
    }
  };

  slot3 = () => {
    let obj = {
      closeTime: this.state.slot32,
      day: this.state.day,
      openTime: this.state.slot31,
    };

    // sort days
    const arr = [...this.state.openingHours, obj].sort(
      (a, b) => days.indexOf(a.day) - days.indexOf(b.day)
    );

    if (this.state.noOfSlots > 3) {
      this.setState({ openingHours: arr }, () => this.slot4());
    } else {
      this.setState({
        openingHours: arr,
        day: "-- Select Day --",
        noOfSlots: "-- Select No of Time Slots --",
      });

      document.getElementById("time-slot-modal-close").click();
    }
  };

  slot4 = () => {
    let obj = {
      closeTime: this.state.slot42,
      day: this.state.day,
      openTime: this.state.slot41,
    };

    // sort days
    const arr = [...this.state.openingHours, obj].sort(
      (a, b) => days.indexOf(a.day) - days.indexOf(b.day)
    );

    if (this.state.noOfSlots > 4) {
      this.setState({ openingHours: arr }, () => this.slot5());
    } else {
      this.setState({
        openingHours: arr,
        day: "-- Select Day --",
        noOfSlots: "-- Select No of Time Slots --",
      });

      document.getElementById("time-slot-modal-close").click();
    }
  };

  slot5 = () => {
    let obj = {
      closeTime: this.state.slot52,
      day: this.state.day,
      openTime: this.state.slot51,
    };

    // sort days
    const arr = [...this.state.openingHours, obj].sort(
      (a, b) => days.indexOf(a.day) - days.indexOf(b.day)
    );

    this.setState({
      openingHours: arr,
      day: "-- Select Day --",
      noOfSlots: "-- Select No of Time Slots --",
    });

    document.getElementById("time-slot-modal-close").click();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSave = () => {
    // set loading
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/order_accepting_hours`,
        this.state.openingHours,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });

        success();

        window.location.reload();
      })
      .catch((err) => {
        this.setState({ loading: false });
        fail();
      });
  };

  deleteSlot = (index) => {
    const filter = this.state.openingHours.filter((filter, i) => i !== index);

    this.setState({ openingHours: filter });
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    // get open hours
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          openingHours: res.data[0].deliveryDetail.orderAcceptingHours
            ? res.data[0].deliveryDetail.orderAcceptingHours
            : [],
          loadingCancel: false,
        });

        const arr = res.data[0].deliveryDetail.orderAcceptingHours
          ? res.data[0].deliveryDetail.orderAcceptingHours.sort(
              (a, b) => days.indexOf(a.day) - days.indexOf(b.day)
            )
          : [];
        this.setState({ openingHours: arr });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false });
      });
  };

  closeModal = () => {
    this.setState({
      day: "-- Select Day --",
      noOfSlots: "-- Select No of Time Slots --",

      slot11: "6:00 am",
      slot12: "10:00 pm",
      slot21: "6:00 am",
      slot22: "10:00 pm",
      slot31: "6:00 am",
      slot32: "10:00 pm",
      slot41: "6:00 am",
      slot42: "10:00 pm",
      slot51: "6:00 am",
      slot52: "10:00 pm",

      showslot11: false,
      showslot12: false,
      showslot21: false,
      showslot22: false,
      showslot31: false,
      showslot32: false,
      showslot41: false,
      showslot42: false,
      showslot51: false,
      showslot52: false,

      // erors
      dayError: "",
      noOfSlotsError: "",
    });

    document.getElementById("time-slot-modal-close").click();
  };

  render() {
    return (
      <div className="settings-container">
        <div className="sec">
          <SecTitle
            title="Open Days with Time Slots"
            titleDesc="Set open days and time slots of your restaurant"
          />
          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <form className="setting-form">
                <div className="form-row mb-3">
                  <div className="form-group mb-0 p-0 input-field">
                    <label className="title ml-0 mb-0">Time Slots</label>
                    <span
                      className="change-settings-btn"
                      data-toggle="modal"
                      data-target="#time-slots-modal"
                    >
                      Add Time Slots
                    </span>
                    <p className="inform">
                      The times when users can request orders
                    </p>
                  </div>
                </div>
              </form>
              <table className="table-open-hours">
                <tbody>
                  {this.state.openingHours.map((slot, i) => (
                    <tr key={i}>
                      <td>{slot.day}</td>
                      <td>
                        <React.Fragment>
                          <button className="time" disabled>
                            {slot.openTime.slice(0, 2) > 12
                              ? slot.openTime.slice(0, 2) - 12
                              : slot.openTime.slice(0, 2) === "00"
                              ? "12"
                              : slot.openTime.slice(0, 2)}
                            :{slot.openTime.slice(2, 4)}{" "}
                            {slot.openTime.slice(0, 2) > 11 ? "pm" : "am"}
                            {/* {slot.openTime.slice(0, 2) < 13
                              ? Math.round(slot.openTime.slice(0, 2))
                              : slot.openTime.slice(0, 2) - 12}
                            :{slot.openTime.slice(2, 4)}
                            {slot.openTime.slice(0, 2) < 13 ? " am" : " pm"} */}
                          </button>

                          <span className="to-time-devider">to</span>

                          <button className="time" disabled>
                            {slot.closeTime.slice(0, 2) > 12
                              ? slot.closeTime.slice(0, 2) - 12
                              : slot.closeTime.slice(0, 2) === "00"
                              ? "12"
                              : slot.closeTime.slice(0, 2)}
                            :{slot.closeTime.slice(2, 4)}{" "}
                            {slot.closeTime.slice(0, 2) > 11 ? "pm" : "am"}
                            {/* {slot.closeTime.slice(0, 2) < 13
                              ? Math.round(slot.closeTime.slice(0, 2))
                              : slot.closeTime.slice(0, 2) - 12}
                            :{slot.closeTime.slice(2, 4)}
                            {slot.closeTime.slice(0, 2) < 13 ? " am" : " pm"} */}
                          </button>
                        </React.Fragment>
                      </td>
                      <td>
                        <img
                          src={remove}
                          alt="delete"
                          className="time-remove-btn"
                          onClick={() => this.deleteSlot(i)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {this.state.openingHours.length > 0 && (
            <div className="row m-0 content-container pt-3">
              <div className="col-md p-0">
                <button
                  type="button"
                  id="location-setting"
                  className="btn setting-save-btn float-right"
                  onClick={this.handleSave}
                >
                  {this.state.loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                      style={{ margin: 0 }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}

                  {this.state.loading ? "" : "Save"}
                </button>
                <button
                  type="button"
                  className="btn setting-cancel-btn float-right"
                  onClick={this.handleCancel}
                >
                  {this.state.loadingCancel ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                      style={{ color: "#4f4f4f", margin: 0 }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}

                  {this.state.loadingCancel ? "" : "Cancel"}
                </button>
              </div>
            </div>
          )}
          <div
            className="modal fade add-cost-com"
            id="time-slots-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="add-cost-com"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="add-cost-com"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Add Time Slots
                  </h5>

                  <img
                    src={close}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="time-slot-modal-close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-row">
                      <div className="form-group col mb-3">
                        <label htmlFor="name" className="title ml-0">
                          Day
                        </label>
                        <select
                          name="day"
                          className={classnames("form-control", {
                            "is-invalid": this.state.dayError,
                          })}
                          value={this.state.day}
                          onChange={this.handleChange}
                          id="day"
                        >
                          <option selected disabled>
                            -- Select Day --
                          </option>
                          <option>Sunday</option>
                          <option>Monday</option>
                          <option>Tuesday</option>
                          <option>Wednesday</option>
                          <option>Thursday</option>
                          <option>Friday</option>
                          <option>Saturday</option>
                        </select>
                        <div className="invalid-feedback">
                          {this.state.dayError}
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col mb-3">
                        <label htmlFor="no-of-slots" className="title ml-0">
                          Slots Per Day
                        </label>
                        <select
                          name="noOfSlots"
                          className={classnames("form-control", {
                            "is-invalid": this.state.noOfSlotsError,
                          })}
                          value={this.state.noOfSlots}
                          onChange={this.handleChange}
                          id="no-of-slots"
                        >
                          <option selected disabled>
                            -- Select No of Time Slots --
                          </option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                        <div className="invalid-feedback">
                          {this.state.noOfSlotsError}
                        </div>
                      </div>
                    </div>

                    {this.state.noOfSlots === "1" ||
                    this.state.noOfSlots === "2" ||
                    this.state.noOfSlots === "3" ||
                    this.state.noOfSlots === "4" ||
                    this.state.noOfSlots === "5" ? (
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="name" className="title ml-0 d-block">
                            Time Slots 1
                          </label>

                          <select
                            name="slot11"
                            className="form-control time-dropdown"
                            value={this.state.slot11}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>

                          <span className="px-3">to</span>

                          <select
                            name="slot12"
                            className="form-control time-dropdown"
                            value={this.state.slot12}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {this.state.noOfSlots === "2" ||
                    this.state.noOfSlots === "3" ||
                    this.state.noOfSlots === "4" ||
                    this.state.noOfSlots === "5" ? (
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="name" className="title ml-0 d-block">
                            Time Slots 2
                          </label>

                          <select
                            name="slot21"
                            className="form-control time-dropdown"
                            value={this.state.slot21}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>

                          <span className="px-3">to</span>
                          <select
                            name="slot22"
                            className="form-control time-dropdown"
                            value={this.state.slot22}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {this.state.noOfSlots === "3" ||
                    this.state.noOfSlots === "4" ||
                    this.state.noOfSlots === "5" ? (
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="name" className="title ml-0 d-block">
                            Time Slots 3
                          </label>

                          <select
                            name="slot31"
                            className="form-control time-dropdown"
                            value={this.state.slot31}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>

                          <span className="px-3">to</span>

                          <select
                            name="slot32"
                            className="form-control time-dropdown"
                            value={this.state.slot32}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {this.state.noOfSlots === "4" ||
                    this.state.noOfSlots === "5" ? (
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="name" className="title ml-0 d-block">
                            Time Slots 4
                          </label>

                          <select
                            name="slot41"
                            className="form-control time-dropdown"
                            value={this.state.slot41}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>

                          <span className="px-3">to</span>

                          <select
                            name="slot42"
                            className="form-control time-dropdown"
                            value={this.state.slot42}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {this.state.noOfSlots === "5" && (
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="name" className="title ml-0 d-block">
                            Time Slots 5
                          </label>

                          <select
                            name="slot51"
                            className="form-control time-dropdown"
                            value={this.state.slot51}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>

                          <span className="px-3">to</span>

                          <select
                            name="slot52"
                            className="form-control time-dropdown"
                            value={this.state.slot52}
                            onChange={this.handleChange}
                          >
                            <Times />
                          </select>
                        </div>
                      </div>
                    )}
                  </form>
                  <div className="row mt-4 m-0 ">
                    <div className="col-md p-0">
                      <button
                        type="button"
                        id="location-setting"
                        className="btn setting-save-btn float-right"
                        onClick={this.addTimeSlots}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="btn setting-cancel-btn float-right"
                        onClick={this.closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
