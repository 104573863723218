import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import "animate.css";
import "react-notifications-component/dist/theme.css";

import { Lines } from "react-preloaders";

import Breadcrumb from "../../Breadcrumb";
import SecTitle from "../SecTitle";
import CompleteInfo from "../CompleteInfo";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";
import Chips, { Chip } from "react-chips";
import { getAllCuisines } from "../../api/getCuisines";

import "../../../styles/alert.css";

export default class General extends Component {
  state = {
    listingId: "",
    name: "",
    phone: "",
    description: "",

    // errors
    nameError: "",
    phoneError: "",

    // show - hide
    status: "",
    isComRest: true,

    // loading
    loading: false,
    loadingCancel: false,

    // pre loading
    preLoading: true,

    cuisines: [],
    allCuisines: [],
  };

  componentDidMount = () => {
    this.getListing();
    getAllCuisines(
      (response) => {
        const data = response.data.v1;
        const cuisinesKey = ["other_cuisines", "popular_cuisines"];
        const tempCuisines = data
          .filter(({ key }) => cuisinesKey.includes(key))
          .map((value) => value.values);
        const merged = [].concat.apply([], tempCuisines);
        const cuisines = merged.map((item) => item.key1);
        this.setState({ allCuisines: cuisines });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          name: res.data[0].name,
          phone: res.data[0].phone,
          description: res.data[0].description,
          status: res.data[0].status,
          loadingCancel: false,
          preLoading: false,
          cuisines: res.data[0].cuisines.map((item) => item.name),
        });

        if (
          res.data[0].address.latitude !== 0 &&
          res.data[0].address.longitude !== 0 &&
          res.data[0].deliveryDetail.orderAcceptingHours &&
          res.data[0].deliveryDetail.orderAcceptingHours.length > 0
        ) {
          this.setState({ isComRest: true });
        } else {
          this.setState({ isComRest: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false, preLoading: false });
      });
  };

  // get input values
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // validate
  validate = () => {
    let nameError = "";
    let phoneError = "";

    if (this.state.name === "") {
      nameError = "Business name is required";
    }

    if (this.state.phone === "") {
      phoneError = "Public phone number is required";
    }

    if (nameError || phoneError) {
      this.setState({
        nameError,
        phoneError,
      });
      return false;
    }

    return true;
  };

  handleSave = () => {
    if (this.validate()) {
      this.setState({ nameError: "", phoneError: "" });

      const editListing = {
        name: this.state.name,
        phone: this.state.phone,
        description: this.state.description,
        cuisines: this.state.cuisines.map((item) => {
          return { name: item };
        }),
      };

      // set loading
      this.setState({ loading: true });
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/listing/${this.state.listingId}`,
          editListing,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });

          success();
        })
        .catch((err) => {
          this.setState({ loading: false });

          fail();
        });
    }
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true, nameError: "", phoneError: "" });
    this.getListing();
  };

  onChangeCuisines = (chips) => {
    this.setState({ cuisines: chips });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#fff"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            <div className="settings-container">
              <Breadcrumb mainPage="Settings" subPage="Restaurant Info" />
              <div className="sec">
                <SecTitle
                  title="General Info"
                  titleDesc="Basic information that your customers see as they enter your website"
                />
                <div className="row m-0 content-container">
                  <div className="col p-0">
                    <form className="setting-form">
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label htmlFor="name" className="title ml-0">
                            Name
                          </label>
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.nameError,
                            })}
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="invalid-feedback">
                            {this.state.nameError}
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label htmlFor="name" className="title ml-0">
                            Public Phone Number
                          </label>
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.phoneError,
                            })}
                            id="phone"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="invalid-feedback">
                            {this.state.phoneError}
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group mb-0 p-0  w-100 chips-div">
                          <label htmlFor="name" className="title ml-0">
                            Cuisines
                          </label>
                          <Chips
                            placeholder="Type a Cuisine"
                            value={this.state.cuisines}
                            onChange={this.onChangeCuisines}
                            suggestionsFilter={(value) =>
                              this.state.allCuisines
                            }
                            suggestions={this.state.allCuisines}
                          />
                          <div className="invalid-feedback">
                            {this.state.phoneError}
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group w-100">
                          <label
                            htmlFor="description"
                            className="title ml-0 mb-0"
                          >
                            Description
                          </label>
                          <p className="inform">
                            Optional (Max 4000 characters)
                          </p>
                          <textarea
                            className="form-control"
                            id="description"
                            rows="6"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row m-0 content-container">
                  <div className="col-md p-0">
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={this.handleSave}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                          style={{ margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Save"}
                    </button>
                    <button
                      type="button"
                      className="btn setting-cancel-btn float-right"
                      onClick={this.handleCancel}
                    >
                      {this.state.loadingCancel ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#4f4f4f", margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loadingCancel ? "" : "Cancel"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.state.status === "verified" ? null : this.state
                .isComRest ? null : (
              <CompleteInfo
                title="Critical Business Details Required"
                desc="You need to add your business location and operating hours before publishing your website."
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
