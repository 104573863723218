import { store } from "react-notifications-component";

export const success = (text) => {
  return store.addNotification({
    title: "Success",
    message: text ? text : "Saved successfully.",
    insert: "top",
    type: "success", // 'default', 'success', 'info', 'warning'
    container: "top-right", // where to position the notifications
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    dismiss: {
      duration: 2000,
      pauseOnHover: true,
      click: true,
      showIcon: true,
    },
    isMobile: true,
  });
};

export const fail = (text) => {
  return store.addNotification({
    title: "Error",
    message: text ? text : "Oops, Something went wrong.",
    insert: "top",
    type: "danger", // 'default', 'success', 'info', 'warning'
    container: "top-right", // where to position the notifications
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    dismiss: {
      duration: 2000,
      pauseOnHover: true,
      click: true,
      showIcon: true,
    },
    isMobile: true,
  });
};

export const deleted = (text) => {
  return store.addNotification({
    title: "Success",
    message: "Deleted successfully.",
    insert: "top",
    type: "success", // 'default', 'success', 'info', 'warning'
    container: "top-right", // where to position the notifications
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    dismiss: {
      duration: 2000,
      pauseOnHover: true,
      click: true,
      showIcon: true,
    },
    isMobile: true,
  });
};
