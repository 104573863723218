import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";

import SecTitle from "../SecTitle";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class SocialMedia extends Component {
  state = {
    listingId: "",
    instagram: "",
    fb: "",
    twitter: "",
    pinterest: "",

    // show - hide
    status: "",

    // loading
    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        const fb = res.data[0].branding
          ? res.data[0].branding.links
            ? res.data[0].branding.links.filter(
                (filter) => filter.type === "facebook"
              )
            : ""
          : "";
        const instagram = res.data[0].branding
          ? res.data[0].branding.links
            ? res.data[0].branding.links.filter(
                (filter) => filter.type === "instagram"
              )
            : ""
          : "";
        const twitter = res.data[0].branding
          ? res.data[0].branding.links
            ? res.data[0].branding.links.filter(
                (filter) => filter.type === "twitter"
              )
            : ""
          : "";
        const pinterest = res.data[0].branding
          ? res.data[0].branding.links
            ? res.data[0].branding.links.filter(
                (filter) => filter.type === "pinterest"
              )
            : ""
          : "";
        this.setState({
          listingId: res.data[0].id,
          fb: fb[0] ? fb[0].url : "",
          instagram: instagram[0] ? instagram[0].url : "",
          twitter: twitter[0] ? twitter[0].url : "",
          pinterest: pinterest[0] ? pinterest[0].url : "",
          status: res.data[0].status,
          loadingCancel: false,
        });
      })
      .catch((err) => {
        this.setState({ loadingCancel: false });
        console.log(err);
      });
  };

  // get input values
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSave = () => {
    let links = [
      {
        type: "facebook",
        url: this.state.fb,
      },
      {
        type: "instagram",
        url: this.state.instagram,
      },
      {
        type: "twitter",
        url: this.state.twitter,
      },
      {
        type: "pinterest",
        url: this.state.pinterest,
      },
    ];

    // set loading
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/branding`,
        { links: links },
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        success();
      })
      .catch((err) => {
        this.setState({ loading: false });
        fail();
      });
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    this.getListing();
  };

  render() {
    return (
      <div className="settings-container" style={{ marginBottom: 150 }}>
        <div className="sec">
          <SecTitle
            title="Social Media Links"
            titleDesc="Add your social media links. They will be displayed in the page footer."
          />

          <div className="row m-0 content-container">
            <div className="col p-0">
              <form className="setting-form">
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field w-100">
                    <label htmlFor="instagram" className="title ml-0">
                      Instagram
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": this.state.phoneError,
                      })}
                      id="instagram"
                      name="instagram"
                      value={this.state.instagram}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {this.state.phoneError}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field w-100">
                    <label htmlFor="fb" className="title ml-0">
                      Facebook
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": this.state.phoneError,
                      })}
                      id="fb"
                      name="fb"
                      value={this.state.fb}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {this.state.phoneError}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field w-100">
                    <label htmlFor="twitter" className="title ml-0">
                      Twitter
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": this.state.phoneError,
                      })}
                      id="twitter"
                      name="twitter"
                      value={this.state.twitter}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {this.state.phoneError}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field w-100">
                    <label htmlFor="pinterest" className="title ml-0">
                      Pinterest
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": this.state.phoneError,
                      })}
                      id="pinterest"
                      name="pinterest"
                      value={this.state.pinterest}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {this.state.phoneError}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <button
                type="button"
                id="location-setting"
                className="btn setting-save-btn float-right"
                onClick={this.handleSave}
              >
                {this.state.loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    style={{ margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loading ? "" : "Save"}
              </button>
              <button
                type="button"
                className="btn setting-cancel-btn float-right"
                onClick={this.handleCancel}
              >
                {this.state.loadingCancel ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "#4f4f4f", margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loadingCancel ? "" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
