import React, { useState, useEffect } from "react";

import axios from "axios";

import SecTitle from "../settings/SecTitle";
import Breadcrumb from "../Breadcrumb";

import { isAuthenticated } from "../../auth/isAuth";
import { success, fail } from "../../methods/Alert";
import DiscountAddComp from "./discounts-comp/DiscountAddComp";

const initial = {
  threshold: "",
  discountType: "",
  discount: "",
  discountTypeError: "",
  discountError: "",
  thresholdError: "",
};

const DiscountsComp = () => {
  const [listingId, setListingId] = useState("");
  const [deliveryForm, setDeliveryForm] = useState(initial);
  const [pickupForm, setPickupForm] = useState(initial);
  const [dineInForm, setDineInForm] = useState(initial);
  const [loading, setLoading] = useState({
    deliveryLoading: false,
    pickupLoading: false,
    Loading: false,
  });
  const [deliveryShow, setDeliveryShow] = useState(true);
  const [pickupShow, setPickupShow] = useState(true);
  const [dineInShow, setDineInShow] = useState(true);
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    getListing();
  }, []);

  const getListing = () => {
    // get country name
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        setListingId(res.data[0].id);
        setCurrency(res.data[0].currency);

        const arr = res.data[0].deliveryDetail
          ? res.data[0].deliveryDetail.orderDiscounts
            ? res.data[0].deliveryDetail.orderDiscounts
            : []
          : [];

        if (arr.length > 0) {
          arr.forEach((el) => {
            if (el.orderType === "delivery") {
              setDeliveryExistVal(el);
            } else if (el.orderType === "pickup") {
              setPickupExistVal(el);
            } else {
              setDineInExistVal(el);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDeliveryExistVal = (data) => {
    setDeliveryForm({
      ...deliveryForm,
      threshold: data.threshold,
      discountType: data.isPercentage ? "percentage" : "value",
      discount: data.isPercentage ? data.percentage : data.value,
    });

    setDeliveryShow(false);
  };

  const setPickupExistVal = (data) => {
    setPickupForm({
      ...pickupForm,
      threshold: data.threshold,
      discountType: data.isPercentage ? "percentage" : "value",
      discount: data.isPercentage ? data.percentage : data.value,
    });

    setPickupShow(false);
  };

  const setDineInExistVal = (data) => {
    setDineInForm({
      ...dineInForm,
      threshold: data.threshold,
      discountType: data.isPercentage ? "percentage" : "value",
      discount: data.isPercentage ? data.percentage : data.value,
    });

    setDineInShow(false);
  };

  // handle input
  const handleChangeDelivery = (e) => {
    setDeliveryForm({
      ...deliveryForm,
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };
  const handleChangePickup = (e) => {
    setPickupForm({
      ...pickupForm,
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };
  const handleChangeDineIn = (e) => {
    setDineInForm({
      ...dineInForm,
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  // validate
  const validate = (type) => {
    let thresholdError = "";
    let discountTypeError = "";
    let discountError = "";

    let orderType = {};

    if (type === "delivery") {
      orderType = deliveryForm;
    } else if (type === "pickup") {
      orderType = pickupForm;
    } else {
      orderType = dineInForm;
    }

    if (!orderType.threshold) {
      thresholdError = "Threshold is required";
    }

    if (!orderType.discountType) {
      discountTypeError = "Discount type is required";
    }

    if (!orderType.discount) {
      discountError = "Discount is required";
    }

    if (discountTypeError || discountError || thresholdError) {
      if (type === "delivery") {
        setDeliveryForm({
          ...deliveryForm,
          discountTypeError,
          discountError,
          thresholdError,
        });
      } else if (type === "pickup") {
        setPickupForm({
          ...deliveryForm,
          discountTypeError,
          discountError,
          thresholdError,
        });
      } else {
        setDineInForm({
          ...deliveryForm,
          discountTypeError,
          discountError,
          thresholdError,
        });
      }
      return false;
    }

    return true;
  };

  // add discount
  const saveDiscount = (type) => {
    if (validate(type)) {
      setLoading({ ...loading, deliveryLoading: true });

      let orderType = {};

      if (type === "delivery") {
        orderType = deliveryForm;
        setLoading({ ...loading, deliveryLoading: true });
      } else if (type === "pickup") {
        orderType = pickupForm;
        setLoading({ ...loading, pickupLoading: true });
      } else {
        orderType = dineInForm;
        setLoading({ ...loading, dineInLoading: true });
      }

      const data = {
        orderType: type,
        isPercentage: orderType.discountType === "percentage" ? true : false,
        percentage:
          orderType.discountType === "percentage" ? orderType.discount : 0,
        value: orderType.discountType === "value" ? orderType.discount : 0,
        threshold: orderType.threshold,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${listingId}/order_discounts`,
          data,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          setLoading({
            ...loading,
            deliveryLoading: false,
            pickupLoading: false,
            dineInLoading: false,
          });

          getListing();

          success();
        })
        .catch((err) => {
          console.log(err);
          setLoading({
            ...loading,
            deliveryLoading: false,
            pickupLoading: false,
            dineInLoading: false,
          });
          fail(
            err.response && err.response.status
              ? `Error code: ${err.response.status}`
              : null
          );
        });
    }
  };

  // delete discount
  const deleteDiscount = (type) => {
    if (type === "delivery") {
      setLoading({ ...loading, deliveryLoading: true });
    } else if (type === "pickup") {
      setLoading({ ...loading, pickupLoading: true });
    } else {
      setLoading({ ...loading, dineInLoading: true });
    }

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${listingId}/order_discounts/${type}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        setLoading({
          ...loading,
          deliveryLoading: false,
          pickupLoading: false,
          dineInLoading: false,
        });

        if (type === "delivery") {
          setDeliveryShow(true);
          setDeliveryForm(initial);
        } else if (type === "pickup") {
          setPickupShow(true);
          setPickupForm(initial);
        } else {
          setDineInShow(true);
          setDineInForm(initial);
        }

        success("Deleted successfully.");
      })
      .catch((err) => {
        console.log(err);
        setLoading({
          ...loading,
          deliveryLoading: false,
          pickupLoading: false,
          dineInLoading: false,
        });
        fail(
          err.response && err.response.status
            ? `Error code: ${err.response.status}`
            : null
        );
      });
  };

  return (
    <div className="settings-container">
      <Breadcrumb mainPage="Promotions" subPage="Discounts" />

      <div className="sec">
        <SecTitle
          title="Discounts Based on Total Order Value"
          titleDesc="Add order total based discounts for each order type"
        />

        <div className="row m-0 content-container">
          <div className="col-md p-0">
            <form className="setting-form">
              <DiscountAddComp
                title="Delivery"
                type="delivery"
                form={deliveryForm}
                handleChange={handleChangeDelivery}
                saveDiscount={saveDiscount}
                deleteDiscount={deleteDiscount}
                showHide={deliveryShow}
                loading={loading.deliveryLoading}
                currency={currency}
              />

              <DiscountAddComp
                title="Pickup"
                type="pickup"
                form={pickupForm}
                handleChange={handleChangePickup}
                saveDiscount={saveDiscount}
                deleteDiscount={deleteDiscount}
                showHide={pickupShow}
                loading={loading.pickupLoading}
                currency={currency}
              />

              <DiscountAddComp
                title="Dine In"
                type="dine_in"
                form={dineInForm}
                handleChange={handleChangeDineIn}
                saveDiscount={saveDiscount}
                deleteDiscount={deleteDiscount}
                showHide={dineInShow}
                loading={loading.dineInLoading}
                currency={currency}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountsComp;
