import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import classnames from "classnames";
import CreditCardInput from "react-credit-card-input";
import { Lines } from "react-preloaders";

import Breadcrumb from "../../Breadcrumb";
import SecTitle from "../SecTitle";

import close from "../../../images/close-modal.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class ConfirmPlan extends Component {
  state = {
    listingId: "",
    plan: {},
    expiryDate: "",
    hasSubscriptionPlan: false,
    isUpgrade: false,
    currentPlanPrice: 0,

    name: "",
    cardNumber: "",
    expiry: "",
    cvc: "",

    // errors
    nameError: "",
    invalidCardError: "",

    // pre loading
    preLoading: true,

    // redirects
    redirectToPayment: false,

    // loading
    loading: false,
    loadingUpdate: false,
  };

  componentDidMount = () => {
    this.getListing();
    this.getPaymentSources();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          currencySymbol: res.data[0].region.country.currencySymbol,
          expiryDate: res.data[0].subscription
            ? res.data[0].subscription.nextInvoiceDate
            : moment.utc(res.data[0].trialExpiry).local() < moment().format()
            ? ""
            : res.data[0].trialExpiry,
          hasSubscriptionPlan: res.data[0].subscription ? true : false,
          currentPlanPrice: res.data[0].subscription
            ? res.data[0].subscription.price
            : 0,
        });

        this.getPricing(res.data[0].region.country.countryCodeShort);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPaymentSources = () => {
    // get payment sources
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/paymentsources/listing_subscription`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          maskedCardNumber: res.data.maskedCardNumber,
          paymentId: res.data.id,
          loadingCancel: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false });
      });
  };

  getPricing = (country) => {
    // get pricing info
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/pricing?countryCode=${country}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        const urlParams = new URLSearchParams(window.location.search);
        const planType = urlParams.get("plan");

        const filter = res.data.filter((filter) => filter.plan === planType);

        this.setState({
          plan: filter[0],
          isUpgrade:
            filter[0].price > this.state.currentPlanPrice ? true : false,
          preLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCardNumberChange = (e) => {
    this.setState({ cardNumber: e.target.value });
  };

  handleCardExpiryChange = (e) => {
    this.setState({ expiry: e.target.value });
  };

  handleCardCVCChange = (e) => {
    this.setState({ cvc: e.target.value });
  };

  // validate
  validateUpdateCard = () => {
    let nameError = "";
    let invalidCardError = "";

    if (this.state.name === "") {
      nameError = "Name is required";
    }

    if (this.state.cardNumber === "") {
      invalidCardError = "Please type a valid card";
    }

    if (this.state.expiry === "") {
      invalidCardError = "Please type a valid card";
    }

    if (this.state.cvc === "") {
      invalidCardError = "Please type a valid card";
    }

    if (invalidCardError || nameError) {
      this.setState({
        invalidCardError,
        nameError,
      });
      return false;
    }

    return true;
  };

  handleUpdateCard = () => {
    if (this.validateUpdateCard()) {
      this.setState({
        nameError: "",
        invalidCardError: "",
        loadingUpdate: true,
      });

      const cardExpMonth = this.state.expiry.split("/")[0];
      const cardExpYear = this.state.expiry.split("/")[1];

      const data = {
        cardNumber: this.state.cardNumber,
        cardExpMonth: cardExpMonth.trim(),
        cardExpYear: cardExpYear.trim(),
        cardCvc: this.state.cvc,
        forListingSubscription: true,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, data, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          this.setState({
            loadingUpdate: false,
            cardNumber: "",
            expiry: "",
            cvc: "",
          });

          this.getPaymentSources();

          success();
          document.getElementById("cancel-update-modal").click();
        })
        .catch((err) => {
          if (err.response.data.code === 1024) {
            fail("Please submit a valid card. Error code: 1024");
          } else {
            fail(err.response && err.response.status ? `Error code: ${err.response.status}` : null);
          }

          this.setState({ loadingUpdate: false });

          document.getElementById("");
        });
    }
  };

  handleCancelUpdate = () => {
    this.setState({ nameError: "", invalidCardError: "" });
    document.getElementById("cancel-update-modal").click();
  };

  // validate
  validate = () => {
    let invalidCardError = "";

    if (this.state.cardNumber === "") {
      invalidCardError = "Please type a valid card";
    }

    if (this.state.expiry === "") {
      invalidCardError = "Please type a valid card";
    }

    if (this.state.cvc === "") {
      invalidCardError = "Please type a valid card";
    }

    if (invalidCardError) {
      this.setState({
        invalidCardError,
      });
      return false;
    }

    return true;
  };

  handleSave = (type) => {
    if (this.validate()) {
      this.setState({ loading: true, invalidCardError: "" });

      const cardExpMonth = this.state.expiry.split("/")[0];
      const cardExpYear = this.state.expiry.split("/")[1];

      const data = {
        cardNumber: this.state.cardNumber,
        cardExpMonth: cardExpMonth.trim(),
        cardExpYear: cardExpYear.trim(),
        cardCvc: this.state.cvc,
        forListingSubscription: true,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, data, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/subscriptions`,
              `plan=${type}`,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
              }
            )
            .then((res) => {
              success();
              this.setState({ loading: false, redirectToPayment: true });
            })
            .catch((err) => this.setState({ loading: false }));
        })
        .catch((err) => {
          if (err.response.data.code === 1024) {
            fail("Please submit a valid card. Error code: 1024");
          } else {
            fail();
          }

          this.setState({ loading: false });
        });
    }
  };

  handleConfirm = (type) => {
    // set loading
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/subscriptions`,
        `plan=${type}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        success();
        this.setState({ loading: false, redirectToPayment: true });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#fff"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <div className="settings-container">
            {this.state.redirectToPayment && (
              <Redirect to="/settings/payment-info" />
            )}
            <Breadcrumb
              mainPage="Settings"
              subPage="Payment and Payout"
              secondSub="Select Subscription Plan"
              thirdSub="Confirm Subscription"
            />

            <div className="sec">
              <SecTitle
                title={
                  this.state.hasSubscriptionPlan
                    ? this.state.isUpgrade
                      ? "Upgrade Subscription Plan"
                      : "Downgrade Subscription Plan"
                    : "Finalize Subscription Plan"
                }
                titleDesc={
                  this.state.hasSubscriptionPlan
                    ? "New features will take effect as soon as you confirm the new plan by clicking on the “Confirm” button below. New pricing will be applied from your next billing cycle."
                    : "Add a payment method and complete your subscription to start accepting orders. You can change your plan at any time from the Payment Settings section."
                }
              />
              <div className="row m-0 content-container">
                <div className="col p-0">
                  <p className="confirm-title">Billing Cycle</p>
                  <p className="confirm-desc" style={{ marginBottom: 0 }}>
                    Every month starting on{" "}
                    {this.state.expiryDate
                      ? moment
                          .utc(this.state.expiryDate)
                          .local()
                          .format("DD MMM YYYY")
                      : moment().format("DD MMM YYYY")}
                  </p>

                  <p className="confirm-title">Billing Amount</p>
                  <p className="total-price" style={{ marginBottom: 0 }}>
                    {this.state.currencySymbol} {this.state.plan.price}
                  </p>

                  <form className="setting-form">
                    {!this.state.hasSubscriptionPlan ? (
                      <div className="form-row" style={{ marginTop: 30 }}>
                        <div className="form-group mb-0 p-0 input-field">
                          <label
                            htmlFor="description"
                            className="title ml-0 mb-2"
                          >
                            Payment Method{" "}
                          </label>
                          <CreditCardInput
                            cardNumberInputProps={{
                              value: this.state.cardNumber,
                              onChange: this.handleCardNumberChange,
                            }}
                            cardExpiryInputProps={{
                              value: this.state.expiry,
                              onChange: this.handleCardExpiryChange,
                            }}
                            cardCVCInputProps={{
                              value: this.state.cvc,
                              onChange: this.handleCardCVCChange,
                            }}
                            // onError={this.validate}
                            fieldClassName="input"
                            cardImageStyle={{ width: 35, height: 25 }}
                            fieldStyle={{ verticalAlign: "middle" }}
                          />
                          <p
                            style={{
                              fontSize: "0.8rem",
                              color: "#ff3860",
                              marginTop: 5,
                              marginBottom: 0,
                            }}
                          >
                            {this.state.invalidCardError}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="form-row" style={{ marginTop: 30 }}>
                        <div className="form-group mb-0 p-0 input-field">
                          <label
                            htmlFor="description"
                            className="title ml-0 mb-2"
                          >
                            Payment Method{" "}
                            <span
                              className="action"
                              data-toggle="modal"
                              data-target="#sub-pay"
                            >
                              Update
                            </span>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            value={`************ ${this.state.maskedCardNumber}`}
                            autoComplete="off"
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="row m-0 content-container">
                <div className="col-md p-0">
                  {this.state.hasSubscriptionPlan ? (
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={() => this.handleConfirm(this.state.plan.plan)}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Confirm"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={() => this.handleSave(this.state.plan.plan)}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Start Plan"}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn setting-cancel-btn float-right"
                    onClick={() => this.setState({ redirectToPayment: true })}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div
                class="modal fade add-cost-com sub-update-plan"
                id="sub-pay"
                tabindex="-1"
                role="dialog"
                aria-labelledby="sub-pay"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="add-cost-com"
                        style={{ fontSize: 22, fontWeight: "normal" }}
                      >
                        Subscription Payment Method
                      </h5>

                      <img
                        src={close}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="cancel-update-modal"
                        alt="close"
                      />
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="form-row m-0">
                          <div className="form-group mb-0 p-0 input-field w-100">
                            <label
                              htmlFor="description"
                              className="title ml-0 mb-2"
                            >
                              Your Name
                            </label>
                            <input
                              id="card-holder-name"
                              type="text"
                              className={classnames("form-control", {
                                "is-invalid": this.state.nameError,
                              })}
                              placeholder="Card Holder Name"
                              name="name"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                            <div className="invalid-feedback">
                              {this.state.nameError}
                            </div>
                          </div>
                        </div>
                        <div className="form-row mx-0 mt-2">
                          <div className="form-group mb-0 p-0 input-field w-100">
                            <label htmlFor="bankCode" className="title ml-0">
                              Card Details
                            </label>
                            <CreditCardInput
                              cardNumberInputProps={{
                                value: this.state.cardNumber,
                                onChange: this.handleCardNumberChange,
                              }}
                              cardExpiryInputProps={{
                                value: this.state.expiry,
                                onChange: this.handleCardExpiryChange,
                              }}
                              cardCVCInputProps={{
                                value: this.state.cvc,
                                onChange: this.handleCardCVCChange,
                              }}
                              // onError={this.validate}
                              fieldClassName="input"
                              cardImageStyle={{ width: 35, height: 25 }}
                              fieldStyle={{ verticalAlign: "middle" }}
                            />
                            <p
                              style={{
                                fontSize: "0.8rem",
                                color: "#ff3860",
                                marginTop: 5,
                                marginBottom: 0,
                              }}
                            >
                              {this.state.invalidCardError}
                            </p>
                          </div>
                        </div>
                      </form>
                      <div className="row mt-4 m-0 ">
                        <div className="col-md p-0">
                          <button
                            type="button"
                            id="location-setting"
                            className="btn setting-save-btn float-right"
                            onClick={this.handleUpdateCard}
                          >
                            {this.state.loadingUpdate ? (
                              <div
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                                style={{ margin: 0 }}
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : null}

                            {this.state.loadingUpdate ? "" : "Save"}
                          </button>

                          <button
                            type="button"
                            className="btn setting-cancel-btn float-right"
                            onClick={this.handleCancelUpdate}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
