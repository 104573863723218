import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import NumberFormat from "react-number-format";
import { Lines } from "react-preloaders";
import { isAuthenticated } from "../auth/isAuth";
import CheeseburgerMenu from "cheeseburger-menu";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EdiText from "react-editext";
import { toast } from "react-toastify";

import empty from "../images/empty-img.png";
import close from "../images/close-modal.png";

import "../styles/delivery.css";

class ItemMenu extends Component {
  state = {
    extId: "",
    listingId: "",
    currency: {},

    // items
    items: [],

    // menu
    category: "",
    showCatInput: false,
    categories: [],
    categoryId: "",
    newCategory: "",

    editCategoryId: "",
    editCategoryName: "",

    onListView: true,

    // modal
    name: "",
    price: "",
    discountPrice: 0,
    minOrder: 0,
    maxOrder: 0,
    isDisableMax: false,
    isUnlimitedMax: false,
    isDisableUnlimited: false,
    description: "",
    image: "",

    // update item
    itemId: "",
    editImage: "",
    menuItem: "",
    editName: "",
    // editNameOld: "",
    editPrice: "",
    editDiscountPrice: 0,
    editMinOrder: 0,
    editMaxOrder: 0,
    // editPriceOld: "",
    editDesc: "",

    // list view
    updateDesc: "",
    showSaveDesc: false,

    //errors
    nameError: "",
    priceError: "",
    categoryError: "",

    // alerts
    successAlert: false,
    showDelItemModal: false,

    // loading
    loading: false,

    //
    showNoItemError: false,

    // redirect
    redirectToManageGallery: false,
    redirectToCompleteGallery: false,
    redirectToHome: false,
    redirectToCompleteHappyHours: false,

    // pre loading
    preLoading: true,

    // SideBar
    menuOpen: false,
    addAnother: false,
    uploadingImage: false,
    imageHash: "",
    itemAddOnList: [],

    // Portion Size
    portionSizeName: "",
    portionSizePrice: "",
    createdPortionList: [],

    // Portion Error
    portionSizeNameError: "",
    portionSizePriceError: "",

    // Portion Size Edit
    portionSizeNameEdit: "",
    portionSizePriceEdit: "",
    portionSizeDisPriceEdit: "",
    loadingAddPortionEdit: false,
    portionSizesList: [],

    // Portion Error
    portionNameEditError: "",
    portionPriceEditError: "",

    // Pre Order
    preOrderHours: "",
    editPreOrderHours: "",
  };

  componentDidMount = () => {
    // get listing id
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          extId: res.data[0].extId,
          currency: res.data[0].currency
        });

        // get categories
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/menu_categories`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedCategories = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({ categories: sortedCategories });
          })
          .catch((err) => console.log(err));

        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({ items: sortedItems, preLoading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ preLoading: false });
          });
      })
      .catch((err) => console.log(err));
  };

  // get checkbox value
  handleChangeInputs = (e) => {
    if (e.target.name === "maxOrder" || e.target.name === "editMaxOrder") {
      if (e.target.value) {
        this.setState({ isDisableUnlimited: true });
      } else {
        this.setState({ isDisableUnlimited: false });
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  // get checkbox value
  handlePortion = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handleChangeFile = (e) => {
    this.setState({ image: e.target.files[0] });
  };

  // login when press the enter key
  categoryKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      this.addCategory();
    }
  };

  addPortionSizeToMenuItem = (menuItemId) => {
    if (this.state.createdPortionList.length > 0) {
      this.state.createdPortionList.forEach((item) => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${menuItemId}/portion_sizes`,
            {
              name: item.name,
              price: item.price,
              discountPrice: item.discountPrice,
              hasDiscount: item.hasDiscount,
            },
            {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
            }
          )
          .then((res) => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
                headers: {
                  Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
              })
              .then((res) => {
                const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

                this.setState({
                  items: sortedItems,
                });
              });
          })
          .catch((err) => console.log(err));
      });
    }
  };

  addCategory = () => {
    if (this.state.showCatInput === true && this.state.newCategory !== "") {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories`,
          {
            title: this.state.newCategory,
            sortOrder: this.state.categories.length,
          },
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ categoryId: res.data.id });

          axios
            .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories`, {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
            })
            .then((res) => {
              this.setState({
                categories: res.data,
                newCategory: "",
                showCatInput: false,
              });
              this.addItemToCategory();
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  };

  addItemToCategory = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        nameError: "",
        priceError: "",
        categoryError: "",
      });

      const data = {
        name: this.state.name,
        description: this.state.description,
        price: parseFloat(this.state.createdPortionList.length > 0 ? this.state.createdPortionList.reduce((prev, curr) => (prev.price < curr.price ? prev : curr)).price : this.state.price),
        discountPrice: parseFloat(this.state.createdPortionList.length > 0 ? this.state.createdPortionList.reduce((prev, curr) => (prev.price < curr.price ? prev : curr)).discountPrice : this.state.discountPrice),
        hasDiscount: this.state.discountPrice ? true : false,
        minOrderQty: this.state.minOrder ? parseInt(this.state.minOrder) : 0,
        maxOrderQty: this.state.maxOrder ? parseInt(this.state.maxOrder) : 0,
        categoryId: this.state.categoryId,
        preOrderHours: this.state.preOrderHours ? parseInt(this.state.preOrderHours) * 24 : 0,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, data, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          this.addPortionSizeToMenuItem(res.data.id);
          if (this.state.image !== "") {
            // create product object
            var bodyFormData = new FormData();
            bodyFormData.append("image", this.state.image);
            bodyFormData.set("attribution", this.state.image.name);

            axios
              .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${res.data.id}/image`, bodyFormData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
              })
              .then((res) => {
                // get items
                axios
                  .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
                    headers: {
                      Authorization: `Bearer ${isAuthenticated().providerToken}`,
                    },
                  })
                  .then((res) => {
                    const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

                    this.setState({
                      items: sortedItems,
                      categoryId: "",
                      createdPortionList: [],
                      description: "",
                      minOrder: "",
                      maxOrder: "",
                      preOrderHours: "",
                    });

                    if (this.state.addAnother === true) {
                      this.setState({
                        loading: false,
                        nameError: "",
                        priceError: "",
                        name: "",
                        price: "",
                        discountPrice: 0,
                        description: "",
                        image: "",
                        category: "",
                        categoryId: "",
                        preOrderHours: "",
                        addAnother: false,
                      });
                    } else {
                      document.getElementById("add-menu-close-btn").click();
                      this.setState({ loading: false });
                    }
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            // get items
            axios
              .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
                headers: {
                  Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
              })
              .then((res) => {
                const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

                this.setState({
                  items: sortedItems,
                  categoryId: "",
                  createdPortionList: [],
                  description: "",
                  minOrder: "",
                  maxOrder: "",
                  preOrderHours: "",
                });

                if (this.state.addAnother === true) {
                  this.setState({
                    loading: false,
                    nameError: "",
                    priceError: "",
                    name: "",
                    price: "",
                    description: "",
                    image: "",
                    category: "",
                    categoryId: "",
                    preOrderHours: "",
                    addAnother: false,
                  });
                } else {
                  document.getElementById("add-menu-close-btn").click();
                  this.setState({ loading: false });
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
            nameError: "",
            priceError: "",
            name: "",
            price: "",
            description: "",
            image: "",
            category: "",
            categoryId: "",
            preOrderHours: "",
            addAnother: false,
          });
        });
    }
  };

  delCategory = (id) => {
    const filterCategory = this.state.categories.filter((filter) => filter.id !== id);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories/${id}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({ categories: filterCategory });
      })
      .catch((err) => console.log(err));
  };

  selectCategoryId = () => {
    this.setState({
      isDisableMax: false,
      isUnlimitedMax: false,
      isDisableUnlimited: false,
      category: "",
      categoryId: "",
      name: "",
      price: "",
      discountPrice: 0,
      portionSizeName: "",
      portionSizePrice: "",
      portionSizeDisPrice: "",
      createdPortionList: [],
      portionNameError: "",
      portionPriceError: "",
      portionSizesList: [],
      description: "",
      image: "",
      minOrder: "",
      maxOrder: "",
      preOrderHours: "",
    });
  };

  handleMaxOrder = (e) => {
    if (e.target.checked) {
      this.setState({
        isUnlimitedMax: true,
        isDisableMax: true,
      });
    } else {
      this.setState({ isUnlimitedMax: false, isDisableMax: false });
    }
  };

  // validate
  validate = () => {
    let nameError = "";
    let priceError = "";
    let categoryError = "";

    const checkIsCategoryAvailable = this.state.categories.some((item) => item.title === this.state.newCategory);

    if (this.state.name === "") {
      nameError = "Item name is required";
    }

    if (this.state.price === "") {
      priceError = "Price is required";
    }

    if (this.state.categoryId === "") {
      categoryError = "Category is required";
    }

    if (checkIsCategoryAvailable === true) {
      categoryError = "Category is existing. Please select from the list.";
    }

    if (nameError || priceError || categoryError) {
      this.setState({
        nameError,
        priceError,
        categoryError,
      });
      return false;
    }

    return true;
  };

  addItem = (value) => {
    this.setState({ addAnother: value });

    if (this.state.showCatInput === true && this.state.newCategory !== "") {
      this.addCategory();
    } else {
      this.addItemToCategory();
    }
  };

  handleItemAvailability = (id, status) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${id}/availability`, qs.stringify({ availability: status }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({
              items: sortedItems,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  handleCategoryAvailability = (id, status) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories/${id}/availability`, qs.stringify({ availability: status }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get categories
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedCategories = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({ categories: sortedCategories });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  handleUpdateImage = (e) => {
    this.setState({ uploadingImage: true });
    this.setState({ editImage: "" });
    // create product object
    var bodyFormData = new FormData();
    bodyFormData.append("image", e.target.files[0]);
    bodyFormData.set("attribution", e.target.files[0].name);

    axios
      .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${this.state.menuItem}/image`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({
              items: sortedItems,
              loading: false,
              uploadingImage: false,
            });

            const uploadedImage = this.state.items.filter((item) => item.id === this.state.itemId).find((element) => element);
            this.setState({
              editImage: uploadedImage.image,
              imageHash: Date.now(),
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              loading: false,
              uploadingImage: false,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          uploadingImage: false,
        });
      });
  };

  updateMenuItem = (e) => {
    e.preventDefault();

    const data = {
      name: this.state.editName,
      price: this.state.editPrice,
      minOrderQty: this.state.editMinOrder ? parseInt(this.state.editMinOrder) : 0,
      maxOrderQty: this.state.editMaxOrder ? parseInt(this.state.editMaxOrder) : 0,
      description: this.state.editDesc,
      categoryId: this.state.categoryId,
      discountPrice: this.state.editDiscountPrice,
      hasDiscount: this.state.editDiscountPrice ? true : false,
      preOrderHours: this.state.editPreOrderHours ? parseInt(this.state.editPreOrderHours) * 24 : 0,
    };

    this.setState({ loading: true });

    axios
      .put(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${this.state.menuItem}`, data, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({
              items: sortedItems,
              loading: false,
              showSaveDesc: false,
            });

            // window.location.reload();
          })
          .catch((err) => {
            console.log(err);

            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  // back
  back = () => {
    if (this.props.history.location.pathname === "/manage-listing-item") {
      this.setState({ redirectToManageGallery: true });
    } else {
      this.setState({ redirectToCompleteGallery: true });
    }
  };

  // next
  next = () => {
    if (this.props.history.location.pathname === "/manage-listing-item") {
      this.setState({ successAlert: false, redirectToHome: true });
    } else {
      this.setState({ redirectToCompleteHappyHours: true });
    }
  };

  showDelModal = (itemId) => {
    this.setState({ showDelItemModal: true, itemId: itemId });
  };

  delItem = (id) => {
    const filterItems = this.state.items.filter((filter) => filter.id !== id);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${id}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({
              items: sortedItems,
              showDelItemModal: false,
              itemId: "",
            });

            document.getElementById("add-menu-close-btn").click();

            window.location.reload();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  onCancel = () => {
    this.setState({ showDelItemModal: false, itemId: "" });
  };

  closeMenu() {
    this.setState({
      menuOpen: false,
      editImage: "",
      menuItem: "",
      editName: "",
      editPrice: "",
      editDiscountPrice: 0,
      editMinOrder: 0,
      editMaxOrder: "",
      editDesc: "",
      categoryId: "",
      itemId: "",
      editPreOrderHours: "",
    });
  }

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#D4D4D4" : "white",
    boxShadow: isDragging ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : null,
    border: isDragging ? "1px solid #bababa" : null,

    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
  });

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(this.state.items, result.source.index, result.destination.index);

    this.setState({
      items,
    });

    const itemCategoryId = this.state.items[result.source.index].category.id;
    const selectedCategory = items.filter((item) => item.category.id === itemCategoryId);

    let count = 0;
    let str = "";

    selectedCategory.forEach((e) => {
      let itemStr = `${e.id}=${count}`;
      count++;
      str = str + "&" + itemStr;
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/sort_order`, str, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({ items: sortedItems });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onDragEndCategory = (result) => {
    if (!result.destination) {
      return;
    }

    const categories = this.reorder(this.state.categories, result.source.index, result.destination.index);

    this.setState({
      categories,
    });

    let count = 0;
    let str = "";

    categories.forEach((e) => {
      let itemStr = `${e.id}=${count}`;
      count++;
      str = str + "&" + itemStr;
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories/sort_order`, str, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get categories
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedCategories = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({ categories: sortedCategories });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  editMenuItemSidebarShow = (item) => {
    this.setState({
      itemId: item.id,
      editImage: item.thumbnail,
      menuItem: item.id,
      editName: item.name,
      editDiscountPrice: item.discountPrice,
      editMinOrder: item.minOrderQty === 0 ? "" : item.minOrderQty,
      editMaxOrder: item.maxOrderQty === 0 ? "" : item.maxOrderQty,
      editDesc: item.description,
      categoryId: item.category.id,
      menuOpen: true,
      portionSizesList: item.portionSizes,
      editPreOrderHours: item.preOrderHours / 24,
      editPrice: item.price,
    });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/addon_groups?menuItemId=${item.id}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        const groups = res.data;
        let groupList = [];

        for (let i = 0; i < groups.length; i++) {
          const sorted = groups[i].items.sort((a, b) => a.sortOrder - b.sortOrder);
          let data = groups[i];
          data.items = sorted;
          groupList.push(data);
        }
        groupList = groupList.sort((a, b) => a.sortOrder - b.sortOrder);
        this.setState({ itemAddOnList: groupList });
      })
      .catch((err) => console.log(err));

    const selectedItem = {
      itemId: item.id,
      itemName: item.name,
      listingId: this.state.listingId,
    };
    this.props.getSelectedItem(selectedItem);
  };

  onSaveItemName = (value) => {
    const data = {
      name: value,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${this.state.menuItem}`, data, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({
              items: sortedItems,
              editName: value,
            });

            // window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSaveCategoryName = (value) => {
    const data = {
      title: value,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_categories/${this.state.editCategoryId}`, data, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // get items
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            const sortedItems = res.data.sort((a, b) => a.sortOrder - b.sortOrder);

            this.setState({
              items: sortedItems,
              editCategoryId: "",
              editCategoryName: "",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error! Please try again");
      });
  };

  handleSelectCategory = (event) => {
    const categoryName = event.target.value;
    const getSelectedCategory = this.state.categories.filter((category) => category.title === categoryName).map((x) => x.id);

    this.setState({
      category: event.target.value,
      categoryId: getSelectedCategory[0],
    });
  };

  // validate Portion
  validatePortion = () => {
    let portionSizeNameError = "";
    let portionSizePriceError = "";
    let portionSizeDisPriceError = "";

    if (this.state.portionSizeName === "") {
      portionSizeNameError = "Portion item name is required";
    }

    if (this.state.portionSizePrice === "") {
      portionSizePriceError = "Portion price is required";
    }

    if (this.state.portionSizeDisPrice) {
      if (parseInt(this.state.portionSizeDisPrice) >= parseInt(this.state.portionSizePrice)) {
        portionSizeDisPriceError = "Discounted price should be low";
      }
    }

    if (portionSizeNameError || portionSizePriceError || portionSizeDisPriceError) {
      this.setState({
        portionSizeNameError,
        portionSizePriceError,
        portionSizeDisPriceError,
      });
      return false;
    }
    return true;
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.addPortionSizeItem();
    }
  };

  addPortionSizeItem = () => {
    if (this.validatePortion()) {
      this.setState({
        portionSizeNameError: "",
        portionSizePriceError: "",
        portionSizeDisPriceError: "",
      });

      let currentPortions = this.state.createdPortionList;

      const tempPortionItem = {
        id: new Date().getTime(),
        name: this.state.portionSizeName,
        price:  parseFloat(this.state.portionSizePrice),
        discountPrice: parseFloat(this.state.portionSizeDisPrice),
        hasDiscount: this.state.portionSizeDisPrice ? true : false,
      };

      currentPortions.push(tempPortionItem);

      this.setState({
        createdPortionList: currentPortions,
        portionNameError: "",
        portionPriceError: "",
        portionSizeName: "",
        portionSizePrice: null,
        portionSizeDisPrice: null,
        price: this.state.portionSizePrice
      });
    }
  };

  removePortionItem = (id) => {
    const removed = this.state.createdPortionList.filter((item) => item.id !== id);
    this.setState({ createdPortionList: removed });
  };

  // validate Portion edit
  validatePortionEdit = () => {
    let portionSizeNameEditError = "";
    let portionSizePriceEditError = "";
    let portionSizeDisPriceEditError = "";

    if (this.state.portionSizeNameEdit === "") {
      portionSizeNameEditError = "Portion item name is required";
    }

    if (this.state.portionSizePriceEdit === "") {
      portionSizePriceEditError = "Portion price is required";
    }

    if (this.state.portionSizeDisPriceEdit) {
      if (parseInt(this.state.portionSizePriceEdit) <= parseInt(this.state.portionSizeDisPriceEdit)) {
        portionSizeDisPriceEditError = "Discounted price should be low";
      }
    }

    if (portionSizeNameEditError || portionSizePriceEditError || portionSizeDisPriceEditError) {
      this.setState({
        portionSizeNameEditError,
        portionSizePriceEditError,
        portionSizeDisPriceEditError,
      });
      return false;
    }
    return true;
  };

  handleAddProtionInEdit = () => {
    if (this.validatePortionEdit()) {
      this.setState({
        loadingAddPortionEdit: true,
        portionSizeNameEditError: "",
        portionSizePriceEditError: "",
        portionSizeDisPriceEditError: "",
      });

      const data = {
        id: new Date().getTime(),
        name: this.state.portionSizeNameEdit,
        price: parseFloat(this.state.portionSizePriceEdit),
        discountPrice: parseFloat(this.state.portionSizeDisPriceEdit),
        hasDiscount: this.state.portionSizeDisPriceEdit ? true : false,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${this.state.menuItem}/portion_sizes`, data, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          // created portion
          const obj = {
            id: new Date().getTime(),
            name: this.state.portionSizeNameEdit,
            calculatedPrice: this.state.portionSizePriceEdit,
            calculatedDiscountPrice: this.state.portionSizeDisPriceEdit,
            hasDiscount: this.state.portionSizeDisPriceEdit ? true : false,
          };

          this.setState({
            loadingAddPortionEdit: false,
            portionSizesList: [...this.state.portionSizesList, obj],
            portionSizeNameEdit: "",
            portionSizePriceEdit: "",
            portionSizeDisPriceEdit: "",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error! Please try again");
        });
    }
  };

  deleteImage = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/menu_items/${id}/image`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          editImage: "",
          imageHash: "",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error! Please try again");
      });
  };

  getCurrencySymbol = (currency) => {
    return currency?.symbol || currency?.code;
  };

   // TODO: make utility and move into it
   getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  }

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines color="#ff6961" background="#F2F2F2" animation="slide-down" customLoading={this.state.preLoading} />
        ) : (
          <React.Fragment>
            {this.state.redirectToManageGallery ? <Redirect to="/manage-listing-delivery" /> : null}

            {this.state.redirectToCompleteGallery ? <Redirect to="/complete-listing-delivery" /> : null}

            {this.state.redirectToHome ? <Redirect to="/manage-orders" /> : null}

            {this.state.redirectToCompleteHappyHours ? <Redirect to="/complete-listing-verify" /> : null}

            {this.state.successAlert ? (
              <SweetAlert success title="Successfull" confirmBtnStyle={{ display: "none" }}>
                Listing menu is successfully updated!
              </SweetAlert>
            ) : null}

            <React.Fragment>
              <div className="menu-breadcrumb">
                <span>Manage Menu</span> {">"} <span className="menu-breadcrumb-bold"> Menu</span>
                <span className="menu-breadcrumb-line"></span>
              </div>

              <div className="add-manage-item-wrapper">
                <div className="add-item-inner" data-toggle="modal" data-target="#exampleModalCenter" onClick={() => this.selectCategoryId()}>
                  + Add Item
                </div>

                <div className="manage-categories-inner" data-toggle="modal" data-target="#manageCatModal">
                  Change Category Order
                </div>
              </div>

              <div className="manage-menu-wrapper">
                <div className="row menu-accordion border-top border-bottom manage-menu-main" style={{ fontSize: 13, color: "#6f7782" }}>
                  <div className="name-col p-2" style={{ fontWeight: 400, color: "#6f7782" }}>
                    <span>Item Name</span>
                  </div>

                  <div className="selling-price-col p-2" style={{ color: "#6f7782" }}>
                    <span>{`Amount (${this.getCurrencySymbol(this.state.currency)})`}</span>
                  </div>

                  {/* <div className="inventory-col p-2" style={{ color: "#6f7782" }}>
                      <span>Inventory</span>
                    </div> */}

                  <div className="image-col p-2" style={{ color: "#6f7782" }}>
                    <span>Image</span>
                  </div>

                  <div className="availability-col p-2" style={{ color: "#6f7782" }}>
                    <span>Availability</span>
                  </div>

                  {/* <div className="ingrediant-inventory-col p-2" style={{ color: "#6f7782" }}>
                      <span>Ingrediant Inventory</span>
                    </div> */}
                </div>

                <div id="accordion" className="accordion category-accordion">
                  {this.state.categories.map((category, i) => (
                    <div key={i}>
                      <div className="card-header px-0 border-bottom" style={{ border: 0 }}>
                        <span className="card-title" style={{ fontWeight: 500 }}>
                          <i data-toggle="collapse" data-target={`#collapse${category.id}`} aria-expanded="false" aria-controls={`collapse${category.id}`} className="fas fa-caret-right"></i>
                          <EdiText
                            type="text"
                            value={category.title}
                            onEditingStart={() =>
                              this.setState({
                                editCategoryName: category.title,
                                editCategoryId: category.id,
                              })
                            }
                            saveButtonClassName="item-name-save-button"
                            editButtonClassName="category-name-edit-button"
                            cancelButtonClassName="item-name-cancel-button"
                            onSave={this.onSaveCategoryName}
                          />
                        </span>

                        <div className="category-collapse-area" data-toggle="collapse" data-target={`#collapse${category.id}`} aria-expanded="false" aria-controls={`collapse${category.id}`}></div>

                        <div className="category-availability-wrapper">
                          <div className="custom-control custom-switch m-0">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={category.id}
                              checked={category.isAvailable}
                              onChange={() => this.handleCategoryAvailability(category.id, !category.isAvailable)}
                            />
                            <label className="custom-control-label" htmlFor={category.id}></label>
                          </div>
                        </div>
                      </div>

                      <div className="card-block collapse show" id={`collapse${category.id}`}>
                        <div className="card-body p-0">
                          <div id="accordion1" className="accordion menu-accordion">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div {...provided.droppableProps} ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver)}>
                                    {this.state.items &&
                                      this.state.items.map((item, index) => {
                                        if (item.category.id === category.id && item.isDeleted === false)
                                          return (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                >
                                                  <div
                                                    className="p-0 border-bottom menu-item"
                                                    style={
                                                      category.isAvailable
                                                        ? {
                                                            cursor: "pointer",
                                                            border: 0,
                                                          }
                                                        : {
                                                            backgroundColor: "#ececec",
                                                          }
                                                    }
                                                  >
                                                    <div className="row m-0">
                                                      <div
                                                        className="name-col p-2"
                                                        onClick={() => this.editMenuItemSidebarShow(item)}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <span className="draggable-icon">
                                                          <i className="fas fa-bars"></i>
                                                        </span>

                                                        <span>{item.name}</span>
                                                      </div>

                                                      <div className="selling-price-col p-2" onClick={() => this.editMenuItemSidebarShow(item)}>
                                                        <NumberFormat value={item.displayPrice.toFixed(2)} displayType={"text"} thousandSeparator={true} />
                                                      </div>

                                                      {/* <div className="inventory-col p-2" onClick={() => this.editMenuItemSidebarShow(item)}>
                                                      </div> */}

                                                      <div className="image-col p-2" onClick={() => this.editMenuItemSidebarShow(item)}>
                                                        {item.thumbnail ? (
                                                          <span>
                                                            <i
                                                              className="fas fa-check-circle text-success"
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            ></i>{" "}
                                                          </span>
                                                        ) : (
                                                          <span>
                                                            <i
                                                              className="fas fa-check-circle"
                                                              style={{
                                                                color: "#e0e0e0",
                                                                fontSize: 15,
                                                              }}
                                                            ></i>{" "}
                                                          </span>
                                                        )}
                                                      </div>
                                                      <div className="availability-col p-2">
                                                        <div className="custom-control custom-switch m-0">
                                                          <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id={item.id}
                                                            checked={item.isAvailable}
                                                            disabled={!category.isAvailable}
                                                            onChange={() => this.handleItemAvailability(item.id, !item.isAvailable)}
                                                          />
                                                          <label className="custom-control-label" htmlFor={item.id}></label>
                                                        </div>
                                                      </div>

                                                      {/* <div className="ingrediant-inventory-col p-2" onClick={() => this.editMenuItemSidebarShow(item)}>
                                                      </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          );
                                      })}

                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* SIDEBAR */}
                <CheeseburgerMenu right topOffset={65} width={450} isOpen={this.state.menuOpen} closeCallback={this.closeMenu.bind(this)}>
                  <div className="card-body p-0" style={{ fontSize: 13 }}>
                    <div className="m1-auto" style={{ padding: 15 }}>
                      <div className="name-col item-name-wrapper mb-3">
                        <EdiText
                          type="text"
                          value={this.state.editName}
                          onEditingStart={() =>
                            this.setState({
                              editName: this.state.editName,
                              menuItem: this.state.itemId,
                            })
                          }
                          className="edit-item-name"
                          saveButtonClassName="item-name-save-button"
                          editButtonClassName="item-name-edit-button"
                          cancelButtonClassName="item-name-cancel-button"
                          onSave={this.onSaveItemName}
                        />
                        <div className="remove-item" onClick={() => this.showDelModal(this.state.itemId)}>
                          Remove Item
                        </div>
                      </div>
                      <div className="item-name-wrapper"></div>

                      <div className="edit-image">
                        <div className="update-image-div">
                          {this.state.editImage ? (
                            <>
                              <img src={`${this.state.editImage}?${this.state.imageHash}`} alt="item menu" />
                              <i className="fas fa-times edit-item-del-img-btn" onClick={() => this.deleteImage(this.state.itemId)}></i>
                            </>
                          ) : (
                            <img src={empty} alt="item menu" />
                          )}

                          {this.state.uploadingImage ? (
                            <div className="spinner-border spinner-border-sm image-upload" role="status">
                              <span className="sr-only">Uploading image...</span>
                            </div>
                          ) : (
                            <div className="update-image-div-inputs">
                              <label htmlFor="file-input">
                                <i className="fas fa-plus" onClick={() => this.setState({ image: "" })}></i>
                              </label>
                              <input type="file" id="file-input" className="file-input" onChange={this.handleUpdateImage} />
                            </div>
                          )}
                        </div>
                      </div>

                      <div>
                        <form>
                          <div className="form-row m-0">
                            <div className="form-group col-6 mb-3 pl-0  business-type-col">
                              <label htmlFor="edit-price" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                                Price
                              </label>
                              <input
                                type="number"
                                className={classnames("form-control", {
                                  "is-invalid": this.state.editPriceError,
                                })}
                                id="edit-price"
                                name="editPrice"
                                min="0"
                                step=".01"
                                value={this.state.editPrice}
                                onChange={this.handleChangeInputs}
                                style={{ paddingLeft: 34 }}
                              />
                              <span className="price-unit" style={{ top: 38 }}>
                                {this.state.currency.code}
                              </span>
                              <div className="invalid-feedback">{this.state.editPriceError}</div>
                            </div>

                            <div className="form-group col-6 mb-2 pr-0 business-type-col">
                              <label htmlFor="editDiscountPrice" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                                Discounted Price <span style={{ fontSize: 13, fontWeight: 300 }}>(Optional)</span>
                              </label>
                              <input
                                type="number"
                                className={classnames("form-control", {
                                  "is-invalid": this.state.editPriceError,
                                })}
                                id="editDiscountPrice"
                                name="editDiscountPrice"
                                min="0"
                                step=".01"
                                value={
                                  this.state.portionSizesList.length > 0
                                    ? this.state.portionSizesList.reduce((prev, curr) => (parseInt(prev.calculatedPrice) > parseInt(curr.calculatedPrice) ? curr : prev)).calculatedDiscountPrice
                                    : this.state.editDiscountPrice
                                }
                                onChange={this.handleChangeInputs}
                                style={{ paddingLeft: 34 }}
                              />
                              <span className="price-unit" style={{ top: 38, left: 14 }}>
                                {this.state.currency.code}
                              </span>
                            </div>
                          </div>

                          <div className="portion-size-wrapper">
                            <div className="add-ons-wrapper">
                              <h6>Portion Sizes</h6>
                            </div>

                            <div className="form-row m-0">
                              <div
                                className="form-group mb-3 pr-0 pl-0 business-type-col"
                                style={{
                                  width: "28.5%",
                                  position: "relative",
                                }}
                              >
                                <label htmlFor="portionSizeNameEdit" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                                  <br />
                                  Portion Size
                                </label>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.state.portionSizeNameEditError,
                                  })}
                                  id="portionSizeNameEdit"
                                  name="portionSizeNameEdit"
                                  value={this.state.portionSizeNameEdit}
                                  onChange={this.handlePortion}
                                />
                                <div className="invalid-feedback">{this.state.portionSizeNameEditError}</div>
                              </div>
                              <div
                                className="form-group mb-2 business-type-col"
                                style={{
                                  width: "27%",
                                  paddingLeft: 10,
                                  position: "relative",
                                }}
                              >
                                <label htmlFor="portionSizePriceEdit" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                                  <br />
                                  Price ({this.state.currency.code})
                                </label>
                                <input
                                  type="number"
                                  className={classnames("form-control", {
                                    "is-invalid": this.state.portionSizePriceEditError,
                                  })}
                                  id="portionSizePriceEdit"
                                  name="portionSizePriceEdit"
                                  min="0"
                                  step=".01"
                                  value={this.state.portionSizePriceEdit}
                                  onChange={this.handlePortion}
                                />
                                <div className="invalid-feedback">{this.state.portionSizePriceEditError}</div>
                              </div>

                              <div
                                className="form-group mb-2 pr-0 business-type-col"
                                style={{
                                  width: "27%",
                                  paddingLeft: 10,
                                  position: "relative",
                                }}
                              >
                                <label
                                  htmlFor="portionSizeDisPriceEdit"
                                  className="page-inner-heading ml-0"
                                  // style={{ fontSize: 11 }}
                                >
                                  Discounted Price ({this.state.currency.code})
                                </label>
                                <input
                                  type="number"
                                  className={classnames("form-control pr-2", {
                                    "is-invalid": this.state.portionSizeDisPriceEditError,
                                  })}
                                  id="portionSizeDisPriceEdit"
                                  name="portionSizeDisPriceEdit"
                                  min="0"
                                  step=".01"
                                  value={this.state.portionSizeDisPriceEdit}
                                  onChange={this.handlePortion}
                                />

                                <div className="invalid-feedback">{this.state.portionSizeDisPriceEditError}</div>
                              </div>

                              <div className="form-group mb-2 pr-0 business-type-col text-center" style={{ paddingLeft: 9 }}>
                                <label className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                                  <br />
                                  &nbsp;
                                  <br />
                                </label>
                                <button type="button" className="btn btn-outline-primary d-block" onClick={this.handleAddProtionInEdit}>
                                  {this.state.loadingAddPortionEdit ? (
                                    <div className="spinner-border spinner-border-sm text-primary mr-0 pr-0" role="status" style={{ fontSize: 14 }}>
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  ) : (
                                    "Add"
                                  )}
                                </button>
                              </div>
                            </div>

                            {this.state.portionSizesList.length > 0 && (
                              <div className="sidebar-item-addon-display">
                                <table className="table table-striped mb-2 border" style={{ width: "83%" }}>
                                  <tbody>
                                    {this.state.portionSizesList
                                      .sort((a, b) => a.calculatedPrice - b.calculatedPrice)
                                      .map((item, i) => (
                                        <tr key={i}>
                                          <td width="34%" className="py-1 px-2">
                                            {item.name}
                                          </td>
                                          <td width="33%" className="py-1 px-2 text-right">
                                            <NumberFormat value={parseFloat(item.calculatedPrice).toFixed(2)} displayType={"text"} thousandSeparator={true} />
                                          </td>
                                          <td width="33%" className="py-1 px-2 text-right">
                                            {!item.calculatedDiscountPrice && "-"}
                                            <NumberFormat value={parseFloat(item.calculatedDiscountPrice).toFixed(2)} displayType={"text"} thousandSeparator={true} />
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                                <span
                                  onClick={() => this.props.showAddOnsPage("portionSizePage", this.state.portionSizesList, this.state.editPrice)}
                                  style={{
                                    display: "block",
                                    color: "#2979ff",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    margin: "-3px 1px 7px",
                                  }}
                                >
                                  Edit Portion Sizes
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-row m-0">
                            <div className="form-group col-6 mb-3 pl-0  business-type-col">
                              <label htmlFor="editMinOrder" className="page-inner-heading ml-0">
                                Min Order Quantity <span style={{ fontSize: 13, fontWeight: 300 }}>(Optional)</span>
                              </label>
                              <input
                                type="Number"
                                className={classnames("form-control", {
                                  "is-invalid": this.state.editMinOrderError,
                                })}
                                id="editMinOrder"
                                name="editMinOrder"
                                min="0"
                                value={this.state.editMinOrder}
                                onChange={this.handleChangeInputs}
                              />
                              <div className="invalid-feedback">{this.state.editMinOrderError}</div>
                            </div>

                            <div className="form-group col-6 mb-2 pr-0 business-type-col">
                              <label htmlFor="editMaxOrder" className="page-inner-heading ml-0">
                                Max Order Quantity <span style={{ fontSize: 13, fontWeight: 300 }}>(Optional)</span>
                              </label>
                              <input
                                type="Number"
                                className={classnames("form-control", {
                                  "is-invalid": this.state.editMaxOrderError,
                                })}
                                id="editMaxOrder"
                                name="editMaxOrder"
                                min="1"
                                value={this.state.editMaxOrder}
                                onChange={this.handleChangeInputs}
                                disabled={this.state.isDisableMax}
                              />
                              <div className="invalid-feedback">{this.state.editMaxOrderError}</div>
                            </div>
                          </div>

                          <div className="form-row m-0">
                            <div className="form-group col-6 mb-3 pl-0 business-type-col">
                              <label htmlFor="maxOrder" className="page-inner-heading ml-0">
                                Pre Order <span style={{ fontSize: 13, fontWeight: 300 }}>(Optional)</span>
                              </label>
                              <select className="form-control" name="category" value={this.state.editPreOrderHours} onChange={(event) => this.setState({ editPreOrderHours: event.target.value })}>
                                <option hidden value="">
                                  Select number of Days
                                </option>
                                <option value="">N/A</option>
                                {Array.from({ length: 14 }, (_, i) => i + 1).map((item) => (
                                  <option key={item} value={item}>
                                    {item} {item === 1 ? "day" : "days"}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="edit-description" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                              Description (Optional)
                            </label>
                            <textarea className="form-control" id="edit-description" rows="6" name="editDesc" value={this.state.editDesc} onChange={this.handleChangeInputs} maxLength="400"></textarea>
                            <p
                              style={{
                                fontSize: "11px",
                                color: "#6d6d6d",
                                textAlign: "right",
                                marginTop: "5px",
                                marginBottom: 0,
                              }}
                            >
                              Character Limit 400
                            </p>
                          </div>
                        </form>

                        <div className="manage-sidebar-menu-buttons">
                          <button type="button" className="manage-menu-cancel" onClick={this.closeMenu.bind(this)}>
                            Cancel
                          </button>

                          <button type="button" onClick={this.updateMenuItem} className="save-edit-btn">
                            {this.state.loading ? (
                              <div className="spinner-border spinner-border-sm text-light mr-0 pr-0" role="status" style={{ fontSize: 14 }}>
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : null}

                            {this.state.loading ? null : "Save"}
                          </button>
                        </div>

                        <div className="add-ons-wrapper">
                          <h6>
                            Add-ons <span onClick={() => this.props.showAddOnsPage("addOnsPage")}>(Edit)</span>
                          </h6>
                        </div>

                        <div className="sidebar-item-addon-display">
                          {this.state.itemAddOnList.map((item) => (
                            <div className="sidebar-item-addon-inner" key={item.id}>
                              <h6 className="sidebar-item-addon-title">
                                {item.name} ({item.isRequired ? "Required" : ""}, {item.maxSelections > 0 ? `Max Selections : ${item.maxSelections}` : ""})
                              </h6>
                              {item.items.map((addon) => (
                                <h6 className="sidebar-item-addon-info">
                                  - {addon.name} (+
                                  <NumberFormat value={addon.price.toFixed(2)} displayType={"text"} thousandSeparator={true} />)
                                </h6>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </CheeseburgerMenu>
              </div>
            </React.Fragment>

            {this.state.showNoItemError ? <p className="ml-3 text-danger d-block">Atleast one menu item is required!</p> : null}

            <div className="row mx-0 mt-4">
              <div className="col p-0">
                {this.props.history.location.pathname === "/manage-menu" ? null : (
                  <React.Fragment>
                    <button className="back-btn" onClick={this.back}>
                      <i className="fas fa-angle-left"></i> Back
                    </button>
                    <button className="save-btn" onClick={this.next}>
                      Save & Proceed <i className="fas fa-angle-right"></i>
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>

            {/* add item modal */}
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h6 className="modal-title" id="exampleModalCenterTitle">
                      Add Item
                    </h6>
                    <img src={close} type="button" className="close" data-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="add-item-category form-group mb-2 p-0">
                        <label htmlFor="name" className="page-inner-heading ml-0">
                          Item Category
                        </label>
                        <div className="add-item-category-inner">
                          {this.state.showCatInput ? (
                            <div className="add-category-wrapper">
                              <input type="text" name="newCategory" className="form-control business-type-col add-category-input" value={this.state.newCategory} onChange={this.handleChangeInputs} />

                              <button
                                type="button"
                                className="cancel-cat-btn"
                                onClick={() =>
                                  this.setState({
                                    showCatInput: false,
                                    newCategory: "",
                                  })
                                }
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div className="select-category-wrapper">
                              <select
                                className={classnames("form-control", {
                                  "is-invalid": this.state.categoryError,
                                })}
                                name="category"
                                value={this.state.category}
                                onChange={this.handleSelectCategory}
                              >
                                <option hidden value="">
                                  Select Category
                                </option>
                                {this.state.categories.map((category) => (
                                  <option key={category.title} value={category.title}>
                                    {category.title}
                                  </option>
                                ))}
                              </select>
                              <button
                                type="button"
                                className="add-item-category-btn"
                                onClick={() =>
                                  this.setState({
                                    showCatInput: true,
                                    categoryId: "",
                                    category: "",
                                  })
                                }
                              >
                                <i className="fas fa-plus"></i> Add
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="invalid-feedback">{this.state.categoryError}</div>
                      </div>

                      <div className="form-row m-0">
                        <div className="form-group mb-2 p-0 business-name-col add-item-modal">
                          <label htmlFor="name" className="page-inner-heading ml-0">
                            Title
                          </label>
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.nameError,
                            })}
                            id="name"
                            name="name"
                            autoComplete="off"
                            value={this.state.name}
                            onChange={this.handleChangeInputs}
                          />
                          <div className="invalid-feedback">{this.state.nameError}</div>
                        </div>
                      </div>

                      <div className="form-row m-0">
                        <div className="form-group col-6 mb-2 pl-0 price-col">
                          <label htmlFor="price" className="page-inner-heading ml-0">
                            Price
                          </label>
                          <input
                            type="number"
                            className={classnames("form-control", {
                              "is-invalid": this.state.priceError,
                            })}
                            id="price"
                            name="price"
                            min="0"
                            step=".01"
                            value={
                              this.state.createdPortionList.length > 0
                                ? this.state.createdPortionList.reduce((prev, curr) => (parseInt(prev.price) < parseInt(curr.price) ? prev : curr)).price
                                : this.state.price
                            }
                            onChange={this.handleChangeInputs}
                          />
                          <span className="price-unit">{this.state.currency.code}</span>
                          <div className="invalid-feedback">{this.state.priceError}</div>
                        </div>
                        <div className="form-group col-6 mb-2 pr-0 business-type-col">
                          <label htmlFor="discountPrice" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                            Discounted Price <span style={{ fontSize: 14, fontWeight: 300 }}>(Optional)</span>
                          </label>
                          <input
                            type="number"
                            className={classnames("form-control", {
                              "is-invalid": this.state.editPriceError,
                            })}
                            id="discountPrice"
                            name="discountPrice"
                            min="0"
                            step=".01"
                            value={
                              this.state.createdPortionList.length > 0
                                ? this.state.createdPortionList.reduce((prev, curr) => (parseInt(prev.price) < parseInt(curr.price) ? prev : curr)).discountPrice
                                : this.state.discountPrice
                            }
                            onChange={this.handleChangeInputs}
                            style={{ paddingLeft: 34 }}
                          />
                          <span className="price-unit" style={{ left: 14 }}>
                            {this.state.currency.code}
                          </span>
                        </div>
                      </div>

                      <div className="portion-size-wrapper">
                        <label htmlFor="price" className="page-inner-heading ml-0" style={{ fontSize: 15 }}>
                          Portion Sizes &nbsp;
                          <span style={{ fontSize: 14, fontWeight: 300 }}>(Optional)</span>
                        </label>

                        <div className="form-row m-0">
                          <div
                            className="form-group mb-3 pr-0 pl-0 business-type-col"
                            style={{
                              width: "28.5%",
                              paddingLeft: 10,
                              position: "relative",
                            }}
                          >
                            <label htmlFor="portionSizeName" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                              <br />
                              Portion Size
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                "is-invalid": this.state.portionSizeNameError,
                              })}
                              id="portionSizeName"
                              name="portionSizeName"
                              value={this.state.portionSizeName}
                              onChange={this.handlePortion}
                            />
                            <div className="invalid-feedback">{this.state.portionSizeNameError}</div>
                          </div>

                          <div
                            className="form-group mb-2 business-type-col"
                            style={{
                              width: "28%",
                              paddingLeft: 10,
                              position: "relative",
                            }}
                          >
                            <label htmlFor="portionSizePrice" className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                              <br />
                              Price ({this.state.currency.code})
                            </label>
                            <input
                              type="number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.portionSizePriceError,
                              })}
                              id="portionSizePrice"
                              name="portionSizePrice"
                              min="0"
                              step=".01"
                              value={this.state.portionSizePrice}
                              onChange={this.handlePortion}
                            />
                            <div className="invalid-feedback">{this.state.portionSizePriceError}</div>
                          </div>

                          <div
                            className="form-group mb-2 pr-0 business-type-col"
                            style={{
                              width: "28%",
                              paddingLeft: 10,
                              position: "relative",
                            }}
                          >
                            <label htmlFor="portionSizeDisPrice" className="page-inner-heading ml-0" style={{ fontSize: 12 }}>
                              Discounted Price ({this.state.currency.code})
                            </label>
                            <input
                              type="number"
                              className={classnames("form-control pr-2", {
                                "is-invalid": this.state.portionSizeDisPriceError,
                              })}
                              id="portionSizeDisPrice"
                              name="portionSizeDisPrice"
                              min="0"
                              step=".01"
                              value={this.state.portionSizeDisPrice}
                              onChange={this.handlePortion}
                            />
                            <div className="invalid-feedback">{this.state.portionSizeDisPriceError}</div>
                          </div>

                          <div className="form-group mb-2 pr-0 business-type-col text-center" style={{ paddingLeft: 9 }}>
                            <label className="page-inner-heading ml-0" style={{ fontSize: 13 }}>
                              <br /> &nbsp; <br />
                            </label>
                            <button type="button" className="btn btn-outline-primary d-block" onClick={this.addPortionSizeItem}>
                              Add
                            </button>
                          </div>
                        </div>

                        {this.state.createdPortionList.length > 0 && (
                          <div className="sidebar-item-addon-display">
                            <table className="table table-striped mb-2 border">
                              <tbody>
                                {this.state.createdPortionList
                                  .sort((a, b) => a.price - b.price)
                                  .map((item, i) => (
                                    <tr kry={i}>
                                      <td width="34%" className="py-1 px-2">
                                        {item.name}
                                      </td>
                                      <td width="25%" className="py-1 px-2 text-right">
                                        {`${this.getCurrencySymbol(this.state.currency)}`}
                                        <NumberFormat value={parseInt(item.price).toFixed(2)} displayType={"text"} thousandSeparator={true} />
                                      </td>
                                      <td width="25%" className="py-1 px-2 text-right">
                                        {item.discountPrice ? `${this.getCurrencySymbol(this.state.currency)}` : "-"}
                                        <NumberFormat value={parseInt(item.discountPrice).toFixed(2)} displayType={"text"} thousandSeparator={true} />
                                      </td>
                                      <td className="py-1 px-2 text-right">
                                        <span className="text-danger" onClick={() => this.removePortionItem(item.id)} style={{ fontSize: 14 }}>
                                          Delete
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>

                      <div className="form-row m-0">
                        <div className="form-group col-6 mb-2 pl-0  business-type-col">
                          <label htmlFor="minOrder" className="page-inner-heading ml-0">
                            Min Order Quantity{" "}
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 300,
                              }}
                            >
                              (Optional)
                            </span>
                          </label>
                          <input
                            type="Number"
                            className={classnames("form-control", {
                              "is-invalid": this.state.minOrderError,
                            })}
                            id="minOrder"
                            name="minOrder"
                            min="0"
                            value={this.state.minOrder}
                            onChange={this.handleChangeInputs}
                          />
                          <div className="invalid-feedback">{this.state.minOrderError}</div>
                        </div>

                        <div className="form-group col-6 mb-2 pr-0 business-type-col">
                          <label htmlFor="maxOrder" className="page-inner-heading ml-0">
                            Max Order Quantity <span style={{ fontSize: 14, fontWeight: 300 }}>(Optional)</span>
                          </label>
                          <input
                            type="Number"
                            className={classnames("form-control", {
                              "is-invalid": this.state.maxOrderError,
                            })}
                            id="maxOrder"
                            name="maxOrder"
                            min="1"
                            value={this.state.maxOrder}
                            onChange={this.handleChangeInputs}
                          />
                          <div className="invalid-feedback">{this.state.maxOrderError}</div>
                        </div>
                      </div>

                      <div className="form-row m-0">
                        <div className="form-group col-6 mb-3 pl-0 business-type-col">
                          <label htmlFor="maxOrder" className="page-inner-heading ml-0">
                            Pre Order <span style={{ fontSize: 14, fontWeight: 300 }}>(Optional)</span>
                          </label>
                          <select className="form-control" name="category" value={this.state.preOrderHours} onChange={(event) => this.setState({ preOrderHours: event.target.value })}>
                            <option hidden value="">
                              Select number of Days
                            </option>
                            <option value="">N/A</option>
                            {Array.from({ length: 14 }, (_, i) => i + 1).map((item) => (
                              <option key={item} value={item}>
                                {item} {item === 1 ? "day" : "days"}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="description" className="page-inner-heading ml-0">
                          Description (Optional)
                        </label>
                        <label htmlFor="name" className="page-inner-heading-sub ml-0">
                          Optional, but adding one makes for a better user experience
                        </label>
                        <textarea className="form-control" id="description" rows="6" name="description" value={this.state.description} onChange={this.handleChangeInputs} maxLength="200"></textarea>
                        <p
                          style={{
                            fontSize: "11px",
                            color: "#6d6d6d",
                            textAlign: "right",
                            marginTop: "5px",
                            marginBottom: 0,
                          }}
                        >
                          Character Limit 200
                        </p>
                      </div>
                    </form>

                    <div>
                      <label htmlFor="description" className="page-inner-heading ml-0">
                        Image
                      </label>
                      <label htmlFor="name" className="page-inner-heading-sub ml-0">
                        Add a clear, square image that shows your item accurately
                      </label>
                      {this.state.image !== "" ? (
                        <div className="item-image-div">
                          <img src={URL.createObjectURL(this.state.image)} width="100" alt="item menu" />
                          <i className="fas fa-times" onClick={() => this.setState({ image: "" })}></i>
                        </div>
                      ) : (
                        <div className="item-input-div">
                          <input type="file" className="imgUpload" onChange={this.handleChangeFile} id="imgUpload" accept="image/x-png,image/gif,image/jpeg" />
                          <label className="item-img-upload-label" htmlFor="imgUpload">
                            {this.state.imageLoading ? (
                              <div className="spinner-border spinner-border-md text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span className="add-image-text">Add Image</span>
                            )}
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="add-item-buttons-wrapper">
                      <button type="button" className="manage-menu-cancel" data-dismiss="modal" id="add-menu-close-btn" style={{ marginRight: 10, padding: "6px 15px" }}>
                        Cancel
                      </button>

                      <button
                        className="add-item"
                        type="button"
                        onClick={() => this.addItem(false)}
                        style={{
                          marginRight: 10,
                          padding: "6px 15px",
                          width: 150,
                        }}
                      >
                        {!this.state.addAnother && this.state.loading ? (
                          <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Save and Exit"
                        )}
                      </button>
                      <button className="add-item" type="button" onClick={() => this.addItem(true)} style={{ padding: "6px 15px", width: 150 }}>
                        {this.state.addAnother && this.state.loading ? (
                          <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Add Next Item"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* manage category modal */}
            <div className="modal fade" id="manageCatModal" tabIndex="-1" role="dialog" aria-labelledby="manage-category" aria-hidden="true" style={{ zIndex: "999999999" }}>
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h6 className="modal-title" id="manage-category">
                      Change Category Order
                    </h6>
                    <img src={close} type="button" className="close" data-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body" style={{ minHeight: "400px" }}>
                    <DragDropContext onDragEnd={this.onDragEndCategory}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div {...provided.droppableProps} ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver)}>
                            {this.state.categories.map((category, i) => {
                              return (
                                <Draggable key={category.id.toString()} draggableId={category.id.toString()} index={i}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <div className="row m-0 border-bottom" key={i}>
                                        <div className="col p-2">
                                          <p className="card-title mb-0" style={{ fontWeight: 500 }}>
                                            &nbsp; {category.title} &nbsp;{" "}
                                            {this.state.items.filter((filter) => filter.category.id === category.id).length === 0 ? (
                                              <i className="fas fa-trash-alt text-danger" style={{ cursor: "pointer" }} onClick={() => this.delCategory(category.id)}></i>
                                            ) : null}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>
            </div>

            {this.state.showDelItemModal ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, Delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => this.delItem(this.state.itemId)}
                onCancel={this.onCancel}
                focusCancelBtn
                btnSize="sm"
                cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
              >
                You will not be able to recover this item!
              </SweetAlert>
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ItemMenu);
