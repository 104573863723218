import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";

import { Lines } from "react-preloaders";

import Breadcrumb from "../../Breadcrumb";

import SecTitle from "../SecTitle";
import CompleteInfo from "../CompleteInfo";

import "../../../styles/seo.css";

import close from "../../../images/close-modal.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class Domain extends Component {
  state = {
    listingId: "",
    url: "",
    cusDomain: "",

    // user
    name: JSON.parse(localStorage.getItem("profile")).name,
    email: JSON.parse(localStorage.getItem("profile")).email,
    phone: "",

    // errors
    urlError: "",
    cusDomainError: "",

    // show - hide
    status: "",
    isComBranding: true,

    loading: false,
    loadingReq: false,
    loadingCancel: false,

    // pre loading
    preLoading: true,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          listingName: res.data[0].name,
          url: res.data[0].customDomain
            ? res.data[0].customDomain
            : res.data[0].extId,
          phone: res.data[0].phone
            ? res.data[0].phone
            : JSON.parse(localStorage.getItem("profile")).phone
            ? JSON.parse(localStorage.getItem("profile")).phone
            : "",
          status: res.data[0].status,
          loadingCancel: false,
          preLoading: false,
        });

        // branding
        if (res.data[0].branding && res.data[0].branding.imageCover) {
          this.setState({ isComBranding: true });
        } else {
          this.setState({ isComBranding: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ preLoading: false });
      });
  };

  handleChange = (e) => {
    if (e.target.name === "url") {
      if (!e.target.value.match(/^[a-zA-Z0-9-_]+$/)) {
        this.setState({
          [e.target.name]: e.target.value.trim(),
          urlError: "Only alphanumeric, dash and underscore are allowed",
        });
      } else {
        this.setState({ urlError: "", [e.target.name]: e.target.value.trim() });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      });
    }
  };

  // validate inputs
  validate = () => {
    let urlError = "";

    if (this.state.url === "") {
      urlError = "Online restaurant name (URL) is required";
    } else if (!this.state.url.match(/^[a-zA-Z0-9-_]+$/)) {
      urlError = "Only alphanumeric, dash and underscore are allowed";
    }

    if (urlError) {
      this.setState({
        urlError,
      });
      return false;
    }

    return true;
  };

  handleSave = () => {
    if (this.validate()) {
      // set loading
      this.setState({ urlError: "", loading: true });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/update_extid`,
          `extId=${this.state.url}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          success();

          window.location.reload();
        })
        .catch((err) => {
          this.setState({ loading: false });
          fail();
        });
    }
  };

  // validate inputs
  validateReq = () => {
    let cusDomainError = "";

    if (this.state.cusDomain === "") {
      cusDomainError = "Your domain is required";
    }

    if (cusDomainError) {
      this.setState({
        cusDomainError,
      });
      return false;
    }

    return true;
  };

  // submit
  handleRequest = () => {
    if (this.validateReq()) {
      // set loading
      this.setState({ cusDomainError: "", loadingReq: true });

      const contactDetails = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone ? this.state.phone : "No phone",
        subject: `Requesting to change URL domain. Listing ID : ${this.state.listingId}, Listing Name: ${this.state.listingName}`,
        text: "please contact me",
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/contact`, contactDetails)
        .then((res) => {
          this.setState({
            loadingReq: false,
          });

          success();

          document.getElementById("close-custom-domain").click();
        })
        .catch((err) => {
          this.setState({
            loadingReq: false,
          });

          fail();
        });
    }
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    this.getListing();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#fff"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            <div className="settings-container">
              <Breadcrumb mainPage="Settings" subPage="Website Info" />
              <div className="sec">
                <SecTitle
                  title="Domains (URL)"
                  titleDesc="This is the web address for your ecommerse store front. Customers can find you online using this address and place orders by themselves."
                />
                <div className="row m-0 content-container">
                  <div className="col-6 p-0">
                    <div
                      className="sign-up-form p-0"
                      style={{ maxWidth: "90%" }}
                    >
                      <form className="setting-form ">
                        <div className="form-row">
                          <div className="form-group w-100 mb-0 mb-2 p-0">
                            <label htmlFor="url" className="title ml-0">
                              Change Store URL
                            </label>
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.state.urlError,
                                })}
                                name="url"
                                id="url"
                                value={this.state.url}
                                onChange={this.handleChange}
                              />
                              <div className="input-group-append">
                                <div className="input-group-text">
                                  .spritzer.app{" "}
                                  {this.state.showWarning && (
                                    <i className="fas fa-exclamation-triangle ml-1"></i>
                                  )}
                                </div>
                              </div>
                              <div className="invalid-feedback">
                                {this.state.urlError}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <p
                        data-toggle="modal"
                        data-target="#custom-domain"
                        style={{
                          color: "#2979FF",
                          fontSize: 14,
                          lineHeight: "16px",
                          marginTop: 25,
                          cursor: "pointer",
                        }}
                      >
                        Request to connect existing domain
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row m-0 content-container">
                  <div className="col-md p-0">
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={this.handleSave}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                          style={{ margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Save"}
                    </button>
                    <button
                      type="button"
                      className="btn setting-cancel-btn float-right"
                      onClick={this.handleCancel}
                    >
                      {this.state.loadingCancel ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#4f4f4f", margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loadingCancel ? "" : "Cancel"}
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="modal fade add-cost-com"
                id="custom-domain"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="custom-domain"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header px-4">
                      <h5
                        className="modal-title"
                        id="custom-domain"
                        style={{ fontSize: 22, fontWeight: "normal" }}
                      >
                        Connect existing domain
                      </h5>

                      <img
                        src={close}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-custom-domain"
                        onClick={this.cancelDelete}
                        alt="close"
                      />
                    </div>
                    <div className="modal-body m-2">
                      <div className="row m-0">
                        <div className="col-md p-0">
                          <p
                            style={{
                              fontWeight: 300,
                              fontSize: 14,
                              marginBottom: 5,
                              color: "#333333",
                            }}
                          >
                            Enter the domain you want to connect
                          </p>
                          <form>
                            <div className="form-row mx-0">
                              <div className="form-group w-100 mb-0 p-0">
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.state.cusDomainError,
                                  })}
                                  name="cusDomain"
                                  id="cusDomain"
                                  value={this.state.cusDomain}
                                  onChange={this.handleChange}
                                  placeholder="Eg. example.com"
                                />
                                <div className="invalid-feedback">
                                  {this.state.cusDomainError}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="row mt-4 m-0 ">
                        <div className="col-md p-0">
                          <button
                            type="button"
                            id="location-setting"
                            className="btn setting-save-btn"
                            onClick={this.handleRequest}
                            style={{
                              display: "block",
                              margin: "auto",
                              width: 200,
                            }}
                          >
                            {this.state.loadingReq ? (
                              <div
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : null}

                            {this.state.loadingReq ? "" : "Request"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.status === "verified" ? null : this.state
                .isComBranding ? null : (
              <CompleteInfo
                title="Add a Cover Image for Your Storefront"
                desc="You need to add a cover image to your website before publishing it."
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
