import React, { Component } from "react";
import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import Shipday from "../components/integrations/Shipday";
import TitleOfPage from "../components/TitleOfPage";

export default class Integrations extends Component {
  state = {
    show: true,
    isComMenu: true,
    isAddOns: false,
  };

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "";
      document.getElementsByClassName(
        "page-section-heading"
      )[0].style.marginLeft = "20px";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
      document.getElementsByClassName(
        "page-section-heading"
      )[0].style.marginLeft = "50px";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div className="dashboard-content-col pb-4">
              {this.state.show ? null : (
                <i
                  class="fas fa-chevron-circle-right side-menu-show"
                  onClick={() => this.showMenu(true)}
                ></i>
              )}
              <TitleOfPage title="Integrations - Spritzer Dashboard" />

              <div style={{ maxWidth: "93%", margin: "auto" }}>
                <Shipday />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
