import React, { Component } from "react";
import axios from "axios";

import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import TitleOfPage from "../components/TitleOfPage";
import Delivery from "../components/settings/order/Delivery";
import Pickup from "../components/settings/order/Pickup";
import DineIn from "../components/settings/order/DineIn";

import "../styles/payment.css";

import { isAuthenticated } from "../auth/isAuth";

export default class Payment extends Component {
  state = {
    show: true,
    showDineIn: false,
  };

  handleSideMenu = () => {
    if (window.screen.width < 768) {
      this.setState({ show: false });
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
    }
  };

  componentDidMount = () => {
    this.handleSideMenu();
    window.addEventListener("resize", this.handleSideMenu);

    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          showDineIn: res.data[0].subscription
            ? res.data[0].subscription.features.dineIn
              ? true
              : false
            : true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false });
      });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSideMenu);
  }

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div
              className="dashboard-content-col pb-4"
              style={{ background: "#f2f2f2" }}
            >
              {this.state.show ? null : (
                <i
                  class="fas fa-chevron-circle-right side-menu-show"
                  onClick={() => this.showMenu(true)}
                ></i>
              )}
              <TitleOfPage title="Order Info - Spritzer Dashboard" />
              <Delivery />
              <Pickup margin={this.state.showDineIn ? false : true} />
              {this.state.showDineIn && <DineIn />}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
