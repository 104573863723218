import React, { useState, useEffect } from 'react'
import axios from "axios";
import { isAuthenticated } from "../auth/isAuth";
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import { toast } from 'react-toastify';
import qs from "qs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddOnsSub from './AddOnsSub';
import SweetAlert from 'react-bootstrap-sweetalert';
import EdiText from 'react-editext'


const AddOns = (props) => {
    console.log(">>>> props >>>> ", props);

    const [state, setState] = useState([]);
    const [addGroup, setAddGroup] = useState("");
    const [showAddGroupInput, setShowAddGroupInput] = useState(false);
    const [showAddItemInput, setShowAddItemInput] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedAddOnId, setSelectedAddOnId] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [availableAddOnList, setAvailableAddOnList] = useState([]);
    const [newItem, setNewItem] = useState({ value: "", isNewItem: true });
    const [itemPrice, setItemPrice] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ nameError: "", priceError: "", groupError: "" });
    const [showDelGroupAlert, setShowDelGroupAlert] = useState(false);
    const [showDelItemAlert, setShowDelItemAlert] = useState(false);
    const [onDelete, setOnDelete] = useState({ groupId: "", itemId: "" });
    const [addOnGroupDetails, setAddOnGroupDetails] = useState({ name: "", groupId: "" });
    const [addOnItemDetails, setAddOnItemDetails] = useState({ itemId: "", groupId: "" });


    useEffect(() => {
        getAddOns();
        getAvailableAddOnList()
    }, []);


    const getAddOns = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups?menuItemId=${props.itemId}`,
            {
                headers: {
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            }
        )
            .then((res) => {

                const groups = res.data;
                let groupList = []

                for (let i = 0; i < groups.length; i++) {
                    const sorted = groups[i].items.sort((a, b) => a.sortOrder - b.sortOrder);
                    let data = groups[i];
                    data.items = sorted
                    groupList.push(data)
                }

                groupList = groupList.sort((a, b) => a.sortOrder - b.sortOrder);
                setState(groupList)
            })
            .catch((err) => console.log(err));
    }

    const getAvailableAddOnList = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_items`,
            {
                headers: {
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            }
        )
            .then((res) => {
                const addOnList = res.data.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
                setAvailableAddOnList(addOnList);
            })
            .catch((err) => console.log(err));
    }

    const handleAddAddOnGroup = (event) => {
        setAddGroup(event.target.value)
    }

    const handleAddGroup = () => {
        if (addGroup) {
            const groupName = {
                name: addGroup,
                isRequired: true,
                maxSelections: 0,
                menuItemId: props.itemId,
            }

            axios.post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups`,
                groupName,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${isAuthenticated().providerToken}`,
                    },
                })

                .then((res) => {
                    axios.get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups?menuItemId=${props.itemId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${isAuthenticated().providerToken}`,
                            },
                        }
                    )
                        .then((res) => {
                            const groups = res.data;
                            let groupList = []

                            for (let i = 0; i < groups.length; i++) {
                                const sorted = groups[i].items.sort((a, b) => a.sortOrder - b.sortOrder);
                                let data = groups[i];
                                data.items = sorted
                                groupList.push(data)
                            }

                            groupList = groupList.sort((a, b) => a.sortOrder - b.sortOrder);
                            getAvailableAddOnList()
                            setState(groupList)
                            setAddGroup("")
                            setShowAddGroupInput(false)
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        } else {
            setError({ ...error, groupError: "Group name is required" })
        }
    }

    const handleCancelAddGroup = () => {
        setShowAddGroupInput(false)
        setAddGroup("")
    }

    const handleAddAddonItem = (groupId) => {
        setSelectedGroupId(groupId);
        setShowAddItemInput(prevShowAddItemInput => ({
            ...prevShowAddItemInput,
            [groupId]: !prevShowAddItemInput[groupId]
        }));
    }

    const handleCancelAddItem = () => {
        setShowAddItemInput(false)
        setNewItem({ ...newItem, value: "" });
        setSelectedGroupId("");
        setItemPrice("");
        setError({ ...error, nameError: "", priceError: "" })
    }


    const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const getSuggestions = value => {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('^' + escapedValue, 'i');
        const suggestions = availableAddOnList.filter(addOn => regex.test(addOn.name));

        if (suggestions.length === 0) {
            return [
                { isAddNew: true }
            ];
        }

        return suggestions;
    }

    const addItemOnChange = (event, { newValue, method }) => {
        const checkItemAvailability = availableAddOnList.filter(item => item.name === event.target.value)
        if (checkItemAvailability) {
            setNewItem({ ...newItem, isNewItem: true, value: newValue });
        } else {
            setNewItem({ ...newItem, isNewItem: false, value: newValue });
        }
    };

    const getSuggestionValue = suggestion => {
        if (suggestion.isAddNew) {
            return newItem.value;
        }

        return suggestion.name;
    };

    const renderSuggestion = (suggestion, { query }) => {
        if (suggestion.isAddNew) {
            return (
                <span className="add-new-addon">
                    <strong>{newItem.value}</strong> ( + Add label )
                </span>
            );
        } else {
            const matches = AutosuggestHighlightMatch(suggestion.name, query);
            const parts = AutosuggestHighlightParse(suggestion.name, matches);

            return (
                <span>
                    {parts.map((part, index) => {
                        const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

                        return (
                            <span className={className} key={index}>
                                {part.text}
                            </span>
                        );
                    })}
                </span>
            );
        }
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        if (suggestion.isAddNew) {
            setNewItem({ ...newItem, isNewItem: true });
        } else {
            setNewItem({ ...newItem, isNewItem: false, value: suggestion.name });
            setSelectedAddOnId(suggestion.id)
        }
    };

    // validate
    const validate = () => {
        let nameError = "";
        let priceError = "";

        if (newItem.value === "") {
            nameError = "Item name is required";
        }

        if (itemPrice === "") {
            priceError = "Price is required";
        }

        if (nameError || priceError) {
            setError({ ...error, nameError, priceError })
            return false;
        }
        return true;
    };

    const handleAddAddonItemToGroup = () => {
        if (newItem.isNewItem === true && validate()) {
            setError({ ...error, nameError: "", priceError: "" })
            setLoading(true)
            const addonName = {
                name: newItem.value
            }

            axios.post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_items`,
                addonName,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${isAuthenticated().providerToken}`,
                    },
                })

                .then((res) => {
                    axios.post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${selectedGroupId}/group_items`,
                        {
                            price: itemPrice,
                            addOnId: res.data.id,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${isAuthenticated().providerToken}`,
                            },
                        })

                        .then((res) => {
                            setShowAddItemInput(false);
                            setNewItem({ ...newItem, isNewItem: false, value: "" });
                            toast.success("Item added to group");
                            getAddOns();
                            setLoading(false)
                            setItemPrice("");
                        })
                        .catch((err) => {
                            setLoading(false)
                            toast.error("Error! Please try again" + err.response && err.response.status ? `Error code: ${err.response.status}` : null);
                        });
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error("Error! Please try again");
                });
        }

        if (newItem.isNewItem === false && validate()) {
            axios.post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${selectedGroupId}/group_items`,
                {
                    price: itemPrice,
                    addOnId: selectedAddOnId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${isAuthenticated().providerToken}`,
                    },
                })

                .then((res) => {
                    setShowAddItemInput(false);
                    setNewItem({ ...newItem, isNewItem: false, value: "" });
                    toast.success("Item added to group");
                    getAddOns();
                    setLoading(false)
                    setItemPrice("");
                    setSelectedAddOnId("");
                })
                .catch((err) => {
                    setLoading(false)
                    toast.error("Error! Please try again");
                });
        }
    }

    const inputProps = {
        placeholder: "Type Add-On Name",
        value: newItem.value,
        onChange: addItemOnChange
    };

    const handleRemoveAddonGroup = (groupId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${groupId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            })

            .then((res) => {
                axios.get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups?menuItemId=${props.itemId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${isAuthenticated().providerToken}`,
                        },
                    }
                )
                    .then((res) => {
                        const groups = res.data;
                        let groupList = []

                        for (let i = 0; i < groups.length; i++) {
                            const sorted = groups[i].items.sort((a, b) => a.sortOrder - b.sortOrder);
                            let data = groups[i];
                            data.items = sorted
                            groupList.push(data)
                        }

                        groupList = groupList.sort((a, b) => a.sortOrder - b.sortOrder);
                        getAvailableAddOnList()
                        setState(groupList)
                        setShowDelGroupAlert(false);
                        setOnDelete({ ...onDelete, groupId: "" })
                        toast.success("Group Deleted Successfully");

                    })
                    .catch((err) => {
                        setShowDelGroupAlert(false);
                        setOnDelete({ ...onDelete, groupId: "" })
                        console.log(err)
                    });
            })
            .catch((err) => {
                setShowDelGroupAlert(false);
                setOnDelete({ ...onDelete, groupId: "" })
                toast.error("Error! Please try again");
            });
    }

    const handleRemoveAddon = (groupId, groupItemId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${groupId}/group_items/${groupItemId}`,
            {
                headers: {
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            })

            .then((res) => {
                axios.get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups?menuItemId=${props.itemId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${isAuthenticated().providerToken}`,
                        },
                    }
                )
                    .then((res) => {
                        const groups = res.data;
                        let groupList = []

                        for (let i = 0; i < groups.length; i++) {
                            const sorted = groups[i].items.sort((a, b) => a.sortOrder - b.sortOrder);
                            let data = groups[i];
                            data.items = sorted
                            groupList.push(data)
                        }

                        groupList = groupList.sort((a, b) => a.sortOrder - b.sortOrder);
                        getAvailableAddOnList()
                        setShowDelItemAlert(false)
                        setOnDelete({ ...onDelete, groupId: "", itemId: "" })
                        setState(groupList)
                        toast.success("Item Deleted Successfully");

                    })
                    .catch((err) => {
                        setShowDelItemAlert(false)
                        setOnDelete({ ...onDelete, groupId: "", itemId: "" })
                        console.log(err)
                    });
            })
            .catch((err) => {
                setLoading(false)
                toast.error("Error! Please try again");
                setShowDelItemAlert(false)
                setOnDelete({ ...onDelete, groupId: "", itemId: "" })
            });
    }

    const handleItemAvailability = (addonId, status) => {
        axios.post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_items/${addonId}`,
            qs.stringify({ availability: status }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            })

            .then((res) => {
                getAddOns();
            })
            .catch((err) => {
                setLoading(false)
                toast.error("Error! Please try again");
            });
    }

    // Drag and Drop Sorting
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        background: isDragging ? "#D4D4D4" : "white",
        boxShadow: isDragging ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : null,
        border: isDragging ? "1px solid #bababa" : null,

        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : null,
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.type === "droppableItem") {
            const items = reorder(
                state,
                result.source.index,
                result.destination.index
            );

            setState(items)
            const addOnGroupId = state[result.source.index].id;
            const sortedGroup = `${addOnGroupId}=${result.destination.index}`

            axios.post(
                `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/addon_group_order`,
                sortedGroup,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `Bearer ${isAuthenticated().providerToken}`,
                    },
                }
            )
                .then((res) => {
                    // get items
                    getAddOns();
                })
                .catch((err) => {
                    console.log(err);
                });

        } else if (result.type.includes("droppableSubItem")) {
            const parentId = result.type.replace("droppableSubItem-", "");
            const itemSubItemMap = state.reduce((acc, item) => {
                acc[item.id] = item.items;
                return acc;
            }, {});

            const subItemsForCorrespondingParent = itemSubItemMap[parentId];
            const reorderedSubItems = reorder(
                subItemsForCorrespondingParent,
                result.source.index,
                result.destination.index
            );

            let newItems = [...state];
            newItems = newItems.map(item => {
                if (item.id === parentId) {
                    item.items = reorderedSubItems;
                }
                return item;
            });

            setState(newItems);
            const addOnItemId = subItemsForCorrespondingParent[result.source.index].id;
            const sortedItem = `${addOnItemId}=${result.destination.index}`

            axios.post(
                `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${parentId}/addon_item_order`,
                sortedItem,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `Bearer ${isAuthenticated().providerToken}`,
                    },
                }
            )
                .then((res) => {
                    // get items
                    getAddOns();
                })
                .catch((err) => {
                    console.log(err);
                });

        }
    }

    const showDelGroupModal = (groupId) => {
        setShowDelGroupAlert(true)
        setOnDelete({ ...onDelete, groupId: groupId })
    };

    const showDelItemModal = (groupId, itemId) => {
        setShowDelItemAlert(true)
        setOnDelete({ ...onDelete, groupId: groupId, itemId: itemId })
    };

    const onCancel = () => {
        setShowDelGroupAlert(false);
        setShowDelItemAlert(false)
    };

    const updateAddOnGroupDetails = (val) => {
        const groupData = {
            name: val,
            menuItemId: props.itemId
        }
        axios.put(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${addOnGroupDetails.groupId}`,
            groupData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            }
        )
            .then((res) => {
                // get items
                getAddOns();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onChangeMaxSelections = (event, groupId) => {
        const groupData = {
            maxSelections: event.target.value,
            menuItemId: props.itemId
        }
        axios.put(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${groupId}`,
            groupData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            }
        )
            .then((res) => {
                // get items
                getAddOns();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onChangeRequiredSelection = (value, groupId) => {
        const groupData = {
            isRequired: value,
            menuItemId: props.itemId
        }
        axios.put(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${groupId}`,
            groupData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            }
        )
            .then((res) => {
                // get items
                getAddOns();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const updateAddOnItemDetails = (val) => {
        const itemData = {
            price: val,
            addOnId: addOnItemDetails.itemId
        };
        axios
          .put(`${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/addon_groups/${addOnItemDetails.groupId}/group_items/${addOnItemDetails.itemId}`, itemData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            // get items
            getAddOns();
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const handleAddOnItemDetails = (itemId, groupId) => {
        setAddOnItemDetails({ ...addOnItemDetails, itemId: itemId, groupId: groupId })
      }

    // TODO: make utility and move into it
    const getCurrencySymbol = (currency) => {
        return currency?.symbol || currency?.code;
    };

    return (
        <div>
            <div className="menu-breadcrumb">
                <span>Manage Menu</span> {">"} <span className="go-back-menu-item" onClick={() => props.showAddOnsPage("menuPage")}>Menu</span> {">"} <span className="menu-breadcrumb-bold">{props.selectedItemTitle}</span>
                <span className="menu-breadcrumb-line"></span>
            </div>

            <div className="add-ons-title"><h6>Add-ons for {props.selectedItemTitle}</h6></div>

            <div className="add-ons-details-wrapper">
                <div className="add-ons-table-header-title">
                    <div className="addons-details-col"></div>
                    <div className="addons-price-col" style={{width: "33%"}}>Price ({getCurrencySymbol(props.currency)})</div>
                    <div className="addons-availability-col" style={{visibility: "hidden", width: 0}}>Availability</div>
                    <div className="remove-addon-header">&nbsp;&nbsp;&nbsp;</div>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" type="droppableItem">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {state.map((addOnGroup, index) => (
                                    <Draggable key={addOnGroup.id} draggableId={addOnGroup.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>

                                                    <div className="add-ons-table-main" key={addOnGroup.id}>
                                                        <div className="addons-details-title-wrapper"
                                                            {...provided.dragHandleProps}>

                                                            <h6 className="addons-details-main-title">{ }</h6>

                                                            <EdiText
                                                                type="text"
                                                                value={addOnGroup.name}
                                                                onEditingStart={() => setAddOnGroupDetails({ ...addOnGroupDetails, name: addOnGroup.name, groupId: addOnGroup.id })}
                                                                saveButtonClassName="item-name-save-button"
                                                                editButtonClassName="category-name-edit-button"
                                                                cancelButtonClassName="item-name-cancel-button"
                                                                onSave={updateAddOnGroupDetails}
                                                            />

                                                            <div className="add-ons-settings">
                                                                <div className="add-ons-settings-inner">
                                                                    <div className="required-selection-wrapper">
                                                                        <label className="required-selection-label" htmlFor="">Required Selection</label>
                                                                        <div className="custom-control custom-switch m-0">
                                                                            <input type="checkbox" className="custom-control-input" id={addOnGroup.id} checked={addOnGroup.isRequired} onChange={() => onChangeRequiredSelection(!addOnGroup.isRequired, addOnGroup.id)} />
                                                                            <label className="custom-control-label" htmlFor={addOnGroup.id}></label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="max-selections-wrapper">
                                                                        <label>Max Selections</label>
                                                                        <select name="maxSelections" id="max-selections" value={addOnGroup.maxSelections} onChange={(event) => onChangeMaxSelections(event, addOnGroup.id)}>
                                                                            <option hidden value="">Select max selection</option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="remove-addon-group" onClick={() => showDelGroupModal(addOnGroup.id)}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <AddOnsSub
                                                            items={addOnGroup.items}
                                                            type={addOnGroup.id}
                                                            handleItemAvailability={handleItemAvailability}
                                                            groupId={addOnGroup.id}
                                                            updateAddOnItemDetails={updateAddOnItemDetails}
                                                            handleAddOnItemDetails={handleAddOnItemDetails}
                                                            showDelItemModal={showDelItemModal}
                                                        />

                                                        {showAddItemInput[addOnGroup.id] ?
                                                            <div className="add-ons-table-body-inner">
                                                                <div className="add-addon-col">
                                                                    <div className="add-item-name-col">
                                                                        <Autosuggest
                                                                            suggestions={suggestions}
                                                                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                                            getSuggestionValue={getSuggestionValue}
                                                                            renderSuggestion={renderSuggestion}
                                                                            onSuggestionSelected={onSuggestionSelected}
                                                                            inputProps={inputProps}
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            {error.nameError}
                                                                        </div>
                                                                    </div>
                                                                    <div className="add-price-col">
                                                                        <input type="number" name="name" className="form-control business-type-col add-price-input"
                                                                            placeholder="Enter Price"
                                                                            autoComplete="off"
                                                                            value={itemPrice}
                                                                            onChange={(event) => setItemPrice(event.target.value)}
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            {error.priceError}
                                                                        </div>
                                                                    </div>
                                                                    <div className="add-Item-input-wrapper">
                                                                        <button type="button" className="add-cat-btn" onClick={handleAddAddonItemToGroup}>
                                                                            {loading ?
                                                                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </div>
                                                                                :
                                                                                <i className="fas fa-check"></i>}
                                                                        </button>
                                                                        <button type="button" className="cancel-cat-btn" onClick={handleCancelAddItem}><i className="fas fa-times"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                        }

                                                        <div className="add-addon" onClick={() => handleAddAddonItem(addOnGroup.id)}>+ Add</div>
                                                    </div>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <div className="add-addon-group" onClick={() => setShowAddGroupInput(true)}>+ Add Group</div>

                {showAddGroupInput ?
                    <div className="add-addon-group-wrapper">
                        <div className="add-addon-group-input">
                            <input type="text" name="name" className="form-control business-type-col add-category-input"
                                placeholder="Enter Group Name"
                                autoComplete="off"
                                value={addGroup}
                                onChange={handleAddAddOnGroup} />
                            <div className="invalid-feedback">
                                {error.groupError}
                            </div>
                        </div>

                        <button type="button" className="add-cat-btn" onClick={handleAddGroup}>Add</button>
                        <button type="button" className="cancel-cat-btn" onClick={handleCancelAddGroup}>Cancel</button>
                    </div>
                    : null
                }

            </div>

            {showDelItemAlert ? (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => handleRemoveAddon(onDelete.groupId, onDelete.itemId)}
                    onCancel={onCancel}
                    focusCancelBtn
                    btnSize="sm"
                    cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
                >
                    You will not be able to recover this item!
                </SweetAlert>
            ) : null}

            {showDelGroupAlert ? (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => handleRemoveAddonGroup(onDelete.groupId)}
                    onCancel={onCancel}
                    focusCancelBtn
                    btnSize="sm"
                    cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
                >
                    You will not be able to recover this item!
                </SweetAlert>
            ) : null}

        </div >
    )
}

export default AddOns
