import React, { useState, useEffect } from "react";
import axios from "axios";
import classnames from "classnames";
import { isAuthenticated } from "../auth/isAuth";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SweetAlert from "react-bootstrap-sweetalert";
import EdiText from "react-editext";

const PortionSize = (props) => {
  const [state, setState] = useState([]);
  const [update, setUpdate] = useState(false);
  const [showDelItemAlert, setShowDelItemAlert] = useState(false);
  const [editName, setEditName] = useState({ name: "", id: "" });
  const [editPrice, setEditPrice] = useState({ price: "", id: "" });
  const [editDisPrice, setEditDisPrice] = useState({ disPrice: "", id: "" });
  const [showAddItemInput, setShowAddItemInput] = useState(false);
  const [addNew, setAddNew] = useState({ name: "", price: "", disPrice: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ nameError: "", priceError: "" });
  const [onDelete, setOnDelete] = useState();

  useEffect(() => {
    setState(props.portionSizesList);
  }, []);

  // const getUpdatedPortionSize = (sizeId) => {
  // axios
  //   .get(
  //     `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_sizes/${sizeId}`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${isAuthenticated().providerToken}`,
  //       },
  //     }
  //   )
  //   .then((res) => {
  //     setEditPrice({ ...editName, name: "", id: "" });
  //     setEditPrice({ ...editPrice, price: "", id: "" });
  //     const changedIdx = state.findIndex((item) => item.id === res.data.id);
  //     const changedItem = res.data;
  //     const updatedState = state;
  //     updatedState[changedIdx] = changedItem;
  //     setState(updatedState);
  //     setUpdate(!update);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     toast.error(
  //       "Error! Please try again" + err.response && err.response.status
  //         ? `Error code: ${err.response.status}`
  //         : null
  //     );
  //     setEditPrice({ ...editName, name: "", id: "" });
  //     setEditPrice({ ...editPrice, price: "", id: "" });
  //   });
  // };

  // Drag and Drop Sorting
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#D4D4D4" : "white",
    boxShadow: isDragging ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : null,
    border: isDragging ? "1px solid #bababa" : null,

    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : null,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // const onDragEnd = (result) => {
  //   if (!result.destination) {
  //     return;
  //   }

  //   const items = reorder(state, result.source.index, result.destination.index);

  //   const addOnItemId = state[result.source.index].id;
  //   const sortedItem = `${addOnItemId}=${result.destination.index}`;

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_size_order`,
  //       sortedItem,
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           Authorization: `Bearer ${isAuthenticated().providerToken}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setState(items);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const showDelItemModal = (itemId) => {
    setShowDelItemAlert(true);
    setOnDelete(itemId);
  };

  const onCancel = () => {
    setShowDelItemAlert(false);
  };

  const onSaveItemName = (val) => {
    const groupData = {
      name: val,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_sizes/${editName.id}`,
        groupData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        // getUpdatedPortionSize(editName.id);
      })
      .catch((err) => {
        console.log(err);
        // getUpdatedPortionSize(editName.id);
        toast.error(
          "Error! Please try again" + err.response && err.response.status
            ? `Error code: ${err.response.status}`
            : null
        );
      });
  };

  const onSaveItemPrice = (val) => {
    const groupData = {
      price: val,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_sizes/${editPrice.id}`,
        groupData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        // getUpdatedPortionSize(editPrice.id);
      })
      .catch((err) => {
        console.log(err);
        setEditPrice({ ...editPrice, price: "", id: "" });
        toast.error("Error! Please try again");
      });
  };

  const onSaveItemDisPrice = (val) => {
    const groupData = {
      discountPrice: val,
      hasDiscount: val ? true : false,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_sizes/${editDisPrice.id}`,
        groupData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        // getUpdatedPortionSize(editPrice.id);
      })
      .catch((err) => {
        console.log(err);
        setEditDisPrice({ ...editDisPrice, disPrice: "", id: "" });
        toast.error("Error! Please try again");
      });
  };

  const handleItemAvailability = (sizeId, status) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_sizes/${sizeId}`,
        { available: status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        const objIndex = state.findIndex((obj) => obj.id == sizeId);
        const currentState = state;
        currentState[objIndex].available = status;
        setState(currentState);
        setUpdate(!update);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Error! Please try again" + err.response && err.response.status
            ? `Error code: ${err.response.status}`
            : null
        );
      });
  };

  const handleDeleteItem = (sizeId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_sizes/${sizeId}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        const removed = state.filter((item) => item.id !== sizeId);
        setState(removed);
        setShowDelItemAlert(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Error! Please try again" + err.response && err.response.status
            ? `Error code: ${err.response.status}`
            : null
        );
      });
  };

  const handleCancelAddItem = () => {
    setShowAddItemInput(false);
    setAddNew({ ...addNew, name: "", price: "", disPrice: "" });
    setError({ ...error, nameError: "", priceError: "" });
  };

  const addPortionSizeToMenuItem = () => {
    if (validate()) {
      setLoading(true);
      const newItem = {
        name: addNew.name,
        price: addNew.price,
        discountPrice: addNew.disPrice,
        hasDiscount: addNew.disPrice ? true : false,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${props.listingId}/menu_items/${props.itemId}/portion_sizes`,
          newItem,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          const data = {
            id: new Date().getTime(),
            name: addNew.name,
            calculatedPrice: addNew.price,
            calculatedDiscountPrice: addNew.disPrice,
            hasDiscount: addNew.disPrice ? true : false,
            available: true,
          };

          const currentState = state;

          currentState.push(data);
          setState(currentState);
          setUpdate(!update);
          setShowAddItemInput(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const validate = () => {
    let nameError = "";
    let priceError = "";

    if (addNew.name === "") {
      nameError = "Item name is required";
    }

    if (addNew.price === "") {
      priceError = "Price is required";
    }

    if (nameError || priceError) {
      setError({ ...error, nameError, priceError });
      return false;
    }
    return true;
  };

  // TODO: make utility and move into it
  const getCurrencySymbol = (currency) => {
      return currency?.symbol || currency?.code;
  };

  return (
    <div className="portion-size-display">
      <div className="menu-breadcrumb">
        <span>Manage Menu</span> {">"}{" "}
        <span
          className="go-back-menu-item"
          onClick={() => props.showAddOnsPage("menuPage")}
        >
          Menu
        </span>{" "}
        {">"}{" "}
        <span className="menu-breadcrumb-bold">{props.selectedItemTitle}</span>
        <span className="menu-breadcrumb-line"></span>
      </div>

      <div className="add-ons-title">
        <h6>Portion Sizes for {props.selectedItemTitle}</h6>
      </div>

      <div className="add-ons-details-wrapper">
        <div className="add-ons-table-header-title">
          <div className="addons-details-col pl-3">Portion Size Name</div>
          <div className="addons-price-col">
            Price ({getCurrencySymbol(props.currency)}) &nbsp; &nbsp; &nbsp;
          </div>
          <div className="addons-price-col">
            Discounted Price ({getCurrencySymbol(props.currency)}) &nbsp; &nbsp; &nbsp;
          </div>
          <div className="addons-availability-col">Availability</div>
          <div className="remove-addon-header">&nbsp;&nbsp;&nbsp;</div>
        </div>

        <DragDropContext
        // onDragEnd={onDragEnd}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {state
                  .sort((a, b) => a.calculatedPrice - b.calculatedPrice)
                  .map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div>
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className="add-ons-table-body-inner"
                              key={item.id}
                            >
                              {/* <span className="draggable-icon-addon">
                                <i className="fas fa-bars"></i>
                              </span> */}
                              <div className="addons-details-col">
                                <EdiText
                                  type="text"
                                  value={item.name}
                                  onEditingStart={() =>
                                    setEditName({
                                      ...editName,
                                      name: item.name,
                                      id: item.id,
                                    })
                                  }
                                  saveButtonClassName="item-name-save-button"
                                  editButtonClassName="item-name-edit-button"
                                  cancelButtonClassName="item-name-cancel-button"
                                  onSave={onSaveItemName}
                                />
                              </div>
                              <div className="addons-price-col">
                                <EdiText
                                  type="number"
                                  value={
                                    item.calculatedPrice &&
                                    item.calculatedPrice.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  }
                                  onEditingStart={() =>
                                    setEditPrice({
                                      ...editPrice,
                                      price:
                                        item.calculatedPrice &&
                                        item.calculatedPrice,
                                      id: item.id,
                                    })
                                  }
                                  saveButtonClassName="item-name-save-button"
                                  editButtonClassName="item-name-edit-button"
                                  cancelButtonClassName="item-name-cancel-button"
                                  onSave={onSaveItemPrice}
                                />
                              </div>
                              <div className="addons-price-col">
                                <EdiText
                                  type="number"
                                  value={
                                    item.calculatedDiscountPrice
                                      ? item.calculatedDiscountPrice.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                          }
                                        )
                                      : "0.00"
                                  }
                                  onEditingStart={() =>
                                    setEditDisPrice({
                                      ...editDisPrice,
                                      disPrice:
                                        item.calculatedDiscountPrice &&
                                        item.calculatedDiscountPrice,
                                      id: item.id,
                                    })
                                  }
                                  saveButtonClassName="item-name-save-button"
                                  editButtonClassName="item-name-edit-button"
                                  cancelButtonClassName="item-name-cancel-button"
                                  onSave={onSaveItemDisPrice}
                                />
                              </div>
                              <div className="addons-availability-col">
                                <div className="custom-control custom-switch m-0">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={item.id}
                                    checked={item.available}
                                    onChange={() =>
                                      handleItemAvailability(
                                        item.id,
                                        !item.available
                                      )
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={item.id}
                                  ></label>
                                </div>
                              </div>
                              <div
                                className="remove-addon"
                                onClick={() => showDelItemModal(item.id)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </div>
                            </div>
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {showAddItemInput ? (
          <div className="add-ons-table-body-inner">
            <div className="add-addon-col">
              <div className="add-item-name-col">
                <input
                  type="text"
                  name="name"
                  className={classnames(
                    "form-control business-type-col add-category-input",
                    {
                      "is-invalid": error.nameError,
                    }
                  )}
                  placeholder="Name"
                  autoComplete="off"
                  value={addNew.name}
                  onChange={(e) =>
                    setAddNew({ ...addNew, name: e.target.value })
                  }
                />
                <div className="invalid-feedback">{error.nameError}</div>
              </div>
              <div className="add-price-col" style={{ marginRight: 10 }}>
                <input
                  type="number"
                  name="name"
                  className={classnames(
                    "form-control business-type-col add-price-input",
                    {
                      "is-invalid": error.priceError,
                    }
                  )}
                  placeholder="Price"
                  autoComplete="off"
                  value={addNew.price}
                  onChange={(e) =>
                    setAddNew({ ...addNew, price: e.target.value })
                  }
                />
                <div className="invalid-feedback">{error.priceError}</div>
              </div>
              <div className="add-price-col">
                <input
                  type="number"
                  name="name"
                  className="form-control business-type-col add-price-input"
                  placeholder="Discounted Price"
                  autoComplete="off"
                  value={addNew.disPrice}
                  onChange={(e) =>
                    setAddNew({ ...addNew, disPrice: e.target.value })
                  }
                />
                {/* <div className="invalid-feedback">{error.priceError}</div> */}
              </div>
              <div className="add-Item-input-wrapper">
                <button
                  type="button"
                  className="add-cat-btn"
                  onClick={addPortionSizeToMenuItem}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </button>
                <button
                  type="button"
                  className="cancel-cat-btn"
                  onClick={handleCancelAddItem}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        ) : null}

        <div className="add-addon" onClick={() => setShowAddItemInput(true)}>
          + Add
        </div>
      </div>

      {showDelItemAlert ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, Delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => handleDeleteItem(onDelete)}
          onCancel={onCancel}
          focusCancelBtn
          btnSize="sm"
          cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
        >
          You will not be able to recover this item!
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default PortionSize;
