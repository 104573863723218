import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import Chips, { Chip } from "react-chips";

import { Lines } from "react-preloaders";

import Breadcrumb from "../../Breadcrumb";
import SecTitle from "../SecTitle";
import CompleteInfo from "../CompleteInfo";

import close from "../../../images/close-modal.png";
import localCard from "../../../images/local-card.png";
import cash from "../../../images/cash.png";
import checkedPay from "../../../images/checked-pay.png";
import uncheckedPay from "../../../images/unchecked-pay.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail, deleted } from "../../../methods/Alert";

export default class Delivery extends Component {
  state = {
    listingId: "",
    currency: "",
    isEnable: false,
    chips: [],
    freeDis: 0,
    baseDis: 0,
    ratePerKM: "0.00",
    maxDis: 0,
    baseRate: "0.00",
    minDelOrderVal: "0.00",

    // errors
    chipsError: "",
    freeDisError: "",
    baseDisError: "",
    ratePerKMError: "",
    maxDisError: "",
    baseRateError: "",
    minDelOrderValError: "",

    // add cost components
    typeOfCharge: "Percentage",
    value: 0,
    card: false,
    cash: false,
    desc: "",
    extraCosts: [],
    showPerMark: true,
    delAddId: "",

    // show - hide
    hasPlan: false,
    status: "",
    isComOrder: true,

    // errors
    valueError: "",
    payMethodError: "",
    descError: "",

    // loading
    loading: false,
    loadingCancel: false,
    loadingCostCom: false,

    // redirects
    redirectToSelectSubPlan: false,

    // pre loading
    preLoading: true,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          currency: res.data[0].region.country.currencySymbol,
          isEnable: res.data[0].deliveryDetail.deliverySettings.enabled,
          chips: res.data[0].deliveryDetail.deliverySettings.locations
            ? res.data[0].deliveryDetail.deliverySettings.locations
            : [],
          freeDis: res.data[0].deliveryDetail.pricing
            ? res.data[0].deliveryDetail.pricing.freeDistance / 1000
            : 0,
          baseDis: res.data[0].deliveryDetail.pricing
            ? res.data[0].deliveryDetail.pricing.baseDistance / 1000
            : 0,
          ratePerKM: res.data[0].deliveryDetail.pricing
            ? res.data[0].deliveryDetail.pricing.rate
            : "0.00",
          maxDis: res.data[0].deliveryDetail.pricing
            ? res.data[0].deliveryDetail.pricing.maxDistance / 1000
            : 0,
          baseRate: res.data[0].deliveryDetail.pricing
            ? res.data[0].deliveryDetail.pricing.basePrice
            : "0.00",
          minDelOrderVal:
            res.data[0].deliveryDetail.deliverySettings.minimumOrder,
          extraCosts: res.data[0].deliveryDetail.deliverySettings.extraCosts,
          hasPlan: res.data[0].subscription ? true : false,
          status: res.data[0].status,
          loadingCancel: false,
          preLoading: false,
        });

        // order settings
        if (
          res.data[0].deliveryDetail.deliverySettings.enabled === true ||
          res.data[0].deliveryDetail.dineInSettings.enabled === true ||
          res.data[0].deliveryDetail.pickupSettings.enabled === true
        ) {
          this.setState({ isComOrder: true });
        } else {
          this.setState({ isComOrder: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false, preLoading: false });
      });
  };

  // get input values
  handleChange = (e) => {
    // if typeOfCharge
    if (e.target.name === "typeOfCharge") {
      if (e.target.value === "Percentage") {
        this.setState({ showPerMark: true });
      } else {
        this.setState({ showPerMark: false });
      }
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  onChange = (chips) => {
    this.setState({ chips });
  };

  handleAvailability = (e) => {
    this.setState({ isEnable: e.target.checked });
  };

  // validate
  validateCostCom = () => {
    let valueError = "";
    let payMethodError = "";
    let descError = "";

    if (this.state.typeOfCharge === "Percentage" && this.state.value === "") {
      valueError = "Value is required";
    } else if (
      this.state.typeOfCharge !== "Percentage" &&
      this.state.value === ""
    ) {
      valueError = "Value is required";
    } else if (
      this.state.typeOfCharge !== "Percentage" &&
      this.state.value <= 0
    ) {
      valueError = "Invalid value";
    }

    if (this.state.card === false && this.state.cash === false) {
      payMethodError = "At least one is required";
    }

    if (this.state.desc === "") {
      descError = "Description of charge is required";
    }

    if (valueError || payMethodError || descError) {
      this.setState({
        valueError,
        payMethodError,
        descError,
      });
      return false;
    }

    return true;
  };

  AddCostCom = () => {
    if (this.validateCostCom()) {
      this.setState({
        loadingCostCom: true,
        valueError: "",
        payMethodError: "",
        descError: "",
      });

      if (this.state.typeOfCharge === "Percentage") {
        var obj = {
          description: this.state.desc,
          isPercentage: this.state.typeOfCharge === "Percentage" ? true : false,
          percentage:
            this.state.typeOfCharge === "Percentage"
              ? parseFloat(this.state.value)
              : 0,
          validForCash: this.state.cash,
          validForCard: this.state.card,
          type: "delivery",
        };
      } else {
        var obj = {
          description: this.state.desc,
          isPercentage: this.state.typeOfCharge === "Percentage" ? true : false,
          value:
            this.state.typeOfCharge === "Value"
              ? parseFloat(this.state.value)
              : 0,

          validForCash: this.state.cash,
          validForCard: this.state.card,
          type: "delivery",
        };
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/cost_components`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            loadingCostCom: false,
            typeOfCharge: "Percentage",
            value: 0,
            card: false,
            cash: false,
            desc: "",
            showPerMark: true,
          });

          success();

          this.getListing();

          document.getElementById("add-cost-com-delivery").click();
        })
        .catch((err) => {
          this.setState({ loadingCostCom: false });
          fail();
        });
    }
  };

  openDelModal = (id) => {
    this.setState({ delAddId: id });

    document.getElementById("delete-hidden-btn").click();
  };

  delCostCom = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/cost_components/${this.state.delAddId}`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        document.getElementById("close-del-modal").click();

        this.setState({ loading: false });

        deleted();
        this.getListing();
      })
      .catch((err) => {
        fail();
      });
  };

  cancelDelete = () => {
    this.setState({ delAddId: "" });
    document.getElementById("close-del-modal").click();
  };

  CancelAddingCom = () => {
    this.setState(
      {
        // add cost components
        typeOfCharge: "Percentage",
        value: 0,
        card: false,
        cash: false,
        desc: "",
        showPerMark: true,

        // errors
        valueError: "",
        payMethodError: "",
        descError: "",
      },
      () => {
        document.getElementById("add-cost-com-delivery").click();
      }
    );
  };

  // validate
  validate = () => {
    let chipsError = "";
    let freeDisError = "";
    let baseDisError = "";
    let ratePerKMError = "";
    let maxDisError = "";
    let baseRateError = "";
    let minDelOrderValError = "";

    if (this.state.chips.length < 1) {
      chipsError = "Delivery areas are required";
    }

    if (this.state.freeDis === "") {
      freeDisError = "Free distance is required";
    }

    if (this.state.baseDis === "") {
      baseDisError = "Base distance is required";
    }
    if (this.state.ratePerKM === "") {
      ratePerKMError = "Rate per Kilometre is required";
    }

    if (this.state.maxDis === "") {
      maxDisError = "Maximum distance is required";
    } else if (this.state.maxDis < 1) {
      maxDisError = "Maximum distance couldn't be zero";
    }

    if (this.state.baseRate === "") {
      baseRateError = "Base rate is required";
    }

    if (this.state.minDelOrderVal === "") {
      minDelOrderValError = "Minimum delivery order value is required";
    }

    if (
      chipsError ||
      freeDisError ||
      baseDisError ||
      ratePerKMError ||
      maxDisError ||
      baseRateError ||
      minDelOrderValError
    ) {
      this.setState({
        chipsError,
        freeDisError,
        baseDisError,
        ratePerKMError,
        maxDisError,
        baseRateError,
        minDelOrderValError,
      });
      return false;
    }

    return true;
  };

  handleSave = () => {
    if (this.validate()) {
      this.setState({
        chipsError: "",
        freeDisError: "",
        baseDisError: "",
        ratePerKMError: "",
        maxDisError: "",
        baseRateError: "",
        minDelOrderValError: "",
        loading: true,
      });

      const pricingData = {
        freeDistance: this.state.freeDis * 1000,
        basePrice: parseFloat(this.state.baseRate),
        rate: parseFloat(this.state.ratePerKM),
        maxDistance: this.state.maxDis * 1000,
        baseDistance: this.state.baseDis * 1000,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_pricing`,
          pricingData,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });

          success();
        })
        .catch((err) => {
          this.setState({ loading: false });
          fail();
        });

      const deliveryData = {
        enabled: this.state.isEnable,
        minimumOrder: parseFloat(this.state.minDelOrderVal),
        locations: this.state.chips,
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_settings`,
          deliveryData,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });

          window.location.reload();
        })
        .catch((err) => {
          this.setState({ loading: false });
          fail(
            err.response && err.response.status
              ? `Error code: ${err.response.status}`
              : null
          );
        });
    }
  };

  handleCancel = () => {
    this.setState({
      loadingCancel: true,
      chipsError: "",
      freeDisError: "",
      baseDisError: "",
      ratePerKMError: "",
      maxDisError: "",
      baseRateError: "",
      minDelOrderValError: "",
    });
    this.getListing();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#fff"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            <div className="settings-container">
              {this.state.redirectToSelectSubPlan && (
                <Redirect to="/settings/payment-info/select-subscription-plan" />
              )}

              <Breadcrumb mainPage="Settings" subPage="Order Info" />

              {/* {this.state.hasPlan ? null : (
                <div className="row mx-0 mt-0 select-plan-div">
                  <div className="col-8 p-0">
                    <p className="sec-title">Select a Plan</p>
                    <p className="sec-title-desc">
                      You need to select a subscription plan to publish your
                      listing and start accepting orders.
                    </p>
                  </div>
                  <div className="col-4 p-0">
                    <button
                      type="button"
                      className="publish-btn"
                      onClick={() =>
                        this.setState({ redirectToSelectSubPlan: true })
                      }
                    >
                      Select Plan
                    </button>
                  </div>
                </div>
              )} */}

              <div className="sec">
                <SecTitle
                  title="Delivery Settings"
                  titleDesc="Set delivery information"
                />
                <div className="row m-0 content-container pb-0">
                  <div className="col p-0">
                    <div className="enable-toggle">
                      <lable className="label">Delivery &nbsp; </lable>
                      <div className="custom-control custom-switch m-0">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="del-enable"
                          onChange={this.handleAvailability}
                          checked={this.state.isEnable}
                          disabled={!this.state.hasPlan ? true : false}
                        />
                        <label
                          className="custom-control-label toggle-btn"
                          for="del-enable"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 content-container pb-0">
                  <div className="col p-0">
                    <form className="setting-form">
                      <div className="form-row">
                        <div
                          className={classnames(
                            "form-group mb-0 p-0  w-100 chips-div",
                            {
                              "invalid-areas": this.state.chipsError,
                            }
                          )}
                        >
                          <label className="title ml-0 mb-0">
                            Delivery Areas
                          </label>
                          <p className="inform">
                            This is for customers information only. Delivery
                            areas are not enforced by the platform.
                          </p>
                          <Chips
                            value={this.state.chips}
                            onChange={this.onChange}
                            className={classnames("", {
                              "invalid-areas": this.state.chipsError,
                            })}
                            suggestions={["Your", "Data", "Here"]}
                          />
                          <p
                            className="invalid-feedback"
                            style={{ marginBottom: 0 }}
                          >
                            {this.state.chipsError}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row m-0 content-container">
                  <div className="col-md col1 p-0">
                    <form className="setting-form order-forms d-inline-block w-100">
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label htmlFor="freeDis" className="title ml-0">
                            Free Distance
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text input-prepends">
                                KM
                              </div>
                            </div>
                            <input
                              type="number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.freeDisError,
                              })}
                              id="freeDis"
                              name="freeDis"
                              value={this.state.freeDis}
                              onChange={this.handleChange}
                              autoComplete="off"
                              disabled={!this.state.hasPlan ? true : false}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {this.state.freeDisError}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label htmlFor="baseDis" className="title ml-0">
                            Base Distance
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text input-prepends">
                                KM
                              </div>
                            </div>
                            <input
                              type="number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.baseDisError,
                              })}
                              id="baseDis"
                              name="baseDis"
                              value={this.state.baseDis}
                              onChange={this.handleChange}
                              autoComplete="off"
                              disabled={!this.state.hasPlan ? true : false}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {this.state.baseDisError}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label htmlFor="ratePerKM" className="title ml-0">
                            Rate Per Kilometre
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text input-prepends">
                                {this.state.currency}
                              </div>
                            </div>
                            <input
                              type="number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.ratePerKMError,
                              })}
                              id="ratePerKM"
                              name="ratePerKM"
                              value={this.state.ratePerKM}
                              onChange={this.handleChange}
                              autoComplete="off"
                              disabled={!this.state.hasPlan ? true : false}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {this.state.ratePerKMError}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="col-md col2 p-0">
                    <form className="setting-form d-inline-block order-forms w-100">
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label htmlFor="maxDis" className="title ml-0">
                            Maximum Distance
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text input-prepends">
                                KM
                              </div>
                            </div>
                            <input
                              type="number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.maxDisError,
                              })}
                              id="maxDis"
                              name="maxDis"
                              value={this.state.maxDis}
                              onChange={this.handleChange}
                              autoComplete="off"
                              disabled={!this.state.hasPlan ? true : false}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {this.state.maxDisError}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label htmlFor="baseRate" className="title ml-0">
                            Base Rate
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text input-prepends">
                                {this.state.currency}
                              </div>
                            </div>
                            <input
                              type="number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.baseRateError,
                              })}
                              id="baseRate"
                              name="baseRate"
                              value={this.state.baseRate}
                              onChange={this.handleChange}
                              autoComplete="off"
                              disabled={!this.state.hasPlan ? true : false}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {this.state.baseRateError}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row m-0 content-container">
                  <div className="col-md col1 p-0">
                    <form className="setting-form order-forms d-inline-block w-100">
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label
                            htmlFor="minDelOrderVal"
                            className="title ml-0"
                          >
                            Minimum Delivery Order Value
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text input-prepends">
                                {this.state.currency}
                              </div>
                            </div>
                            <input
                              type="number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.minDelOrderValError,
                              })}
                              id="minDelOrderVal"
                              name="minDelOrderVal"
                              value={this.state.minDelOrderVal}
                              onChange={this.handleChange}
                              autoComplete="off"
                              disabled={!this.state.hasPlan ? true : false}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {this.state.minDelOrderValError}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="col-md col2 p-0"></div>
                </div>

                <div className="row m-0 content-container">
                  <div className="col-md col1 p-0">
                    <form className="setting-form order-forms d-inline-block w-100">
                      <div className="form-row mb-3">
                        <div className="form-group mb-0 p-0 input-field">
                          <label className="title ml-0 mb-0">Extra Costs</label>
                          <span
                            className="change-settings-btn"
                            data-toggle="modal"
                            data-target={
                              !this.state.hasPlan
                                ? ""
                                : "#add-cost-com-delivery"
                            }
                          >
                            Add Cost Component
                          </span>
                          <p className="inform">
                            Extra cost components that are added on top of the
                            total order cost. Eg: Service charges or Government
                            taxes.
                          </p>
                        </div>
                      </div>
                      {this.state.extraCosts.map((com, i) => (
                        <div className="form-row mt-0" key={i}>
                          <div className="form-group extra-cost-col1 pl-0">
                            <label className="title ml-0 mb-0">
                              {com.isPercentage === true
                                ? `${com.percentage}%`
                                : `${this.state.currency} ${com.value.toFixed(
                                    2
                                  )}`}{" "}
                              - {com.description}
                            </label>
                          </div>
                          <div className="form-group extra-cost-col2 pl-0">
                            <span
                              className="com-del-btn"
                              onClick={() => this.openDelModal(com.id)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      ))}
                    </form>
                  </div>

                  <div className="col-md col2 p-0"></div>
                </div>

                <div className="row m-0 content-container">
                  <div className="col-md p-0">
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={this.handleSave}
                      disabled={!this.state.hasPlan ? true : false}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                          style={{ margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Save"}
                    </button>
                    <button
                      type="button"
                      className="btn setting-cancel-btn float-right"
                      onClick={this.handleCancel}
                      disabled={!this.state.hasPlan ? true : false}
                    >
                      {this.state.loadingCancel ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#4f4f4f", margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loadingCancel ? "" : "Cancel"}
                    </button>
                  </div>
                </div>

                <div
                  className="modal fade add-cost-com"
                  id="add-cost-com-delivery"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="add-cost-com"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id="add-cost-com"
                          style={{ fontSize: 22, fontWeight: "normal" }}
                        >
                          Add Cost Component
                        </h5>

                        <img
                          src={close}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="form-row">
                            <div className="form-group col input-field">
                              <label
                                htmlFor="typeOfCharge"
                                className="title ml-0"
                              >
                                Type of Charge
                              </label>
                              <select
                                id="typeOfCharge"
                                className="form-control"
                                name="typeOfCharge"
                                value={this.state.typeOfCharge}
                                onChange={this.handleChange}
                              >
                                <option>Percentage</option>
                                <option>Value</option>
                              </select>
                            </div>
                            <div className="form-group col">
                              <label htmlFor="value" className="title ml-0">
                                Value
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  className={classnames("form-control", {
                                    "is-invalid": this.state.valueError,
                                  })}
                                  id="value"
                                  name="value"
                                  value={this.state.value}
                                  onChange={this.handleChange}
                                />
                                {this.state.showPerMark ? (
                                  <div className="input-group-append">
                                    <div className="input-group-text input-prepends">
                                      %
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className="invalid-feedback">
                                {this.state.valueError}
                              </div>
                            </div>
                          </div>
                          <div className="form-row" style={{ paddingLeft: 5 }}>
                            <div className="form-group mb-0 p-0 input-field">
                              <label className="title ml-0 mb-2">
                                Applicable for
                              </label>
                              <p className="payment-methods">
                                {this.state.card ? (
                                  <img
                                    src={checkedPay}
                                    alt="credit cards"
                                    onClick={() =>
                                      this.setState({ card: !this.state.card })
                                    }
                                  />
                                ) : (
                                  <img
                                    src={uncheckedPay}
                                    alt="card credit cards"
                                    onClick={() =>
                                      this.setState({ card: !this.state.card })
                                    }
                                  />
                                )}
                                <img src={localCard} alt="card credit cards" />
                                Valid for Card
                              </p>

                              <p className="payment-methods">
                                {this.state.cash ? (
                                  <img
                                    src={checkedPay}
                                    alt="cash"
                                    onClick={() =>
                                      this.setState({ cash: !this.state.cash })
                                    }
                                  />
                                ) : (
                                  <img
                                    src={uncheckedPay}
                                    alt="cash"
                                    onClick={() =>
                                      this.setState({ cash: !this.state.cash })
                                    }
                                  />
                                )}
                                <img src={cash} alt="local credit cards" />
                                Valid for Cash
                              </p>
                              <p
                                className="invalid-feedback"
                                style={{ margin: "-5px 0 0 0" }}
                              >
                                {this.state.payMethodError}
                              </p>
                            </div>
                          </div>
                          <div className="form-row" style={{ marginTop: 12 }}>
                            <div className="form-group col w-100">
                              <label htmlFor="desc" className="title ml-0">
                                Description
                              </label>
                              <textarea
                                className={classnames("form-control", {
                                  "is-invalid": this.state.descError,
                                })}
                                id="desc"
                                rows="3"
                                name="desc"
                                value={this.state.desc}
                                onChange={this.handleChange}
                              ></textarea>
                              <div className="invalid-feedback">
                                {this.state.descError}
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="row mt-4 m-0 ">
                          <div className="col-md p-0">
                            <button
                              type="button"
                              id="location-setting"
                              className="btn setting-save-btn float-right"
                              onClick={this.AddCostCom}
                            >
                              {this.state.loadingCostCom ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                  style={{ margin: 0 }}
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : null}

                              {this.state.loadingCostCom ? "" : "Save"}
                            </button>
                            <button
                              type="button"
                              className="btn setting-cancel-btn float-right"
                              onClick={this.CancelAddingCom}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#delete-modal"
                  id="delete-hidden-btn"
                  hidden
                ></button>

                <div
                  className="modal fade add-cost-com"
                  id="delete-modal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="delete-modal"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id="delete-modal"
                          style={{ fontSize: 22, fontWeight: "normal" }}
                        >
                          Are you sure?
                        </h5>

                        <img
                          src={close}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          id="close-del-modal"
                          onClick={this.cancelDelete}
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row m-0 ">
                          <div className="col-md p-0">
                            <p>You won't be able to revert this!</p>
                          </div>
                        </div>
                        <div className="row mt-4 m-0 ">
                          <div className="col-md p-0">
                            <button
                              type="button"
                              id="location-setting"
                              className="btn setting-save-btn float-right"
                              onClick={this.delCostCom}
                            >
                              {this.state.loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : null}

                              {this.state.loading ? "" : "Yes"}
                            </button>
                            <button
                              type="button"
                              className="btn setting-cancel-btn float-right"
                              onClick={this.cancelDelete}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.status === "verified" ? null : this.state
                .isComOrder ? null : this.state.hasPlan ? (
              <CompleteInfo
                title="Enable Ordering"
                desc="At least one ordering method needs to be enabled before publishing your website."
              />
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
