import React, { Component } from "react";
import axios from "axios";

import SecTitle from "../SecTitle";
import Chips, { Chip } from "react-chips";

import "../../../styles/seo.css";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class SEO extends Component {
  state = {
    listingId: "",
    metaDesc: "",
    chips: [],

    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        const keyStr = res.data[0].branding
          ? res.data[0].branding.metaKeywords
            ? res.data[0].branding.metaKeywords
            : ""
          : "";

        const arrKey = keyStr.split(",");

        this.setState({
          listingId: res.data[0].id,
          metaDesc: res.data[0].branding
            ? res.data[0].branding.metaDescription
              ? res.data[0].branding.metaDescription
              : ""
            : "",
          chips: res.data[0].branding
            ? res.data[0].branding.metaKeywords
              ? arrKey
              : []
            : [],
          loadingCancel: false,
        });
      })
      .catch((err) => console.log(err));
  };

  // get input values
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChange = (chips) => {
    this.setState({ chips });
  };

  handleSave = () => {
    const data = {
      metaDescription: this.state.metaDesc,
      metaKeywords: this.state.chips.toString(),
    };

    // set loading
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/branding`,
        data,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        success();
      })
      .catch((err) => {
        this.setState({ loading: false });
        fail();
      });
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    this.getListing();
  };

  render() {
    return (
      <div className="settings-container">
        <div className="sec">
          <SecTitle
            title="SEO (Search Engine Optimization)"
            titleDesc="This is description that will be visible when a user shares your page link its also the description seen by indexing services like Google's crawler bots"
          />
          <div className="row m-0 content-container">
            <div className="col p-0">
              <form className="setting-form">
                <div className="form-row">
                  <div className="form-group  mb-0 w-100">
                    <label htmlFor="metaDesc" className="title ml-0 mb-0">
                      Meta Description
                    </label>
                    <textarea
                      className="form-control"
                      id="metaDesc"
                      rows="5"
                      name="metaDesc"
                      value={this.state.metaDesc}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group mb-0 p-0  w-100 chips-div">
                    <label htmlFor="name" className="title ml-0">
                      Meta Keywords
                    </label>
                    <Chips
                      value={this.state.chips}
                      onChange={this.onChange}
                      suggestions={["Your", "Data", "Here"]}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <button
                type="button"
                id="location-setting"
                className="btn setting-save-btn float-right"
                onClick={this.handleSave}
              >
                {this.state.loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    style={{ margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loading ? "" : "Save"}
              </button>
              <button
                type="button"
                className="btn setting-cancel-btn float-right"
                onClick={this.handleCancel}
              >
                {this.state.loadingCancel ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "#4f4f4f", margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loadingCancel ? "" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
