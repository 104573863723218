import React, { Component } from "react";
import axios from "axios";
import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import Heading from "../components/dashboard/Heading";
import Orders from "../components/Orders";
import TitleOfPage from "../components/TitleOfPage";

import { isAuthenticated } from "../auth/isAuth";

export default class ManageOrders extends Component {
  state = {
    show: true,
  };

  componentDidMount = () => {
    if (JSON.parse(localStorage.getItem("listing")).deliveryDetail) {
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${
            JSON.parse(localStorage.getItem("listing")).id
          }/delivery_optin`,
          {},
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
            })
            .then((res) => {
              localStorage.setItem("listing", JSON.stringify(res.data[0]));
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    }
  };

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "";
      // document.getElementsByClassName("page-section-heading")[0].style.marginLeft = "20px";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "100%";
      // document.getElementsByClassName("page-section-heading")[0].style.marginLeft = "50px";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div className="dashboard-content-col pb-4">
              {/* <Heading heading="Manage Orders" /> */}
              {this.state.show ? null : (
                <i
                  class="fas fa-chevron-circle-right side-menu-show"
                  onClick={() => this.showMenu(true)}
                ></i>
              )}
              <TitleOfPage title="Manage Orders - Spritzer Dashboard" />

              <Orders />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
