import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../styles/dashboardSideMenu.css";

import google from "../../images/icon-google-play--black.png";
import apple from "../../images/icon-apple-store--black.png";

import { isAuthenticated } from "../../auth/isAuth";

const isActive = (history, path) => {
  if (history.location.pathname === path) {
    return { background: "#D4D4D4", color: "#1E88E5", fontWeight: "500" };
  }
};

class SideMenu extends Component {
  state = {
    extId: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).extId
      : "",
    listingId: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).id
      : "",
    favIcon: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).branding
        ? JSON.parse(localStorage.getItem("listing")).branding.imageFavicon
          ? JSON.parse(localStorage.getItem("listing")).branding.imageFavicon
              .url
          : ""
        : ""
      : "",
    restName: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).name
      : "",
    location: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).address.locality
      : "",
    isDeliveryOn: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).deliveryDetail &&
        JSON.parse(localStorage.getItem("listing")).deliveryDetail
          .isAvailable === true
        ? true
        : false
      : false,
    isDeliveryOff: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).deliveryDetail &&
        JSON.parse(localStorage.getItem("listing")).deliveryDetail
          .isAvailable === true
        ? false
        : true
      : true,
    status: JSON.parse(localStorage.getItem("listing")).status,
    // alert
    updatePhoneAlertMsg: false,
    errorCode: "",
  };

  componentDidMount = () => {
    // get listing id
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          extId: res.data[0].extId,
          listingId: res.data[0].id,
          restName: res.data[0].name,
          location: res.data[0].address.locality,
          favIcon: res.data[0].branding
            ? res.data[0].branding.imageFavicon
              ? res.data[0].branding.imageFavicon.url
              : ""
            : "",
          isDeliveryOn:
            res.data[0].deliveryDetail.isAvailable === true ? true : false,
          isDeliveryOff:
            res.data[0].deliveryDetail.isAvailable === true ? false : true,
          status: res.data[0].status,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ redirectToLogin: true });
      });
  };

  handleDeliveryOn = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_availability`,
        qs.stringify({ availability: true }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ isDeliveryOn: true, isDeliveryOff: false });

        axios
          .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
            headers: {
              Authorization: `Bearer ${
                isAuthenticated() && isAuthenticated().providerToken
              }`,
            },
          })
          .then((res) => {
            localStorage.setItem("listing", JSON.stringify(res.data[0]));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          updatePhoneAlertMsg: true,
          errorCode:
            err.response && err.response.status ? err.response.status : "",
        });
      });
  };

  handleDeliveryOff = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_availability`,
        qs.stringify({ availability: false }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ isDeliveryOff: true, isDeliveryOn: false });
        axios
          .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
            headers: {
              Authorization: `Bearer ${
                isAuthenticated() && isAuthenticated().providerToken
              }`,
            },
          })
          .then((res) => {
            localStorage.setItem("listing", JSON.stringify(res.data[0]));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ updatePhoneAlertMsg: true });
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.updatePhoneAlertMsg ? (
          <SweetAlert
            danger
            title="Oops, Something went wrong"
            onConfirm={this.close}
            confirmBtnStyle={{ background: "#f26666", border: 0 }}
          >
            Please add a phone number to enable delivery.{" "}
            {this.state.errorCode
              ? "Error code: " + this.state.errorCode
              : null}
          </SweetAlert>
        ) : null}

        <div className="dashboard-side-menu-col">
          <div className="hide-side-menu">
            {this.props.show ? (
              <i
                className="fas fa-chevron-circle-left side-menu-hide"
                onClick={() => this.props.showMenu(false)}
              ></i>
            ) : null}
          </div>

          <div className="com-identy-div">
            <div className="brand-logo">
              {this.state.favIcon ? (
                <img
                  src={this.state.favIcon}
                  width="35"
                  style={{ marginTop: 2 }}
                  alt="favicon"
                />
              ) : null}
            </div>
            <div className="brand-name">
              <p className="shop-name">{this.state.restName}</p>
              <p className="location">
                {this.state.location === "undefined" ? "" : this.state.location}
              </p>
            </div>
          </div>

          <ul className="list-group">
            {this.state.status === "verified" ? null : (
              <Link to="/get-started">
                <li
                  className="list-group-item orders-border-top"
                  style={isActive(this.props.history, "/get-started")}
                >
                  <i className="fas fa-store"></i> &nbsp; Get Started
                </li>
              </Link>
            )}

            <Link to="/manage-orders">
              <li
                className="list-group-item orders-border-top"
                style={isActive(this.props.history, "/manage-orders")}
              >
                <i className="fas fa-clock"></i> &nbsp; Manage Orders
              </li>
            </Link>

            <div className="accordion" id="accordionMenu">
              <div className="card">
                <div
                  className="card-header pr-2 collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseMenu"
                  aria-expanded="true"
                  aria-controls="collapseMenu"
                  id="headingMenu"
                >
                  <i className="fas fa-book-open"></i> &nbsp; Manage Menus{" "}
                  <i className="fas fa-angle-right"></i>
                </div>

                <div
                  id="collapseMenu"
                  className={`${
                    this.props.history.location.pathname === "/manage-menu" ||
                    this.props.history.location.pathname === "/manage-addons"
                      ? "collapse show"
                      : "collapse"
                  }`}
                  aria-labelledby="headingMenu"
                  data-parent="#accordionMenu"
                >
                  <div className="card-body p-0">
                    <ul className="list-group">
                      <Link to="/manage-menu">
                        <li
                          className="list-group-item"
                          style={isActive(this.props.history, "/manage-menu")}
                        >
                          <i className="fas fa-th-list"></i> &nbsp; Menu
                        </li>
                      </Link>

                      <Link to="/manage-addons">
                        <li
                          className="list-group-item"
                          style={isActive(this.props.history, "/manage-addons")}
                        >
                          <i className="fas fa-stream"></i> &nbsp; AddOns
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* <Link to="/billing">
              <li
                className="list-group-item"
                style={isActive(this.props.history, "/billing")}
              >
                <i className="fas fa-dollar-sign"></i> &nbsp; Billing
              </li>
            </Link> */}
          </ul>
          <div className="accordion" id="accordionSetting">
            <div className="card">
              <div
                className="card-header pr-2 collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                id="headingOne"
              >
                <i className="fas fa-cog"></i> &nbsp; Settings
                <i className="fas fa-angle-right"></i>
              </div>

              <div
                id="collapseOne"
                className={`${
                  this.props.history.location.pathname ===
                    "/settings/restaurant-info" ||
                  this.props.history.location.pathname ===
                    "/settings/website-info" ||
                  this.props.history.location.pathname ===
                    "/settings/payment-info" ||
                  this.props.history.location.pathname ===
                    "/settings/order-info" ||
                  this.props.history.location.pathname ===
                    "/settings/change-password"
                    ? "collapse show"
                    : "collapse"
                }`}
                aria-labelledby="headingOne"
                data-parent="#accordionSetting"
              >
                <div className="card-body p-0">
                  <ul className="list-group">
                    <Link to="/settings/restaurant-info">
                      <li
                        className="list-group-item"
                        style={isActive(
                          this.props.history,
                          "/settings/restaurant-info"
                        )}
                      >
                        <i className="fas fa-store"></i> &nbsp; Restaurant Info
                      </li>
                    </Link>
                    <Link to="/settings/website-info">
                      <li
                        className="list-group-item"
                        style={isActive(
                          this.props.history,
                          "/settings/website-info"
                        )}
                      >
                        <i className="fas fa-copyright"></i> &nbsp; Website
                        Branding
                      </li>
                    </Link>
                    <Link to="/settings/payment-info">
                      <li
                        className="list-group-item"
                        style={isActive(
                          this.props.history,
                          "/settings/payment-info"
                        )}
                      >
                        <i className="fas fa-credit-card"></i> &nbsp; Payment
                        Info
                      </li>
                    </Link>
                    <Link to="/settings/order-info">
                      <li
                        className="list-group-item"
                        style={isActive(
                          this.props.history,
                          "/settings/order-info"
                        )}
                      >
                        <i className="fas fa-utensils"></i> &nbsp; Order Info
                      </li>
                    </Link>

                    <Link to="/settings/change-password">
                      <li
                        className="list-group-item"
                        style={isActive(
                          this.props.history,
                          "/settings/change-password"
                        )}
                      >
                        <i className="fas fa-key"></i> &nbsp; Change Password
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion" id="accordionMenu">
            <div className="card">
              <div
                className="card-header pr-2 collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapsePromo"
                aria-expanded="true"
                aria-controls="collapsePromo"
                id="headingMenu"
              >
                <i className="fas fa-ad"></i> &nbsp; Promotions{" "}
                <i className="fas fa-angle-right"></i>
              </div>

              <div
                id="collapsePromo"
                className={`${
                  this.props.history.location.pathname ===
                  "/promotions/discounts"
                    ? "collapse show"
                    : "collapse"
                }`}
                aria-labelledby="headingMenu"
                data-parent="#accordionMenu"
              >
                <div className="card-body p-0">
                  <ul className="list-group">
                    <Link to="/promotions/discounts">
                      <li
                        className="list-group-item"
                        style={isActive(
                          this.props.history,
                          "/promotions/discounts"
                        )}
                      >
                        <i className="fas fa-percent"></i> &nbsp; Discounts
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Link to="/integrations">
            <li
              className="list-group-item orders-border-top"
              style={isActive(this.props.history, "/integrations")}
            >
              <i className="fas fa-plug"></i> &nbsp; Integrations
            </li>
          </Link>

          <ul className="list-group">
            <li className="orders-border-top px-3 pt-4 res-availability-buttons">
              <button
                onClick={this.handleDeliveryOff}
                className={
                  this.state.isDeliveryOff ? "delivery-off" : "delivery-disable"
                }
              >
                Offline
              </button>
              <button
                onClick={this.handleDeliveryOn}
                className={
                  this.state.isDeliveryOn ? "delivery-on" : "delivery-disable"
                }
              >
                Online
              </button>
            </li>
          </ul>

          <div className="row mx-0 dashboard-app-div">
            <div className="col-md p-0">
              <p className="text-center px-3" style={{ fontSize: 12 }}>
                Download the Order Manager app <br /> on your mobile
              </p>
              <div className="store-links">
                <a
                  href="https://play.google.com/store/apps/details?id=com.spritzer.restaurantmanager"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={google} width="100px" alt="mobile app" />
                </a>
                <a
                  href="https://apps.apple.com/lk/app/spritzer-res-manager/id1590359581"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={apple} width="100px" alt="mobile app" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SideMenu);
