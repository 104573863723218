import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Lines } from "react-preloaders";

import SelectPlan from "./SelectPlan";

import Bus from "../../images/get-started/bus-info.png";
import Branding from "../../images/get-started/branding.png";
import Order from "../../images/get-started/order.png";
import Menu from "../../images/get-started/menu.png";
import Payment from "../../images/get-started/payment.png";

import "../../styles/getStarted.css";

import { isAuthenticated } from "../../auth/isAuth";

export default class ToDo extends Component {
  state = {
    isComRest: false,
    isComBranding: false,
    isComOrder: false,
    isComMenu: false,
    isComPay: false,

    // pre loading
    preLoading: true,
  };

  componentDidMount = () => {
    // menu settings
    // get listing id
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        // restaurant info
        if (
          res.data[0].address.latitude !== 0 &&
          res.data[0].address.longitude !== 0 &&
          res.data[0].deliveryDetail.orderAcceptingHours &&
          res.data[0].deliveryDetail.orderAcceptingHours.length > 0
        ) {
          this.setState({ isComRest: true });
        } else {
          this.setState({ isComRest: false });
        }

        // branding
        if (res.data[0].branding && res.data[0].branding.imageCover) {
          this.setState({ isComBranding: true });
        } else {
          this.setState({ isComBranding: false });
        }

        // order settings
        if (
          res.data[0].deliveryDetail.deliverySettings.enabled === true ||
          res.data[0].deliveryDetail.dineInSettings.enabled === true ||
          res.data[0].deliveryDetail.pickupSettings.enabled === true
        ) {
          this.setState({ isComOrder: true });
        } else {
          this.setState({ isComOrder: false });
        }

        // payment settings
        if (res.data[0].subscription) {
          this.setState({ isComPay: true });
        } else {
          this.setState({ isComPay: false });
        }

        //  get menus
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/menu_items`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
            }
          )
          .then((res) => {
            if (res.data.length > 0) {
              this.setState({ isComMenu: true, preLoading: false });
            } else {
              this.setState({ isComMenu: false, preLoading: false });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ preLoading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ preLoading: false });
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#fff"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            <div className="settings-container to-do-container">
              <div className="sec" style={{ boxShadow: "unset" }}>
                <div
                  className="row m-0 content-container p-0"
                  style={{ background: "unset" }}
                >
                  <div className="col p-0">
                    <p className="to-do-heading">What to do next</p>
                    <p className="to-do-desc">
                      Update the follwing information about your restaurant to
                      make it available for your customers.
                    </p>
                    <div className="to-do-div to-do1">
                      <img src={Bus} alt="Business Info" />
                      <p className="title">Restaurant Info</p>
                      <p className="small-desc">
                        Update basic information about your listing
                      </p>
                      {this.state.isComRest ? (
                        <p className="completed-to-do">
                          Completed <i className="fas fa-check-circle ml-1"></i>
                        </p>
                      ) : (
                        <Link to="/settings/restaurant-info">
                          Update Restaurant
                        </Link>
                      )}
                    </div>
                    <div className="to-do-div to-do2">
                      <img src={Branding} alt="Business Info" />
                      <p className="title">Restaurant Branding</p>
                      <p className="small-desc">
                        Set the colour themes and add your social media links.
                      </p>
                      {this.state.isComBranding ? (
                        <p className="completed-to-do">
                          Completed <i className="fas fa-check-circle ml-1"></i>
                        </p>
                      ) : (
                        <Link to="/settings/website-info">Update Branding</Link>
                      )}
                    </div>
                    <div className="to-do-div to-do3">
                      <img src={Order} alt="Business Info" />
                      <p className="title">Order Settings</p>
                      <p className="small-desc">
                        Set ordering methods, delivery prcing and online hours.
                      </p>
                      {this.state.isComOrder ? (
                        <p className="completed-to-do">
                          Completed <i className="fas fa-check-circle ml-1"></i>
                        </p>
                      ) : (
                        <Link to="/settings/order-info">Ordering Settings</Link>
                      )}
                    </div>
                    <div className="to-do-div to-do4">
                      <img src={Menu} alt="Business Info" />
                      <p className="title">Menu</p>
                      <p className="small-desc">
                        Add items and images to your menu.
                      </p>
                      {this.state.isComMenu ? (
                        <p className="completed-to-do">
                          Completed <i className="fas fa-check-circle ml-1"></i>
                        </p>
                      ) : (
                        <Link to="/manage-menu">Add Menus</Link>
                      )}
                    </div>
                    <div className="to-do-div to-do5">
                      <img src={Payment} alt="Business Info" />
                      <p className="title">Payment Settings</p>
                      <p className="small-desc">
                        Setup your payout details and payment accepting methods.
                      </p>
                      {this.state.isComPay ? (
                        <p className="completed-to-do">
                          Completed <i className="fas fa-check-circle ml-1"></i>
                        </p>
                      ) : (
                        <Link to="/settings/payment-info">
                          Payment Settings
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SelectPlan
              isPublishDisable={
                this.state.isComRest &&
                this.state.isComBranding &&
                this.state.isComOrder &&
                this.state.isComMenu &&
                this.state.isComPay
              }
              canPublish={this.state.isComPay}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
