import axios from "axios";
import { isAuthenticated } from "../../auth/isAuth";

export const saveIntegrationAPI = (
  listingId,
  // apiKey,
  integrationType,
  meta,
  onSuccess,
  onError,
) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v2/listings/${listingId}/integrations`,
      {
        listingId,
        integrationType,
        meta,
      },
      {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
