import React, { Component, createRef } from "react";
import axios from "axios";

import SecTitle from "../SecTitle";

import close from "../../../images/close-modal.png";
import cover from "../../../images/cover-img.png";
import del from "../../../images/delete.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail, deleted } from "../../../methods/Alert";

export default class LogoFavCover extends Component {
  cropper = createRef();

  state = {
    // images
    logoFile: "",
    favFile: "",
    coverFile: "",

    logoImage: "",
    favImage: "",
    coverImage: "",
    logoImageId: "",
    favImageId: "",
    coverImageId: "",

    // delete
    delImageId: "",

    // errors
    errorText: "",

    // loading
    loadingLogo: false,
    loadingFav: false,
    loadingCover: false,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          logoFile: "",
          favFile: "",
          coverFile: "",
          logoImage: res.data[0].branding
            ? res.data[0].branding.imageLogo
              ? res.data[0].branding.imageLogo.url
              : ""
            : "",
          logoImageId: res.data[0].branding
            ? res.data[0].branding.imageLogo
              ? res.data[0].branding.imageLogo.id
              : ""
            : "",
          favImage: res.data[0].branding
            ? res.data[0].branding.imageFavicon
              ? res.data[0].branding.imageFavicon.url
              : ""
            : "",
          favImageId: res.data[0].branding
            ? res.data[0].branding.imageFavicon
              ? res.data[0].branding.imageFavicon.id
              : ""
            : "",
          coverImage: res.data[0].branding
            ? res.data[0].branding.imageCover
              ? res.data[0].branding.imageCover.url
              : ""
            : "",
          coverImageId: res.data[0].branding
            ? res.data[0].branding.imageCover
              ? res.data[0].branding.imageCover.id
              : ""
            : "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  uploadLogo = (e) => {
    this.setState({ loadingLogo: true });

    let bodyFormData = new FormData();
    bodyFormData.append("image", e.target.files[0]);
    bodyFormData.set("type", "logo");

    if (e.target.files[0]) {
      // logo upload
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/branding_images`,
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            deletedAlert: false,
            loadingLogo: false,
          });

          success();

          document.getElementById("logo-upload").value = "";

          this.getListing();
        })
        .catch((err) => {
          this.setState({ loadingLogo: false });
          if (err.response.data.code === 1072) {
            this.setState({ logoFile: "" });
            fail("Logo already exists");
          } else if (err.response.data.code === 1015) {
            this.setState({
              logoFile: "",
            });
            fail("Invalid image type");
          } else {
            fail();
          }
        });
    }
  };

  uploadFav = (e) => {
    this.setState({ loadingFav: true });

    let bodyFormData = new FormData();
    bodyFormData.append("image", e.target.files[0]);
    bodyFormData.set("type", "favicon");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/branding_images`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          deletedAlert: false,

          loadingFav: false,
        });

        success();

        document.getElementById("fav-upload").value = "";

        // get listing info
        axios
          .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            localStorage.setItem("listing", JSON.stringify(res.data[0]));
          })
          .catch((err) => {
            console.log(err);
          });

        this.getListing();

        window.location.reload();
      })
      .catch((err) => {
        this.setState({ loadingFav: false });
        if (err.response.data.code === 1072) {
          this.setState({ logoFile: "" });
          fail("Favicon already exists");
        } else if (err.response.data.code === 1015) {
          this.setState({
            logoFile: "",
          });
          fail("Invalid image type");
        } else {
          fail();
        }
      });
  };

  uploadCover = (e) => {
    this.setState({ loadingCover: true });

    let bodyFormData = new FormData();
    bodyFormData.append("image", e.target.files[0]);
    bodyFormData.set("type", "cover");

    // logo upload
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/branding_images`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          deletedAlert: false,

          loadingCover: false,
        });

        success();

        document.getElementById("cover-upload").value = "";

        this.getListing();

        window.location.reload();
      })
      .catch((err) => {
        this.setState({ loadingCover: false });

        if (err.response.data.code === 1072) {
          this.setState({
            logoFile: "",
          });

          fail("Cover image already exists");
        } else if (err.response.data.code === 1015) {
          this.setState({
            logoFile: "",
          });

          fail("Invalid image type");
        } else {
          fail();
        }
      });
  };

  openDelModal = (id) => {
    this.setState({ delImageId: id });

    document.getElementById("delete-hidden-btn").click();
  };

  delIamges = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/branding_images/${this.state.delImageId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        document.getElementById("close-del-modal").click();
        this.setState({ delImageId: "" });
        deleted();

        this.getListing();

        window.location.reload();
      })
      .catch((err) => {
        fail();
      });
  };

  cancelDelete = () => {
    this.setState({ delImageId: "" });
    document.getElementById("close-del-modal").click();
  };

  render() {
    return (
      <div className="settings-container">
        <div className="sec">
          <SecTitle
            title="Logo, Favicon and Cover Image"
            titleDesc="This is the look and feel of your page. Use logo, favicon and cover image to make a good first impression for your users as well as maitaining continuity when comming to spritzer from your own website."
          />

          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <p className="uplaod-heading">Logo</p>
              <p className="upload-info">
                The logo willbe displayed on the site header next to the name
                (Optimal Size: 150px by 35px)
              </p>
              <div className="upload-img-container">
                {this.state.logoImage ? (
                  <React.Fragment>
                    <img
                      src={del}
                      alt="delete"
                      className="del-uploads"
                      onClick={() => this.openDelModal(this.state.logoImageId)}
                    />
                    <img
                      src={this.state.logoImage}
                      alt="logo"
                      className="logo-preview-styling"
                    />
                  </React.Fragment>
                ) : (
                  <label htmlFor="logo-upload" className="add-img-btn">
                    {this.state.loadingLogo ? (
                      <div
                        className="spinner-border spinner-border-sm text-secondary"
                        role="status"
                        style={{ margin: 0 }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Add Image"
                    )}
                  </label>
                )}

                <input
                  name="logoFile"
                  type="file"
                  id="logo-upload"
                  onChange={this.uploadLogo}
                  accept="image/jpeg, image/png"
                />
              </div>

              <p className="uplaod-heading">Favicon</p>
              <p className="upload-info">
                Favicon is displayed on the browser tab near the title (Optimal
                Size: 64px by 64px)
              </p>
              <div className="upload-img-container">
                {this.state.favImage ? (
                  <React.Fragment>
                    <img
                      src={del}
                      alt="delete"
                      className="del-uploads"
                      onClick={() => this.openDelModal(this.state.favImageId)}
                    />
                    <img
                      src={this.state.favImage}
                      alt="favicon"
                      className="logo-preview-styling"
                    />
                  </React.Fragment>
                ) : (
                  <label htmlFor="fav-upload" className="add-img-btn">
                    {this.state.loadingFav ? (
                      <div
                        className="spinner-border spinner-border-sm text-secondary"
                        role="status"
                        style={{ margin: 0 }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Add Image"
                    )}
                  </label>
                )}

                <input
                  name="favFile"
                  type="file"
                  id="fav-upload"
                  onChange={this.uploadFav}
                  accept="image/jpeg, image/png"
                />
              </div>

              <p className="uplaod-heading">Cover Image</p>
              <p className="upload-info">Optimal Size: 1380px by 300px</p>
              <div className="cover-img-container">
                {this.state.coverImage ? (
                  <React.Fragment>
                    <img
                      src={del}
                      alt="delete"
                      className="del-uploads"
                      onClick={() => this.openDelModal(this.state.coverImageId)}
                    />
                    <img
                      src={this.state.coverImage}
                      alt="cover"
                      className="cover-preview-styling"
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <img src={cover} className="cover-img" alt="store" />
                    <label htmlFor="cover-upload" className="add-cover-img-btn">
                      {this.state.loadingCover ? (
                        <div
                          className="spinner-border spinner-border-sm text-secondary"
                          role="status"
                          style={{ margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Add Image"
                      )}
                    </label>
                  </React.Fragment>
                )}

                <input
                  name="coverFile"
                  type="file"
                  id="cover-upload"
                  onChange={this.uploadCover}
                  accept="image/jpeg, image/png"
                />
              </div>
            </div>
          </div>

          <button
            type="button"
            data-toggle="modal"
            data-target="#delete-modal"
            id="delete-hidden-btn"
            hidden
          ></button>

          <div
            className="modal fade add-cost-com"
            id="delete-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="delete-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="delete-modal"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Are you sure?
                  </h5>

                  <img
                    src={close}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-del-modal"
                    onClick={this.cancelDelete}
                  />
                </div>
                <div className="modal-body">
                  <div className="row m-0 ">
                    <div className="col-md p-0">
                      <p>You won't be able to revert this!</p>
                    </div>
                  </div>
                  <div className="row mt-4 m-0 ">
                    <div className="col-md p-0">
                      <button
                        type="button"
                        id="location-setting"
                        className="btn setting-save-btn float-right"
                        onClick={this.delIamges}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loading ? "" : "Yes"}
                      </button>
                      <button
                        type="button"
                        className="btn setting-cancel-btn float-right"
                        onClick={this.cancelDelete}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
