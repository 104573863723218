import React, { Component } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
export default class OrderInfo extends Component {
  getMinOrderValue = (orderItem, extraCost, orderTotal) => {
    let total = 0;

    const items = orderItem.reduce((currentValue, nextValue) => {
      return currentValue + nextValue.subtotal;
    }, 0);
    const extras =
      extraCost !== undefined &&
      extraCost &&
      extraCost.reduce((currentCost, cashExtraCost) => {
        if (cashExtraCost.percentage > 0) {
          return currentCost + (orderTotal * cashExtraCost.percentage) / 100;
        } else {
          return currentCost + cashExtraCost.value;
        }
      }, 0);

    total = items + (extras !== false ? extras : 0);
    return total;
  };

  render() {
    return (
      <div>
        {this.props.orders.map((order, i) => (
          <div
            key={order.orderId}
            className="col"
            onClick={() => this.props.handleInfo(order)}
            data-toggle="modal"
            data-target="#order-more-info"
            style={{
              background: this.props.highlightColor
                ? this.props.highlightColor
                : "",
              fontWeight: this.props.fontWeight ? this.props.fontWeight : "",
              color: "#333333"
            }}
          >
            <div className="row order-info-main-wrapper">
              <div className="orderid-col">{order.orderId}</div>
              <div className="date-col">
                <span>
                  {order.expectedTime ? (
                    <span>
                      {moment
                        .utc(order.expectedTime)
                        .local()
                        .calendar()
                        .includes("Tomorrow") ||
                      moment
                        .utc(order.expectedTime)
                        .local()
                        .calendar()
                        .includes("Today") ? (
                        moment.utc(order.expectedTime).local().calendar()
                      ) : (
                        <React.Fragment>
                          {moment
                            .utc(order.expectedTime)
                            .local()
                            .format("DD/MM/YY")}{" "}
                          {moment
                            .utc(order.expectedTime)
                            .local()
                            .format("hh:mm A")}
                        </React.Fragment>
                      )}
                      <span className="scheduled-order-icon">
                        <i className="fas fa-clock"></i>
                        <span className="scheduled-order-tooltip">
                          Scheduled Order
                        </span>
                      </span>
                    </span>
                  ) : (
                    <span>
                      {moment
                        .utc(order.createTime)
                        .local()
                        .format("DD/MM/YY hh:mm A")}
                    </span>
                  )}
                </span>
              </div>
              <div className="customer-col">{order.customerName}</div>

              <div className="suburb-col">
                {order.address ? order.address.city : null}
              </div>

              <div className="channel-col">
                {order.orderType ? (
                  <span
                    className={
                      order.orderType === "delivery"
                        ? "delivery-label"
                        : order.orderType === "pickup"
                        ? "pickup-label"
                        : "dinein-label"
                    }
                  >
                    {order.orderType === "delivery"
                      ? "Delivery"
                      : order.orderType === "pickup"
                      ? "Pickup"
                      : "Dine In"}
                  </span>
                ) : null}
              </div>

              <div className="amount-col">
                <NumberFormat
                  value={order.total.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </div>

              <div className="pay-method-col">
                <span
                  className={order.payOnDelivery ? "cash-label" : "card-label"}
                >
                  {order.payOnDelivery ? "Cash" : "Card"}
                </span>
                
                {this.props.orderType==='pending' && <i className="fas fa-circle reddot-icon"></i>}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
