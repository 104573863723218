import React, { Component } from "react";

export default class Breadcrumb extends Component {
  render() {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item main-page">{this.props.mainPage}</li>
          <li className="breadcrumb-item sub-page" aria-current="page">
            {this.props.subPage}
          </li>
          {this.props.secondSub && (
            <li className="breadcrumb-item sub-page" aria-current="page">
              {this.props.secondSub}
            </li>
          )}
          {this.props.thirdSub && (
            <li className="breadcrumb-item sub-page" aria-current="page">
              {this.props.thirdSub}
            </li>
          )}
        </ol>
      </nav>
    );
  }
}
