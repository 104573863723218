import React, { Component } from "react";
import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import Heading from "../components/dashboard/Heading";
import TitleOfPage from "../components/TitleOfPage";

import analytics from "../images/analytics.png";

export default class Analytics extends Component {
  state = {
    show: true,
  };

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "";
      document.getElementsByClassName(
        "page-section-heading"
      )[0].style.marginLeft = "20px";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
        document.getElementsByClassName(
          "page-section-heading"
        )[0].style.marginLeft = "50px";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div className="dashboard-content-col pb-4">
              <Heading heading="Analytics" />
              {this.state.show ? null : (
                <i
                  class="fas fa-chevron-circle-right side-menu-show"
                  onClick={() => this.showMenu(true)}
                ></i>
              )}
              <TitleOfPage title="Analytics - Spritzer Dashboard" />

              <div className="row m-0">
                <div className="col p-0">
                  <p className="page-inner-heading mt-3">
                    We are collecting user data at the moment to give you the
                    best insights possible.
                  </p>
                  <img
                    src={analytics}
                    className="analytics-img"
                    alt="analytics"
                    style={{maxWidth: 500}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
