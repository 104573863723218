import React, { Component } from "react";
import classnames from "classnames";
import axios from "axios";

import SecTitle from "../SecTitle";

import close from "../../../images/close-modal.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class Payout extends Component {
  state = {
    // country
    countryCode: "",

    // banks
    slBanks: [],
    branches: [],

    // bank details
    accountName: "",
    accountNumber: "",
    bankCode: "",
    branchCode: "",
    bsbNo: "",

    // errors
    accountNameError: "",
    branchCodeError: "",
    accountNumberError: "",

    // loading
    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    // get country name
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          countryCode: res.data[0].region.country.countryCodeShort,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/sl_bank_branches`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
          secret:
            "7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS",
        },
      })
      .then((res) => {
        this.setState(
          {
            slBanks: res.data,
            branches: res.data[0].branches,
            bankCode: res.data[0].name,
            branchCode: res.data[0].branches[0].branchCode,
          },
          () => {
            this.getListing();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.getListing();
      });
  };

  getListing = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/my_bankdetails`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            id: res.data[0].id,
            showingAccName: res.data[0].accountName,
            showingAccNo: res.data[0].accountNumber,
            accountName: res.data[0].accountName,
            accountNumber: res.data[0].accountNumber,
            bankCode: res.data[0].bankCode ? res.data[0].bankCode : "",
            branchCode: res.data[0].branchCode ? res.data[0].branchCode : "",
          });
        } else {
          this.setState({ showAddBankDetails: true });
        }
      })
      .catch((err) => console.log(err));
  };

  // handle input
  handleChange = (e) => {
    if (e.target.name === "bankCode") {
      const branches = this.state.slBanks.filter(
        (filter) => filter.code === e.target.value
      );
      this.setState({ branches: branches[0].branches });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  // validate
  validate = () => {
    let accountNameError = "";
    let accountNumberError = "";
    let bsbNoError = "";

    if (this.state.accountName === "") {
      accountNameError = "Account name is required";
    }

    if (this.state.accountNumber === "") {
      accountNumberError = "Account number is required";
    }

    if (this.state.countryCode !== "LK") {
      if (this.state.bsbNo === "") {
        bsbNoError = "BSB number is required";
      }
    }

    if (accountNameError || accountNumberError || bsbNoError) {
      this.setState({
        accountNameError,
        accountNumberError,
        bsbNoError,
      });
      return false;
    }

    return true;
  };

  // add bank account
  addBankAccount = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        accountNameError: "",
        accountNumberError: "",
        bsbNoError: "",
      });

      const bankDataSL = {
        accountName: this.state.accountName,
        accountNumber: this.state.accountNumber,
        bankCode: this.state.bankCode,
        branchCode: this.state.branchCode,
      };

      const bankDataAU = {
        accountName: this.state.accountName,
        accountNumber: this.state.accountNumber,
        bsbNo: this.state.bsbNo,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/my_bankdetails`,
          this.state.countryCode === "LK" ? bankDataSL : bankDataAU,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            showingAccName: this.state.accountName,
            showingAccNo: this.state.accountNumber,
            loading: false,
          });

          success();

          document.getElementById("close-bank-modal").click();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
          fail(err.response && err.response.status ? `Error code: ${err.response.status}` : null);
        });
    }
  };

  // update bank account
  updateBankAccount = () => {
    if (this.validate()) {
      this.setState({
        loading: true,
        accountNameError: "",
        accountNumberError: "",
        bsbNoError: "",
      });

      const bankDataSL = {
        accountName: this.state.accountName,
        accountNumber: this.state.accountNumber,
        bankCode: this.state.bankCode,
        branchCode: this.state.branchCode,
      };

      const bankDataAU = {
        accountName: this.state.accountName,
        accountNumber: this.state.accountNumber,
        bsbNo: this.state.bsbNo,
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v2/my_bankdetails/${this.state.id}`,
          this.state.countryCode === "LK" ? bankDataSL : bankDataAU,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            showingAccName: this.state.accountName,
            showingAccNo: this.state.accountNumber,
            loading: false,
          });

          success();
          document.getElementById("close-bank-modal").click();
        })
        .catch((err) => {
          this.setState({ loading: false });
          fail(err.response && err.response.status ? `Error code: ${err.response.status}` : null);
        });
    }
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    this.getListing();
    document.getElementById("close-bank-modal").click();
    this.setState({
      loadingCancel: false,
      accountNameError: "",
      branchCodeError: "",
      accountNumberError: "",
    });
  };

  render() {
    return (
      <div className="settings-container">
        <div className="sec">
          <SecTitle
            title="Payout Settings"
            titleDesc="Set your bank account information for payouts"
          />

          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <form className="setting-form d-inline-block form-location w-100">
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field">
                    <label className="title ml-0 mb-0">Bank Account</label>
                    <span
                      className="change-settings-btn"
                      data-toggle="modal"
                      data-target="#update-bank"
                    >
                      {this.state.showingAccName ? "Update" : "Add"}
                    </span>
                    <p className="inform">
                      Enter details for the account you would like to receive
                      payouts
                    </p>
                    {this.state.showingAccName && (
                      <input
                        type="text"
                        className="form-control"
                        value={`${this.state.showingAccName} / ************${
                          this.state.showingAccNo &&
                          this.state.showingAccNo.slice(
                            this.state.showingAccNo.length - 4
                          )
                        }`}
                        disabled
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md p-0"></div>
          </div>

          <div
            className="modal fade add-cost-com"
            id="update-bank"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="add-cost-com"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="add-cost-com"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Bank Account
                  </h5>

                  <img
                    src={close}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-bank-modal"
                    alt="close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    {this.state.countryCode === "LK" && (
                      <React.Fragment>
                        <div className="form-row">
                          <div className="form-group col mb-2">
                            <label htmlFor="bankCode" className="title ml-0">
                              Bank
                            </label>
                            <div className="input-group">
                              <select
                                id="bankCode"
                                className="form-control"
                                name="bankCode"
                                value={this.state.bankCode}
                                onChange={this.handleChange}
                              >
                                {/* <option>-- Select --</option> */}
                                {this.state.slBanks.map((bank, i) => (
                                  <option key={i} value={bank.code}>
                                    {bank.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="invalid-feedback">
                              {this.state.bankCodeError}
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col mb-2">
                            <label htmlFor="branchCode" className="title ml-0">
                              Branch
                            </label>
                            <div className="input-group">
                              <select
                                id="branchCode"
                                className="form-control"
                                name="branchCode"
                                value={this.state.branchCode}
                                onChange={this.handleChange}
                              >
                                {this.state.branches.map((branch, i) => (
                                  <option key={i} value={branch.branchCode}>
                                    {branch.name} ({branch.branchCode})
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="invalid-feedback">
                              {this.state.branchCodeError}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="form-row">
                      <div className="form-group col mb-2">
                        <label htmlFor="accountName" className="title ml-0">
                          Account Name
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.accountNameError,
                            })}
                            id="accountName"
                            name="accountName"
                            value={this.state.accountName}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="invalid-feedback">
                          {this.state.accountNameError}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col mb-2">
                        <label htmlFor="accountNumber" className="title ml-0">
                          Account Number
                        </label>
                        <div className="input-group">
                          <input
                            type="Number"
                            className={classnames("form-control", {
                              "is-invalid": this.state.accountNumberError,
                            })}
                            id="accountNumber"
                            name="accountNumber"
                            value={this.state.accountNumber}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="invalid-feedback">
                          {this.state.accountNumberError}
                        </div>
                      </div>
                    </div>
                    {this.state.countryCode !== "LK" && (
                      <div className="form-row">
                        <div className="form-group col mb-2">
                          <label htmlFor="bsbNo" className="title ml-0">
                            BSB Number
                          </label>
                          <div className="input-group">
                            <input
                              type="Number"
                              className={classnames("form-control", {
                                "is-invalid": this.state.accountNumberError,
                              })}
                              id="bsbNo"
                              name="bsbNo"
                              value={this.state.bsbNo}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {this.state.absbNoError}
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                  <div className="row mt-4 m-0 ">
                    <div className="col-md p-0">
                      {this.state.showAddBankDetails ? (
                        <button
                          type="button"
                          id="location-setting"
                          className="btn setting-save-btn float-right"
                          onClick={this.addBankAccount}
                        >
                          {this.state.loading ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                              style={{ margin: 0 }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}

                          {this.state.loading ? "" : "Save"}
                        </button>
                      ) : (
                        <button
                          type="button"
                          id="location-setting"
                          className="btn setting-save-btn float-right"
                          onClick={this.updateBankAccount}
                        >
                          {this.state.loading ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                              style={{ margin: 0 }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}

                          {this.state.loading ? "" : "Save"}
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn setting-cancel-btn float-right"
                        onClick={this.handleCancel}
                      >
                        {this.state.loadingCancel ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{ color: "#4f4f4f", margin: 0 }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loadingCancel ? "" : "Cancel"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
