import React, { Component } from "react";
import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import TitleOfPage from "../components/TitleOfPage";
import General from "../components/settings/resturant/General";
import Location from "../components/settings/resturant/Location";
import TimeSlots from "../components/settings/resturant/TimeSlots";
import Tax from "../components/settings/resturant/Tax";

import "../styles/settings.css";

export default class RestInfo extends Component {
  state = {
    show: true,
  };

  handleSideMenu = () => {
    if (window.screen.width < 768) {
      this.setState({ show: false });
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "100%";
    }
  };

  componentDidMount = () => {
    this.handleSideMenu();
    window.addEventListener("resize", this.handleSideMenu);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSideMenu);
  }

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "100%";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? <SideMenu show={this.state.show} showMenu={this.showMenu} /> : null}
            <div className="dashboard-content-col pb-4" style={{ background: "#f2f2f2" }}>
              {/* <Heading heading="Delivery Info" /> */}
              {this.state.show ? null : <i class="fas fa-chevron-circle-right side-menu-show" onClick={() => this.showMenu(true)}></i>}
              <TitleOfPage title="Restaurant Info - Spritzer Dashboard" />
              <General />
              <Location />
              <TimeSlots />
              <Tax />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
