import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import EdiText from 'react-editext'

const AddOnsSub = (props) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#D4D4D4" : "white",
    boxShadow: isDragging ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : null,
    border: isDragging ? "1px solid #bababa" : null,

    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : null,
  });

  return (
    <Droppable droppableId={props.type} type={`droppableSubItem-${props.type}`}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
          {props.items &&
            props.items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div>
                    <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      <div className="add-ons-table-body-inner" key={item.id}>
                        <span className="draggable-icon-addon">
                          <i className="fas fa-bars"></i>
                        </span>
                        <div className="addons-details-col">{item.name}</div>
                        <div className="addons-price-col" style={{ width: "33%" }}>
                            <EdiText
                                type="number"
                                value={item.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                onEditingStart={() => props.handleAddOnItemDetails(item.id, props.groupId)}
                                onCancel={() => props.handleAddOnItemDetails("", "")}
                                saveButtonClassName="item-name-save-button"
                                editButtonClassName="addon-price-edit-button"
                                cancelButtonClassName="item-name-cancel-button"
                                onSave={props.updateAddOnItemDetails}
                            />
                        </div>
                        <div className="addons-availability-col" style={{ visibility: "hidden", width: 0 }}>
                          <div className="custom-control custom-switch m-0">
                            <input type="checkbox" className="custom-control-input" id={item.id} checked={item.isAvailable} onChange={() => props.handleItemAvailability(item.id, !item.isAvailable)} />
                            <label className="custom-control-label" htmlFor={item.id}></label>
                          </div>
                        </div>
                        <div className="remove-addon" onClick={() => props.showDelItemModal(props.groupId, item.id)}>
                          <i className="fas fa-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default AddOnsSub;
