import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

import Navbar from "../components/Navbar";
import TitleOfPage from "../components/TitleOfPage";

import "../styles/signUp.css";

import { isAuthenticated } from "../auth/isAuth";

// initiate state
const initialState = {
  name: "",
  customDomain: "",

  // redirect
  redirectToDashboard: false,
  redirectToGetStarted: false,
  redirectToSignUp: false,
  redirectToLogin: false,

  // loading
  loading: false,
};

export default class ContinueToDashboard extends Component {
  // initial state
  state = initialState;

  componentDidMount = () => {
    if (document.cookie.includes("auth_dash_token")) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.setState({
              name: res.data[0].name,
              customDomain: res.data[0].customDomain
                ? res.data[0].customDomain
                : "",
              extId: res.data[0].extId,
            });
          } else {
            this.setState({
              redirectToSignUp: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            redirectToSignUp: true,
          });
        });
    } else {
      this.setState({
        redirectToLogin: true,
      });
    }
  };

  handleContinue = () => {
    if (document.cookie.includes("auth_dash_token")) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            localStorage.setItem("listing", JSON.stringify(res.data[0]));
            if (res.data[0].status === "verified") {
              this.setState({
                redirectToDashboard: true,
                loading: false,
              });
            } else {
              this.setState({
                redirectToGetStarted: true,
                loading: false,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // logout
  logout = (e) => {
    e.preventDefault();

    if (typeof window !== "undefined") {
      Cookies.remove("auth_dash_token", { path: "", domain: ".spritzer.app" });
      localStorage.removeItem("profile");
      localStorage.removeItem("listing");
    }

    this.setState({ redirectToLogin: true });
  };

  // logout
  goToSignUp = (e) => {
    e.preventDefault();

    if (typeof window !== "undefined") {
      Cookies.remove("auth_dash_token", { path: "", domain: ".spritzer.app" });
      localStorage.removeItem("profile");
      localStorage.removeItem("listing");
    }

    this.setState({ redirectToSignUp: true });
  };

  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="Sign Up | Spritzer Dashboard" />

        {this.state.redirectToLogin ? <Redirect to="/" /> : null}
        {this.state.redirectToSignUp ? <Redirect to="/sign-up" /> : null}

        {this.state.redirectToDashboard ? (
          <Redirect to="/manage-orders" />
        ) : null}

        {this.state.redirectToGetStarted ? (
          <Redirect to="/get-started" />
        ) : null}

        <Navbar />

        <div className="row m-0 sign-up-main-div">
          <div className="col px-3 py-0">
            <div className="sign-up-form-div">
              <div className="sign-up-form" style={{ marginTop: "20vh" }}>
                <div className="row mx-0">
                  <div className="col p-0">
                    <p className="continue-heading">
                      Continue to your dashboard
                    </p>

                    <div className="logged-details-div">
                      <p>You are logged in as</p>

                      <p>{this.state.name}</p>
                      <p>
                        {this.state.customDomain
                          ? this.state.customDomain
                          : `${this.state.extId}.spritzer.app`}
                      </p>
                    </div>
                    <button
                      className="register-btn"
                      onClick={this.handleContinue}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}
                      {this.state.loading ? "" : <span>Continue</span>}
                    </button>

                    <p className="not-your-account">
                      Not your account?{" "}
                      <span onClick={this.logout}>Logout</span>
                    </p>

                    <p className="new">
                      New to Spritzer?{" "}
                      <span onClick={this.goToSignUp}>Get Started</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
