import React, { Component, createRef } from "react";
import reactCSS from "reactcss";
import axios from "axios";
import { SketchPicker } from "react-color";

import SecTitle from "../SecTitle";

import "../../../styles/theme.css";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class Location extends Component {
  cropper = createRef();

  state = {
    primary: "#000000",
    secondary: "#000000",
    header: "#000000",
    footer: "#000000",

    // display color pickers
    displayColorPickerPrimary: false,
    displayColorPickerSecondary: false,
    displayColorPickerHeader: false,
    displayColorPickerFooter: false,

    // loading
    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          primary: res.data[0].branding
            ? res.data[0].branding.colorAccent
              ? res.data[0].branding.colorAccent
              : "#000000"
            : "#000000",
          secondary: res.data[0].branding
            ? res.data[0].branding.colorAccent2
              ? res.data[0].branding.colorAccent2
              : "#000000"
            : "#000000",
          header: res.data[0].branding
            ? res.data[0].branding.colorHeader
              ? res.data[0].branding.colorHeader
              : "#000000"
            : "#000000",
          footer: res.data[0].branding
            ? res.data[0].branding.colorFooter
              ? res.data[0].branding.colorFooter
              : "#000000"
            : "#000000",
          loadingCancel: false,
        });
      })
      .catch((err) => {
        this.setState({ loadingCancel: false });
        console.log(err);
      });
  };

  handleClickPrimary = () => {
    this.setState({
      displayColorPickerPrimary: true,
    });
  };
  handleClickSecondary = () => {
    this.setState({
      displayColorPickerSecondary: true,
    });
  };
  handleClickHeader = () => {
    this.setState({
      displayColorPickerHeader: true,
    });
  };
  handleClickFooter = () => {
    this.setState({
      displayColorPickerFooter: true,
    });
  };

  handleColorClose = () => {
    this.setState({
      displayColorPickerPrimary: false,
      displayColorPickerSecondary: false,
      displayColorPickerHeader: false,
      displayColorPickerFooter: false,
    });
  };

  handleChangePrimary = (color) => {
    this.setState({ primary: color.hex });
  };
  handleChangeSeondary = (color) => {
    this.setState({ secondary: color.hex });
  };
  handleChangeHeader = (color) => {
    this.setState({ header: color.hex });
  };
  handleChangeFooter = (color) => {
    this.setState({ footer: color.hex });
  };

  handleSave = () => {
    let data = {
      colorAccent: this.state.primary,
      colorAccent2: this.state.secondary,
      colorFooter: this.state.footer,
      colorHeader: this.state.header,
    };

    // set loading
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/branding`,
        data,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        success();
      })
      .catch((err) => {
        this.setState({ loading: false });
        fail();
      });
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    this.getListing();
  };

  render() {
    const styles = reactCSS({
      default: {
        primary: {
          width: "18px",
          height: "18px",
          borderRadius: "2px",
          background: this.state.primary,
        },
        secondary: {
          width: "18px",
          height: "18px",
          borderRadius: "2px",
          background: this.state.secondary,
        },
        header: {
          width: "18px",
          height: "18px",
          borderRadius: "2px",
          background: this.state.header,
        },
        footer: {
          width: "18px",
          height: "18px",
          borderRadius: "2px",
          background: this.state.footer,
        },
        swatch: {
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
          width: 18,
          height: 18,
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return (
      <div className="settings-container">
        <div className="sec">
          <SecTitle
            title="Color Themes"
            titleDesc="This is the look and feel of your page. Use colors and fonts to make a good first impression for your users as well as maitaining continuity when comming to spritzer from your own website."
          />

          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <div className="color-row">
                <div className="color-col">
                  <p>Primary Accent Color</p>
                  <div style={styles.swatch} onClick={this.handleClickPrimary}>
                    <div style={styles.primary} />
                  </div>
                  <span>{this.state.primary}</span>
                  {this.state.displayColorPickerPrimary ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={this.handleColorClose}
                      />
                      <SketchPicker
                        color={this.state.primary}
                        onChange={this.handleChangePrimary}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="color-col">
                  <p>Secondary Accent Color</p>
                  <div
                    style={styles.swatch}
                    onClick={this.handleClickSecondary}
                  >
                    <div style={styles.secondary} />
                  </div>
                  <span>{this.state.secondary}</span>
                  {this.state.displayColorPickerSecondary ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={this.handleColorClose}
                      />
                      <SketchPicker
                        color={this.state.secondary}
                        onChange={this.handleChangeSeondary}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="color-col">
                  <p>Page Header Color</p>
                  <div style={styles.swatch} onClick={this.handleClickHeader}>
                    <div style={styles.header} />
                  </div>
                  <span>{this.state.header}</span>
                  {this.state.displayColorPickerHeader ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={this.handleColorClose}
                      />
                      <SketchPicker
                        color={this.state.header}
                        onChange={this.handleChangeHeader}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="color-col">
                  <p>Page Footer Color</p>
                  <div style={styles.swatch} onClick={this.handleClickFooter}>
                    <div style={styles.footer} />
                  </div>
                  <span>{this.state.footer}</span>
                  {this.state.displayColorPickerFooter ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={this.handleColorClose}
                      />
                      <SketchPicker
                        color={this.state.footer}
                        onChange={this.handleChangeFooter}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <button
                type="button"
                id="location-setting"
                className="btn setting-save-btn float-right"
                onClick={this.handleSave}
              >
                {this.state.loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    style={{ margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loading ? "" : "Save"}
              </button>
              <button
                type="button"
                className="btn setting-cancel-btn float-right"
                onClick={this.handleCancel}
              >
                {this.state.loadingCancel ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "#4f4f4f", margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loadingCancel ? "" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
