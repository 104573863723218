import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Lines } from "react-preloaders";
import { isAuthenticated } from "../auth/isAuth";

export default class Bill extends Component {
  state = {
    listingId: "",
    currency: "",
    ipgFeePercentage: "0.05",
    bills: [],
    selectedBill: [],
    billInDayWise: [],
    thisWeekStartFrom: "",

    // pre loading
    preLoading: true,
  };

  componentDidMount = () => {
    // get currency
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          currency: res.data[0].region.country.currencySymbol,
          listingId: res.data[0].id,
          ipgFeePercentage: res.data[0].ipgFeePercentage
            ? res.data[0].ipgFeePercentage
            : "0.05",
        });

        // this week
        if (true) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/invoices/pending`,
              {
                headers: {
                  Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
              }
            )
            .then((res) => {
              const totOrdersByCard = res.data.reduce(
                (currentValue, nextValue) => {
                  return currentValue + nextValue.ordersByCard;
                },
                0
              );

              const totOrdersByCash = res.data.reduce(
                (currentValue, nextValue) => {
                  return currentValue + nextValue.ordersByCash;
                },
                0
              );

              const totalByCard = res.data.reduce((currentValue, nextValue) => {
                return currentValue + nextValue.totalByCard;
              }, 0);
              const totalByCash = res.data.reduce((currentValue, nextValue) => {
                return currentValue + nextValue.totalByCash;
              }, 0);
              const totalFees = res.data.reduce((currentValue, nextValue) => {
                return currentValue + nextValue.totalFees;
              }, 0);

              const from = res.data[res.data.length - 1].from;
              const to = res.data[0].to;

              const data = [
                {
                  from: from,
                  to: to,
                  invoiceNumber: "",
                  ordersByCard: totOrdersByCard,
                  ordersByCash: totOrdersByCash,
                  totalByCard: totalByCard,
                  totalByCash: totalByCash,
                  totalFees: totalFees,
                },
              ];

              this.setState({
                thisWeekStartFrom: from,
                billInDayWise: res.data,
                selectedBill: data,
                preLoading: false,
              });
            })
            .catch((err) => console.log(err));
        }
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/invoices`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            this.setState({ bills: res.data, preLoading: false });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // change statement
  changeStatement = (e) => {
    if (e.target.value !== "-- Select --" && e.target.value !== "This Week") {
      let FromTo = e.target.value.split(",");

      const filterBill = this.state.bills.filter((filter) => {
        return filter.from === FromTo[0] && filter.to === FromTo[1];
      });

      this.setState({ thisWeekStartFrom: "", selectedBill: filterBill });

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/invoices/${FromTo[2]}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ billInDayWise: res.data });
        })
        .catch((err) => console.log(err));
    } else if (e.target.value === "This Week") {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/invoices/pending`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          const totOrdersByCard = res.data.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.ordersByCard;
          }, 0);

          const totOrdersByCash = res.data.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.ordersByCash;
          }, 0);

          const totalByCard = res.data.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.totalByCard;
          }, 0);
          const totalByCash = res.data.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.totalByCash;
          }, 0);
          const totalFees = res.data.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.totalFees;
          }, 0);

          const from = res.data[res.data.length - 1].from;
          const to = res.data[0].to;

          const data = [
            {
              from: from,
              to: to,
              invoiceNumber: "",
              ordersByCard: totOrdersByCard,
              ordersByCash: totOrdersByCash,
              totalByCard: totalByCard,
              totalByCash: totalByCash,
              totalFees: totalFees,
            },
          ];

          this.setState({
            thisWeekStartFrom: from,
            billInDayWise: res.data,
            selectedBill: data,
          });
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ billInDayWise: [], selectedBill: [] });
    }
  };

  render() {
    const sortBills = this.state.bills.sort((a, b) => {
      return b.from.localeCompare(a.to);
    });

    console.log(this.state.preLoading);

    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#F2F2F2"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            <div className="mx-auto" style={{ maxWidth: 900, background: "#ffffff", borderRadius: "5px" }}>
              <div className="btn-group d-block mx-auto my-5 mb-4" style={{ paddingTop: "2rem" }}>
                <select
                  id="bill-type"
                  className="billing-dropdown"
                  onChange={this.changeStatement}
                >
                  {/* <option className="text-center">-- Select --</option> */}
                  <option selected>This Week</option>
                  {sortBills.map((bill, i) => (
                    <option
                      key={i}
                      value={
                        bill.from + "," + bill.to + "," + bill.invoiceNumber
                      }
                    >
                      {moment.utc(bill.from).local().format("DD MMM")} -{" "}
                      {moment.utc(bill.to).local().format("DD MMM")}
                    </option>
                  ))}
                </select>
              </div>

              {this.state.selectedBill &&
                this.state.selectedBill.map((bill, i) => (
                  <div key={i}>
                    <p className="billing-amount">
                      {this.state.currency}{" "}
                      <NumberFormat
                        value={(bill.totalByCash + bill.totalByCard).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </p>
                    <p className="time-period" style={{ color: "#2196f3" }}>
                      Total Sales
                    </p>
                    {bill.invoiceNumber ? (
                      <p className="time-period font-weight-bold">
                        Invoice No : {bill.invoiceNumber}
                      </p>
                    ) : null}

                    <div className="row bill-summery-row mt-5">
                      <div className="col-sm-6 col-md-4 p-0 first-col">
                        <p className="mb-0 first-p completed-orders">
                          <span className="big-font">
                            {bill.ordersByCash + bill.ordersByCard}
                          </span>{" "}
                          DELIVERED
                        </p>
                        {/* <p className="second-p text-danger">3 rejected</p> */}
                      </div>
                      <div className="col-sm-6 col-md-4 p-0 second-col">
                        <p className="mb-0 first-p">
                          <span className="big-font">
                            {this.state.currency}{" "}
                            <NumberFormat
                              value={bill.totalByCash.toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </p>
                        <p className="second-p">
                          {bill.ordersByCash} CASH ON DELIVERY
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-4 p-0 third-col">
                        <p className="mb-0 first-p">
                          <span className="big-font">
                            {this.state.currency}{" "}
                            <NumberFormat
                              value={bill.totalByCard.toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </p>
                        <p className="second-p">
                          {bill.ordersByCard} CARD PAYMENTS
                        </p>
                      </div>
                    </div>
                    {bill.invoiceNumber ? (
                      <div className="row m-0 mt-4">
                        <div className="col p-0 billing-table">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>
                                  <p className="mb-0 ml-0">
                                    Total payments received online
                                  </p>
                                  <p className="mb-3">
                                    Payment processing fee (
                                    {(
                                      this.state.ipgFeePercentage * 100
                                    ).toFixed(2)}
                                    %)
                                  </p>
                                  <p className="mb-0 font-weight-bold">
                                    Paid on{" "}
                                    {moment.utc(bill.to).local().format("LL")}
                                  </p>
                                </td>
                                <td className="text-right">
                                  <p className="mb-0">
                                    {this.state.currency}{" "}
                                    <NumberFormat
                                      value={bill.totalByCard.toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </p>
                                  <p className="mb-3">
                                    {this.state.currency}{" "}
                                    <NumberFormat
                                      value={bill.totalFees.toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </p>
                                  <p className="mb-0 font-weight-bold">
                                    {this.state.currency}{" "}
                                    <NumberFormat
                                      value={(
                                        bill.totalByCard - bill.totalFees
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}

              {this.state.billInDayWise.length > 0 ? (
                <div className="row m-0 mt-5">
                  <div className="col p-0 analized-table">
                    <table
                      className="table table-responsive"
                      style={{ fontSize: 14 }}
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th className="text-center">Completed</th>
                          <th className="text-center">Rejected</th>
                          <th className="text-right">
                            Cash Sales ({this.state.currency})
                          </th>
                          <th className="text-right">
                            Card Sales ({this.state.currency})
                          </th>
                          <th className="text-right">
                            Card Fee (
                            {(this.state.ipgFeePercentage * 100).toFixed(2)}%) (
                            {this.state.currency})
                          </th>
                          <th className="text-right">
                            Payout ({this.state.currency})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.billInDayWise.map((dayBill, i) => (
                          <tr key={i}>
                            <td>
                              {moment
                                .utc(dayBill.from)
                                .local()
                                .format("DD-MMM-YY")}
                            </td>
                            <td className="text-center">
                              {dayBill.ordersByCash + dayBill.ordersByCard}
                            </td>
                            <td className="text-center">
                              {dayBill.rejectedOrders}
                            </td>
                            <td className="text-right">
                              <NumberFormat
                                value={dayBill.totalByCash.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                            <td className="text-right">
                              <NumberFormat
                                value={dayBill.totalByCard.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                            <td className="text-right">
                              <NumberFormat
                                value={dayBill.totalFees.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                            <td className="text-right">
                              <NumberFormat
                                value={(
                                  dayBill.totalByCard - dayBill.totalFees
                                ).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                          </tr>
                        ))}
                        <tr className="font-weight-bold">
                          <td></td>
                          <td className="text-center">
                            {this.state.billInDayWise.reduce(
                              (currentValue, nextValue) => {
                                return currentValue + nextValue.ordersByCash;
                              },
                              0
                            ) +
                              this.state.billInDayWise.reduce(
                                (currentValue, nextValue) => {
                                  return currentValue + nextValue.ordersByCard;
                                },
                                0
                              )}
                          </td>
                          <td className="text-center">
                            {this.state.billInDayWise.reduce(
                              (currentValue, nextValue) => {
                                return currentValue + nextValue.rejectedOrders;
                              },
                              0
                            )}
                          </td>
                          <td className="text-right">
                            <NumberFormat
                              value={this.state.billInDayWise
                                .reduce((currentValue, nextValue) => {
                                  return currentValue + nextValue.totalByCash;
                                }, 0)
                                .toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td className="text-right">
                            <NumberFormat
                              value={this.state.billInDayWise
                                .reduce((currentValue, nextValue) => {
                                  return currentValue + nextValue.totalByCard;
                                }, 0)
                                .toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td className="text-right">
                            <NumberFormat
                              value={this.state.billInDayWise
                                .reduce((currentValue, nextValue) => {
                                  return currentValue + nextValue.totalFees;
                                }, 0)
                                .toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td className="text-right">
                            <NumberFormat
                              value={this.state.billInDayWise
                                .reduce((currentValue, nextValue) => {
                                  return (
                                    currentValue +
                                    (nextValue.totalByCard -
                                      nextValue.totalFees)
                                  );
                                }, 0)
                                .toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        </tr>
                      </tbody>
                      {this.state.thisWeekStartFrom ? (
                        <p className="font-weight-bold mt-4">
                          Next Payout on{" "}
                          {moment
                            .utc(this.state.thisWeekStartFrom)
                            .local()
                            .add(7, "days")
                            .format("LL")}
                        </p>
                      ) : null}
                    </table>

                    <p className="text-center mt-5">
                      For any enquiry please call +94 777 344 327
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </React.Fragment>
        )}
        ;
      </React.Fragment>
    );
  }
}
