import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import moment from "moment";
import NumberFormat from "react-number-format";

export class OrderInfoModal extends Component {
  state = {
    isAdditionalInfo: false,
    orderId: "",
    triggerShipday: false,
    currency: {},
  };

  componentDidUpdate = () => {
    if (this.state.orderId && this.state.orderId !== this.props.order.orderId) {
      this.setState({
        isAdditionalInfo: false,
        orderId: "",
        triggerShipday: false,
      });
    }

    if (this.state.orderId === "") {
      this.setState({
        orderId: this.props.order.orderId,
      });
      if (document.getElementById("check-shipday")) {
        document.getElementById("check-shipday").checked = false;
      }
    }
  };

  handleChange = () => {
    this.setState({
      triggerShipday: !this.state.triggerShipday,
    });
  };

  getCurrencySymbol = (currency) => {
    return currency?.symbol || currency?.code || "";
  };

  render() {
    const itemsTotal =
      this.props.order &&
      this.props.order.orderItems &&
      this.props.order.orderItems
        .reduce((currentValue, nextValue) => {
          return currentValue + nextValue.subtotal;
        }, 0)
        .toFixed(2);

    const discount =
      this.props.order &&
      this.props.order.orderDiscount &&
      this.props.order.orderDiscount.threshold &&
      this.props.order.orderDiscount.threshold <= itemsTotal
        ? this.props.order.orderDiscount.isPercentage
          ? (this.props.order.orderDiscount.percentage / 100) * itemsTotal
          : this.props.order.orderDiscount.value
        : 0;

    const TotalAfterDiscount = itemsTotal - discount;

    return (
      <div
        class="modal fade"
        id="order-more-info"
        tabindex="-1"
        role="dialog"
        aria-labelledby="order-more-info"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="order-info-display">
              <div className="modal-header">
                <h5 className="modal-title">
                  Order No: {this.props.order.orderId}
                </h5>

                <button
                  type="button"
                  className="close order-more-info-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  alt="close"
                >
                  <span aria-hidden="true" className="close-cart">
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="order-times">
                  <div className="ordered-time">
                    <h6>Order Time</h6>
                    <p>
                      {moment
                        .utc(this.props.order.createTime)
                        .local()
                        .format("LLLL")}
                    </p>
                  </div>

                  {this.props.order.expectedTime && (
                    <div className="reqested-time">
                      <h6>Requested For</h6>
                      <p>
                        {moment
                          .utc(this.props.order.expectedTime)
                          .local()
                          .format("LLLL")}
                      </p>
                    </div>
                  )}
                </div>

                <div className="order-contact-details">
                  <h6>Contact Details</h6>
                  <p>
                    {this.props.order.customerName} - +
                    {this.props.order.customerPhone}
                  </p>
                </div>

                {this.props.order.orderType === "dine_in" && (
                  <div className="order-dine-in-table">
                    <h6>Table</h6>
                    <p>
                      {this.props.order.table
                        ? this.props.order.table?.name
                        : "None"}
                    </p>
                  </div>
                )}

                <div className="order-delivery-note">
                  <h6>Delivery Note</h6>
                  <p>
                    {this.props.order.deliveryNote
                      ? this.props.order.deliveryNote
                      : "None"}
                  </p>
                </div>

                <div className="manage-order-details-body">
                  {this.props &&
                    this.props.order &&
                    this.props.order.orderItems &&
                    this.props.order.orderItems.map((item, i) => (
                      <div className="ordered-item" key={i}>
                        <div className="manage-order-details-header">
                          <div className="mod-qty">{item.quantity}x</div>
                          <div className="mod-item">{item.item.name}</div>
                          <div className="mod-amount">
                            {`${this.getCurrencySymbol(this.props.currency)} `}
                            <NumberFormat
                              value={item.subtotal.toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </div>
                        </div>

                        <div className="order-other-info">
                          <div className="order-category-title">
                            {item.item.category.title}
                          </div>

                          {item.portionSize ? (
                            <div className="mo-order-menu-item-addon-main">
                              <div className="mod-item-title">Portion Size</div>
                              <div className="mo-order-menu-item-addon">
                                <div>{item.portionSize.name}</div>
                              </div>
                            </div>
                          ) : null}

                          {item.addonItems && item.addonItems.length ? (
                            <div className="mo-order-menu-item-addon-main">
                              <div className="mod-item-title">AddOns</div>
                              {item.addonItems.map((addon, i) => (
                                <div
                                  className="mo-order-menu-item-addon"
                                  key={i}
                                >
                                  <div>{addon.name} </div>
                                  <div>{`${this.getCurrencySymbol(this.props.currency)} ${addon.price}`}</div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}

                  <div className="manage-order-details-total-wrapper">
                    <div className="mod-total-title">
                      {this.props &&
                        this.props.order &&
                        this.props.order.orderItems &&
                        this.props.order.orderItems.reduce(
                          (currentValue, nextValue) => {
                            return currentValue + nextValue.quantity;
                          },
                          0
                        )}
                    </div>

                    <div className="mod-total-amount">
                      <NumberFormat
                        value={itemsTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </div>
                  </div>

                  {this.props.order.minOrderMatchAmount ? (
                    <div className="mod-total-min-order-wrapper">
                      <div className="mod-total-title">
                        {`Match minimum order value {${this.getCurrencySymbol(this.props.currency)}`}
                      </div>

                      <div className="mod-total-amount">
                        <NumberFormat
                          value={this.props.order.minOrderMatchAmount.toFixed(
                            2
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.props.order &&
                    this.props.order.orderDiscount &&
                    this.props.order.orderDiscount.threshold &&
                    this.props.order.orderDiscount.threshold <= itemsTotal && (
                      <div className="mod-total-min-order-wrapper">
                        <div className="mod-total-title">
                          {this.props.order.orderDiscount.isPercentage
                            ? `${this.props.order.orderDiscount.percentage}%`
                            : `${this.getCurrencySymbol(this.props.currency)}`}
                          {`discount for orders above ${this.getCurrencySymbol(this.props.currency)}`}
                          {this.props.order.orderDiscount.threshold.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </div>

                        <div className="mod-total-amount">
                          {`- ${this.getCurrencySymbol(this.props.currency)} `}
                          {discount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    )}

                  {discount > 0 && (
                    <div className="mod-total-min-order-wrapper">
                      <div className="mod-total-title">
                        Total after Order Discount
                      </div>

                      <div className="mod-total-amount">
                        {` ${this.getCurrencySymbol(this.props.currency)} `}
                        {TotalAfterDiscount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                  )}

                  {this.props.order.extraCosts &&
                  this.props.order.extraCosts.length > 0 ? (
                    <div className="mod-extra-cost-wrapper">
                      <div className="mod-total-title">{`Extra Cost ${this.getCurrencySymbol(this.props.currency)} `}</div>
                      {this.props.order.extraCosts.map((exCost, i) => (
                        <>
                          {exCost.percentage === 0 && (
                            <div className="mod-extra-cost-inner" key={i * 2}>
                              <div className="mod-cost-title">
                                {exCost.description}
                              </div>
                              <div className="mod-total-amount">
                                <NumberFormat
                                  value={exCost.value.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </div>
                            </div>
                          )}
                          {exCost.value === 0 && (
                            <div className="mod-extra-cost-inner" key={i * 3}>
                              <div className="mod-cost-title">
                                {exCost.description}
                              </div>
                              <div className="mod-total-amount">
                                {`- ${this.getCurrencySymbol(this.props.currency)}`}
                                {(
                                  (TotalAfterDiscount *
                                    Math.abs(exCost.percentage)) /
                                  100
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  ) : null}

                  {this.props.order.taxes &&
                  this.props.order.taxes.length > 0 ? (
                    <div className="mod-extra-cost-wrapper">
                      <div className="mod-total-title">{`Tax (${this.getCurrencySymbol(this.props.currency)}) `}</div>
                      {this.props.order.taxes.map((tax, i) => (
                        <div className="mod-extra-cost-inner" key={i * 2}>
                          <div className="mod-cost-title">
                            {tax.description}
                          </div>
                          <div className="mod-total-amount">
                            {`${tax.percentage}%`}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}

                  <div className="mod-sub-total-wrapper">
                    <div className="mod-total-title">{`Sub Total (${this.props.currency}) `}</div>
                    <div className="mod-total-amount">
                      <NumberFormat
                        value={
                          this.props.order &&
                          this.props.order.subtotal &&
                          this.props.order.subtotal.toFixed(2)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </div>
                  </div>

                  <div className="mod-delivery-fee-wrapper">
                    <div className="mod-total-title">{`Delivery Fee (${this.getCurrencySymbol(this.props.currency)})`}</div>
                    <div className="mod-total-amount">
                      <NumberFormat
                        value={
                          this.props.order &&
                          this.props.order.deliveryFee &&
                          this.props.order.deliveryFee.toFixed(2)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </div>
                  </div>

                  <div className="mod-grand-total-wrapper">
                    <div className="mod-total-title">{`Order Total (${this.getCurrencySymbol(this.props.currency)})`}</div>
                    <div className="mod-total-amount">
                      <NumberFormat
                        value={
                          this.props.order &&
                          this.props.order.total &&
                          this.props.order.total.toFixed(2)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="action-col">
                  {this.props.order.status === "dispatched" ? (
                    <div className="order-status-display delivered">
                      Order Delivered
                    </div>
                  ) : null}

                  {this.props.order.status === "rejected" ? (
                    <div className="order-status-display rejected">
                      Order Rejected
                    </div>
                  ) : null}

                  {this.props.order.status === "pending" ? (
                    <React.Fragment>
                      <button
                        className="btn btn-primary accept"
                        onClick={() =>
                          this.props.handleStatus(
                            "accepted",
                            this.props.order.orderId
                          )
                        }
                      >
                        {this.props.acceptLoading ? (
                          <div
                            className="spinner-border spinner-border-sm text-white"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {this.props.acceptLoading ? "" : "Accept"}
                      </button>

                      <button
                        className="btn btn-secondary reject"
                        disabled={this.props.acceptLoading}
                        onClick={() =>
                          this.props.handleStatus(
                            "rejected",
                            this.props.order.orderId
                          )
                        }
                      >
                        Reject
                      </button>
                    </React.Fragment>
                  ) : null}
                  {this.props.order.status === "accepted" ? (
                    <>
                      {this.props.order.orderType === "delivery" &&
                        this.props.isShipdayAvailable && (
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={this.state.triggerShipday}
                              id="check-shipday"
                              onChange={this.handleChange}
                            />
                            <label class="form-check-label" for="check-shipday">
                              Trigger Shipday
                            </label>
                          </div>
                        )}

                      <button
                        className="btn btn-success ready"
                        onClick={() =>
                          this.props.handleStatus(
                            "dispatched",
                            this.props.order.orderId,
                            {
                              integration: "shipday",
                              value: this.state.triggerShipday,
                            }
                          )
                        }
                      >
                        {this.props.readyLoading ? (
                          <div
                            className="spinner-border spinner-border-sm text-white"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {this.props.readyLoading ? "" : "Ready"}
                      </button>
                    </>
                  ) : null}
                </div>

                <div className="order-address-info">
                  <div className="order-address-block">
                    <div>
                      {this.props.order.address ? (
                        <React.Fragment>
                          <p className="order-address-title">Address</p>
                          <p className="order-address-sec mb-0">
                            {this.props.order.address.line1}
                          </p>
                          {this.props.order.address.line2 ? (
                            <p className="order-address-sec mb-0">
                              {this.props.order.address.line2}
                            </p>
                          ) : null}
                          <p className="order-address-sec mb-0">
                            {this.props.order.address.city}
                          </p>
                          <p className="order-address-sec mb-0">
                            {this.props.order.address.country}
                          </p>
                        </React.Fragment>
                      ) : null}
                    </div>

                    {this.props.order.address &&
                    this.props.order.address.latitude &&
                    this.props.order.address.longitude ? (
                      <div>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${this.props.order.address.latitude},${this.props.order.address.longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-block mt-1"
                        >
                          <i
                            className="fas fa-external-link-alt"
                            style={{ fontSize: 13 }}
                          ></i>{" "}
                          Open in maps
                        </a>
                      </div>
                    ) : null}
                  </div>

                  {this.props.order.address ? (
                    <div className="order-map-block">
                      {this.props.order.address.latitude &&
                      this.props.order.address.longitude ? (
                        <React.Fragment>
                          <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=700x300&maptype=roadmap&markers=color:red%7Clabel:%7C${this.props.order.address.latitude},${this.props.order.address.longitude}%7C&key=AIzaSyAx1D1lhp79KaXfQBhvs2aS8IHw5Dtql48`}
                            width="100%"
                          />
                        </React.Fragment>
                      ) : (
                        <p className="text-danger">
                          Customer map location is not provided
                        </p>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="order-additional-info">
                  <div className="oai-header">
                    <label
                      htmlFor="info"
                      className="additional-order-info-heading ml-0 d-block"
                    >
                      Additional Order Info
                    </label>
                    <button
                      type="button"
                      class="btn btn-light text-primary"
                      onClick={() =>
                        this.setState({
                          isAdditionalInfo: true,
                          orderId: this.props.order.orderId,
                        })
                      }
                    >
                      Edit
                    </button>
                  </div>
                  <textarea
                    type="text"
                    className="form-control additional-info-input"
                    id="info"
                    name="info"
                    readOnly={this.state.isAdditionalInfo === false}
                    placeholder="Eg: Delivery person, phone number etc ..."
                    value={this.props.info}
                    onChange={this.props.handleChange}
                  ></textarea>
                  {this.state.isAdditionalInfo ? (
                    <div className="info-save-btn-block">
                      <button
                        type="button"
                        className="btn btn-primary info-save-btn"
                        onClick={() =>
                          this.props.saveCustomInfo(
                            this.props.order.orderId,
                            this.props.order.listingId,
                            this.props.info
                          )
                        }
                      >
                        {this.props.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-white"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.props.loading ? "Saving..." : "Save"}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC8EFXuIwNdJVSEzVOCIDJeCRM2Rhuj8VY",
})(OrderInfoModal);
