import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import ReactNotifications from "react-notifications-component";

import { isAuthenticated } from "./auth/isAuth";

import "./styles/commonStyles.css";

import SignUp from "./views/SignUp";
import ContinueToDashboard from "./views/ContinueToDashboard";
import Login from "./views/Login";
import GetStarted from "./views/GetStarted";
import ManageOrders from "./views/ManageOrders";
import ManageMenu from "./views/ManageMenu";
import Billing from "./views/Billing";
import Analytics from "./views/Analytics";
import RestInfo from "./views/RestInfo";
import Website from "./views/Website";
import Payment from "./views/Payment";
import SelectPlan from "./views/SelectPlan";
import ConfirmSubs from "./views/ConfirmSubs";
import Order from "./views/Order";
import Discounts from "./views/Discounts";
import ChangePwd from "./views/ChangePwd";
import PageNotFound from "./views/PageNotFound";
import ManageAddOns from "./views/ManageAddOns";
import Integrations from "./views/Integrations";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// create active user route for customers
const ActiveUser = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() &&
      isAuthenticated().providerToken !== "" &&
      localStorage.getItem("listing") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

function App() {
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <ReactNotifications />

      <Router>
        <Switch>
          <Route path="/sign-up" exact component={SignUp} />
          <Route
            path="/continue-to-dashboard"
            exact
            component={ContinueToDashboard}
          />
          <Route path="/" exact component={Login} />
          <ActiveUser path="/get-started" exact component={GetStarted} />
          <ActiveUser path="/manage-orders" exact component={ManageOrders} />
          <ActiveUser path="/manage-menu" exact component={ManageMenu} />
          <ActiveUser path="/manage-addons" exact component={ManageAddOns} />
          <ActiveUser path="/billing" exact component={Billing} />
          <ActiveUser path="/analytics" exact component={Analytics} />
          <ActiveUser path="/integrations" exact component={Integrations} />
          <ActiveUser
            path="/settings/restaurant-info"
            exact
            component={RestInfo}
          />
          <ActiveUser path="/settings/website-info" exact component={Website} />
          <ActiveUser path="/settings/payment-info" exact component={Payment} />
          <ActiveUser
            path="/settings/payment-info/select-subscription-plan"
            exact
            component={SelectPlan}
          />
          <ActiveUser
            path="/settings/payment-info/select-subscription-plan/confirm"
            exact
            component={ConfirmSubs}
          />
          <ActiveUser path="/settings/order-info" exact component={Order} />
          <ActiveUser
            path="/promotions/discounts"
            exact
            component={Discounts}
          />
          <ActiveUser
            path="/settings/change-password"
            exact
            component={ChangePwd}
          />

          <Route path="*404" exact component={PageNotFound} />
          <Redirect to="404" />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
