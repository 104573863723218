import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Lines } from "react-preloaders";

import Breadcrumb from "../../Breadcrumb";
import SecTitle from "../SecTitle";

import { isAuthenticated } from "../../../auth/isAuth";

export default class SelectSubPlan extends Component {
  state = {
    listingId: "",
    planType: "",
    planPrice: "",
    currencySymbol: "",

    plans: [],

    // pre loading
    preLoading: true,

    // redirect
    redirectToConfirm: false,
    redirectToPayment: false,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          planType: res.data[0].subscription
            ? res.data[0].subscription.planType
            : "",
          planPrice: res.data[0].subscription
            ? res.data[0].subscription.price
            : "",
          currencySymbol: res.data[0].region.country.currencySymbol,
        });

        this.getPricing(res.data[0].region.country.countryCodeShort);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ preLoading: false });
      });
  };

  getPricing = (country) => {
    // get pricing info
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/pricing?countryCode=${country}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          plans: res.data,
          preLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ preLoading: false });
      });
  };

  handlePlan = (plan) => {
    this.setState({ plan: plan, redirectToConfirm: true });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#fff"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            {this.state.redirectToConfirm && (
              <Redirect
                to={`/settings/payment-info/select-subscription-plan/confirm?plan=${this.state.plan}`}
              />
            )}

            {this.state.redirectToPayment && (
              <Redirect to="/settings/payment-info" />
            )}
            <div className="settings-container">
              <Breadcrumb
                mainPage="Settings"
                subPage="Payment and Payout"
                secondSub="Select Subscription Plan"
              />

              <div className="sec">
                <SecTitle
                  title="Select Subscription Plan"
                  titleDesc="Select the plan that suits your needs best. You can also contact
            our support team to help you decide."
                />
                <div className="row m-0 content-container p-0">
                  <div className="col px-0 py-3">
                    <div className="subscrip-plan">
                      {this.state.plans.map((plan, i) => (
                        <div
                          className="sel-plan"
                          key={i}
                          style={
                            this.state.planType === plan.plan
                              ? { background: "#e3f2fd47" }
                              : {}
                          }
                        >
                          <p className="title">{plan.name}</p>
                          <p className="price">
                            <span className="currency">
                              {this.state.currencySymbol}{" "}
                            </span>
                            <span className="amt">{plan.price}</span>
                            <span className="period"> /month</span>
                          </p>
                          {this.state.planType === plan.plan ? (
                            <span
                              className="selected-plan"
                              style={{ background: "#448aff80" }}
                            >
                              Selected
                            </span>
                          ) : (
                            <button
                              type="button"
                              style={{ textDecoration: "none" }}
                              onClick={() => this.handlePlan(plan.plan)}
                            >
                              {this.state.loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : null}

                              {this.state.loading ? "" : "Select"}
                            </button>
                          )}

                          <p className="special-price">
                            {parseInt(this.state.planPrice) !== plan.price &&
                              this.state.planType === plan.plan &&
                              "Special pricing applied"}
                          </p>

                          {plan.plan === "small" ? (
                            <React.Fragment>
                              <p className="main-desc">
                                All the features for home based/ takeout
                                businesses, including online menu and a website
                                with custom branding.
                              </p>
                              <p className="sub-title">StoreFront</p>
                              <p className="desc">Unlimited menu items</p>
                              <p className="desc">Branded and themed website</p>

                              <p className="sub-title">Ordering</p>
                              <p className="desc">Delivery/Takeout features</p>
                              <p className="desc"></p>

                              <p className="sub-title">Payments</p>
                              <p className="desc">5% card processing fee</p>
                              <p className="desc">
                                Upto {this.state.currencySymbol} {plan.orderCap}{" "}
                                orders per month
                              </p>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <p className="main-desc">
                                All the basics for small to mid sized
                                restaurants or cafes, including online menu and
                                a website with custom branding.
                              </p>
                              <p className="sub-title">StoreFront</p>
                              <p className="desc">Unlimited menu items</p>
                              <p className="desc">Branded and themed website</p>

                              <p className="sub-title">Ordering</p>
                              <p className="desc">Delivery/Takeout features</p>
                              <p className="desc">QR Ordering for dine-in</p>

                              <p className="sub-title">Payments</p>
                              <p className="desc">
                                2.99% payment processing fee
                              </p>
                              <p className="desc">Unlimited orders per month</p>
                            </React.Fragment>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
