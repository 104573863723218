import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReactTooltip from "react-tooltip";

import "../../styles/getStarted.css";

import close from "../../images/close-modal.png";

import { isAuthenticated } from "../../auth/isAuth";

export default class SelectPlan extends Component {
  state = {
    listingId: "",

    // loading
    loading: false,

    // redirects
    redirectToSelectSubPlan: false,
    redirectToManageOrders: false,
  };

  componentDidMount = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
        });
      })
      .catch((err) => {
        console.log(err);

        this.setState({ loadingCancel: false });
      });
  };

  handlePublish = () => {
    // set loading
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/publish`,
        {},
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          })
          .then((res) => {
            if (res.data.length > 0) {
              document.getElementById("close-publish-modal").click();
              localStorage.setItem("listing", JSON.stringify(res.data[0]));
              this.setState({ loading: false, redirectToManageOrders: true });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="container-fluid to-do-select-plan"
          style={{ background: "#283593" }}
        >
          {this.state.redirectToSelectSubPlan && (
            <Redirect to="/settings/payment-info/select-subscription-plan" />
          )}

          {this.state.redirectToManageOrders && (
            <Redirect to="/manage-orders" />
          )}

          <div className="settings-container px-2  mt-0">
            <div className="row m-0 select-plan-div px-0 p-md-3">
              <div className="col-12 col-sm-8 p-0">
                <p className="sec-title">
                  {this.props.canPublish
                    ? "Publish Your Restaurant"
                    : "Select a Plan"}
                </p>
                <p className="sec-title-desc">
                  {this.props.canPublish
                    ? this.props.isPublishDisable
                      ? "Now you can publish your restaurent for going live"
                      : "Please complete all incomplete settings before going live"
                    : "You need to select a subscription plan to publish your listing and start accepting orders."}
                </p>
              </div>
              <div className="col-12 col-sm-4 p-0">
                {this.props.canPublish ? (
                  !this.props.isPublishDisable ? (
                    <p
                      type="button"
                      className="disabled-publish-btn text-center"
                      data-tip="Please complete all incomplete settings before going live"
                      disabled
                    >
                      Publish
                    </p>
                  ) : (
                    <button
                      type="button"
                      className="publish-btn"
                      data-toggle="modal"
                      data-target="#publish-modal"
                    >
                      Publish
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className="publish-btn"
                    onClick={() =>
                      this.setState({ redirectToSelectSubPlan: true })
                    }
                  >
                    Select Plan
                  </button>
                )}
              </div>

              <ReactTooltip className="tooltip-cus" />
            </div>
          </div>
        </div>

        <div
          className="modal fade add-cost-com"
          id="publish-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="publish-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="publish-modal"
                  style={{ fontSize: 22, fontWeight: "normal" }}
                >
                  Publish Your Restaurant?
                </h5>

                <img
                  src={close}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-publish-modal"
                  onClick={this.cancelDelete}
                  alt="close"
                />
              </div>
              <div className="modal-body">
                <div className="row m-0 ">
                  <div className="col-md p-0">
                    <p>
                      Are you ready to publish your website now? You can edit
                      content and graphics later here.{" "}
                    </p>
                  </div>
                </div>
                <div className="row mt-4 m-0 ">
                  <div className="col-md p-0">
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={this.handlePublish}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Yes"}
                    </button>
                    <button
                      type="button"
                      className="btn setting-cancel-btn float-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
