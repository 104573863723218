import React, { Component } from "react";

import "../../styles/getStarted.css";

export default class CompleteInfo extends Component {
  render() {
    return (
      <div
        className="container-fluid to-do-select-plan"
        style={{ background: "#283593", zIndex: 999 }}
      >
        <div className="settings-container px-2  mt-0">
          <div className="row m-0 select-plan-div px-0  py-md-3  pl-md-0 pr-md-3">
            <div className="col-12 p-0">
              <p className="sec-title text-center">{this.props.title}</p>
              <p className="sec-title-desc  text-center">{this.props.desc}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
