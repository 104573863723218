import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import printHTML from "print-mini-html";

import SecTitle from "../SecTitle";

import close from "../../../images/close-modal.png";
import localCard from "../../../images/local-card.png";
import cash from "../../../images/cash.png";
import checkedPay from "../../../images/checked-pay.png";
import uncheckedPay from "../../../images/unchecked-pay.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail, deleted } from "../../../methods/Alert";

export default class DineIn extends Component {
  state = {
    listingId: "",
    name: "",
    currency: "",
    isEnable: false,
    minDineinOrderVal: "0.00",

    // show - hide
    hasPlan: false,

    // errors
    minDineinOrderValError: "",

    // add cost components
    typeOfCharge: "Percentage",
    value: 0,
    card: false,
    cash: false,
    desc: "",
    extraCosts: [],
    showPerMark: true,
    delDineAddId: "",

    // errors
    valueError: "",
    payMethodError: "",
    descError: "",
    payAtCounterTextError: "",

    // add table
    tableName: "",
    tables: [],

    isPayAtCounter: false,
    payAtCounterText: "Pay at Outlet",
    phoneNumberRequired: true,

    // errors
    tableNameError: "",

    // QR code
    tabName: "",

    // show - hide
    status: "",

    // loading
    loading: false,
    loadingCancel: false,
    loadingCostCom: false,
    loadingAddTable: false,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          name: res.data[0].name,
          currency: res.data[0].region.country.currencySymbol,
          isEnable: res.data[0].deliveryDetail.dineInSettings.enabled,
          minDineinOrderVal:
            res.data[0].deliveryDetail.dineInSettings.minimumOrder,
          extraCosts: res.data[0].deliveryDetail.dineInSettings.extraCosts,
          isPayAtCounter: res.data[0].deliveryDetail.dineInSettings.payAtCounterEnabled,
          payAtCounterText: res.data[0].deliveryDetail.dineInSettings.payAtCounterText,
          phoneNumberRequired: res.data[0].deliveryDetail.dineInSettings.phoneNumberRequired,
          tables: res.data[0].deliveryDetail.dineInSettings.tables,
          hasPlan: res.data[0].subscription ? true : false,
          status: res.data[0].status,
          loadingCancel: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false });
      });
  };

  // get input values
  handleChange = (e) => {
    // if typeOfCharge
    if (e.target.name === "typeOfCharge") {
      if (e.target.value === "Percentage") {
        this.setState({ showPerMark: true });
      } else {
        this.setState({ showPerMark: false });
      }
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  handleAvailability = (e) => {
    this.setState({ isEnable: e.target.checked });
  };

  // validate
  validateCostCom = () => {
    let valueError = "";
    let payMethodError = "";
    let descError = "";

    if (this.state.typeOfCharge === "Percentage" && this.state.value === "") {
      valueError = "Value is required";
    } else if (this.state.typeOfCharge !== "Percentage" && this.state.value === "") {
      valueError = "Value is required";
    } else if (this.state.typeOfCharge !== "Percentage" && this.state.value <= 0) {
      valueError = "Invalid value";
    }

    if (this.state.card === false && this.state.cash === false) {
      payMethodError = "At least one is required";
    }

    if (this.state.desc === "") {
      descError = "Description of charge is required";
    }

    if (valueError || payMethodError || descError) {
      this.setState({
        valueError,
        payMethodError,
        descError,
      });
      return false;
    }

    return true;
  };

  AddCostCom = () => {
    if (this.validateCostCom()) {
      this.setState({
        loadingCostCom: true,
        valueError: "",
        payMethodError: "",
        descError: "",
      });

      if (this.state.typeOfCharge === "Percentage") {
        var obj = {
          description: this.state.desc,
          isPercentage: this.state.typeOfCharge === "Percentage" ? true : false,
          percentage:
            this.state.typeOfCharge === "Percentage"
              ? parseFloat(this.state.value)
              : 0,
          validForCash: this.state.cash,
          validForCard: this.state.card,
          type: "dine_in",
        };
      } else {
        var obj = {
          description: this.state.desc,
          isPercentage: this.state.typeOfCharge === "Percentage" ? true : false,
          value:
            this.state.typeOfCharge === "Value"
              ? parseFloat(this.state.value)
              : 0,

          validForCash: this.state.cash,
          validForCard: this.state.card,
          type: "dine_in",
        };
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/cost_components`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            loadingCostCom: false,
            typeOfCharge: "Percentage",
            value: 0,
            card: false,
            cash: false,
            desc: "",
            showPerMark: true,
          });

          success();

          this.getListing();

          document.getElementById("add-cost-com-dinein").click();
        })
        .catch((err) => {
          this.setState({ loadingCostCom: false });
          fail();
        });
    }
  };

  openDelModal = (id) => {
    this.setState({ delDineAddId: id });

    document.getElementById("delete-dine-hidden-btn").click();
  };

  delCostCom = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/cost_components/${this.state.delDineAddId}`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        document.getElementById("close-dine-del-modal").click();

        this.setState({ loading: false });
        deleted();

        this.getListing();
      })
      .catch((err) => {
        fail();
      });
  };

  cancelDelete = () => {
    this.setState({ delDineAddId: "" });
    document.getElementById("close-dine-del-modal").click();
  };

  CancelAddingCom = () => {
    this.setState(
      {
        // add cost components
        typeOfCharge: "Percentage",
        value: 0,
        card: false,
        cash: false,
        desc: "",
        showPerMark: true,

        // errors
        valueError: "",
        payMethodError: "",
        descError: "",
      },
      () => {
        document.getElementById("add-cost-com-dinein").click();
      }
    );
  };

  // validate
  validateAddTable = () => {
    let tableNameError = "";

    if (this.state.tableName === "") {
      tableNameError = "Table name is required";
    }

    if (tableNameError) {
      this.setState({
        tableNameError,
      });
      return false;
    }

    return true;
  };

  AddTable = () => {
    if (this.validateAddTable()) {
      this.setState({
        loadingAddTable: true,
        tableNameError: "",
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/tables`,
          { name: this.state.tableName },
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            loadingAddTable: false,
            tableName: "",
          });

          success();
          this.getListing();

          document.getElementById("add-table").click();
        })
        .catch((err) => {
          this.setState({ loadingAddTable: false });
          fail();
        });
    }
  };

  delTable = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/tables/${id}`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });

        deleted();

        this.getListing();
      })
      .catch((err) => {
        fail();
      });
  };

  cancelAddingTable = () => {
    this.setState(
      {
        tableName: "",
        tableNameError: "",
      },
      () => {
        document.getElementById("add-table").click();
      }
    );
  };

  getQrCode = (id, tabName) => {
    this.setState({ tabName: tabName });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/tables/${id}/qrcode`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        var url = URL.createObjectURL(res.data);
        var img = document.getElementById("img-qr-code");
        img.src = url;
      })
      .catch((err) => console.log(err));
  };

  // validate
  validate = () => {
    let minDineinOrderValError = "";
    let payAtCounterTextError = "";

    if (this.state.minDineinOrderVal === "") {
      minDineinOrderValError = "Minimum pickup order value is required";
    }

    if (this.state.isPayAtCounter && this.state.payAtCounterText === "") {
      payAtCounterTextError = "Pay at Counter Text is required";
    }

    if (minDineinOrderValError || payAtCounterTextError) {
      this.setState({
        minDineinOrderValError,
        payAtCounterTextError
      });
      return false;
    }

    return true;
  };

  handlePayAtCounter = (e) => {
    this.setState({ isPayAtCounter: e.target.checked });
  };

  handlePhoneNumberRequired = (e) => {
    this.setState({ phoneNumberRequired: e.target.checked });
  };

  handleSave = () => {
    if (this.validate()) {
      this.setState({ minDineinOrderValError: "", loading: true });

      const pickupData = {
        enabled: this.state.isEnable,
        minimumOrder: parseFloat(this.state.minDineinOrderVal),
        payAtCounterEnabled: this.state.isPayAtCounter,
        payAtCounterText: this.state.payAtCounterText,
        phoneNumberRequired: this.state.phoneNumberRequired,
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/dinein_settings`,
          pickupData,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });

          success();

          window.location.reload();
        })
        .catch((err) => {
          this.setState({ loading: false });
          fail();
        });
    }
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true, minDineinOrderValError: "" });
    this.getListing();
  };

  print = () => {
    printHTML(document.querySelector("#qr-print").cloneNode(true));
  };

  render() {
    return (
      <div className="settings-container" style={{ marginBottom: 150 }}>
        <div className="sec">
          <SecTitle
            title="Dine-In Settings"
            titleDesc="Set dine-in information"
          />
          <div className="row m-0 content-container pb-0">
            <div className="col p-0">
              <div className="enable-toggle">
                <lable className="label">Dine-In &nbsp; </lable>
                <div className="custom-control custom-switch m-0">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="dinein-enable"
                    onChange={this.handleAvailability}
                    checked={this.state.isEnable}
                    disabled={!this.state.hasPlan ? true : false}
                  />
                  <label
                    className="custom-control-label toggle-btn"
                    for="dinein-enable"
                  ></label>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-0 content-container">
            <div className="col-md col1 p-0">
              <form className="setting-form order-forms d-inline-block w-100">
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field">
                    <label htmlFor="minDineinOrderVal" className="title ml-0">
                      Minimum Dine-in Order Value
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text input-prepends">
                          {this.state.currency}
                        </div>
                      </div>
                      <input
                        type="number"
                        className={classnames("form-control", {
                          "is-invalid": this.state.minDineinOrderValError,
                        })}
                        id="minDineinOrderVal"
                        name="minDineinOrderVal"
                        value={this.state.minDineinOrderVal}
                        onChange={this.handleChange}
                        autoComplete="off"
                        disabled={!this.state.hasPlan ? true : false}
                      />
                    </div>
                    <div className="invalid-feedback">
                      {this.state.minDineinOrderValError}
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md col2 p-0"></div>
          </div>

          <div className="row m-0 content-container">
            <div className="col-md col1 p-0">
              <form className="setting-form order-forms d-inline-block w-100">
                <div className="form-row mb-3">
                  <div className="form-group mb-0 p-0 input-field">
                    <label className="title ml-0 mb-0">Extra Costs</label>
                    <span
                      className="change-settings-btn"
                      data-toggle="modal"
                      data-target={
                        !this.state.hasPlan ? "" : "#add-cost-com-dinein"
                      }
                    >
                      Add Cost Component
                    </span>
                    <p className="inform">
                      Extra cost components that are added on top of the total
                      order cost. Eg: Service charges or Government taxes.
                    </p>
                  </div>
                </div>
                {this.state.extraCosts.map((com, i) => (
                  <div className="form-row mt-0" key={i}>
                    <div className="form-group extra-cost-col1 pl-0">
                      <label className="title ml-0 mb-0">
                        {com.isPercentage === true
                          ? `${com.percentage}%`
                          : `${this.state.currency} ${com.value.toFixed(
                              2
                            )}`}{" "}
                        - {com.description}
                      </label>
                    </div>
                    <div className="form-group extra-cost-col2 pl-0">
                      <span
                        className="com-del-btn"
                        onClick={() => this.openDelModal(com.id)}
                      >
                        Delete
                      </span>
                    </div>
                  </div>
                ))}
              </form>
            </div>

            <div className="col-md col2 p-0"></div>
          </div>

          <div className="row m-0 content-container">
            <div className="col-md col1 p-0">
              <form className="setting-form order-forms d-inline-block w-100">
                <div className="form-row mb-3">
                  <div className="form-group mb-0 p-0 input-field">
                    <label className="title ml-0 mb-0">Table QR Codes</label>
                    <span
                      className="change-settings-btn"
                      data-toggle="modal"
                      data-target={!this.state.hasPlan ? "" : "#add-table"}
                    >
                      Add Table
                    </span>
                    <p className="inform">
                      Table numbers and QR codes for in-venue mobile ordering
                    </p>
                  </div>
                </div>

                {this.state.tables.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})).map((table, i) => (
                  <div className="form-row mt-0" key={i}>
                    <div className="form-group table-QR-col1 pl-0">
                      <label className="title ml-0 mb-0">
                        Table {table.name}
                      </label>
                    </div>
                    <div className="form-group table-QR-col2 pl-0">
                      <span
                        className="com-del-btn"
                        onClick={() => this.delTable(table.id)}
                      >
                        Delete
                      </span>
                      <span
                        className="qr-btn"
                        data-toggle="modal"
                        data-target="#qr-code"
                        onClick={() => this.getQrCode(table.id, table.name)}
                      >
                        QR Code
                      </span>
                    </div>
                  </div>
                ))}
              </form>
            </div>

            <div className="col-md col2 p-0"></div>
          </div>

          <div className="row m-0 content-container">
            <div>
              <div className="pay-at-counter-wrapper">
                <label className="title ml-0 mb-0">Enable Pay At Counter</label>
                <div className="custom-control custom-switch m-0">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pay-at-counter"
                    onChange={this.handlePayAtCounter}
                    checked={this.state.isPayAtCounter}
                  />
                  <label
                    className="custom-control-label toggle-btn"
                    for="pay-at-counter"
                  ></label>
                </div>
              </div>

              <div className="form-group mb-0 p-0 input-field" style={{width: "100%"}}>
                <label htmlFor="name" className="title ml-0">
                  Pay at counter button text
                </label>
                <input
                  type="text"
                  className={classnames("form-control", {
                    "is-invalid": this.state.payAtCounterTextError,
                  })}
                  id="payAtCounterText"
                  name="payAtCounterText"
                  value={this.state.payAtCounterText}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength={20}
                  disabled={!this.state.isPayAtCounter}
                />
                <div className="invalid-feedback">
                  {this.state.payAtCounterTextError}
                </div>
              </div>
            </div>
          </div>

          <div className="row m-0 content-container">
            <div className="pay-at-counter-wrapper">
              <label className="title ml-0 mb-0">Phone number required</label>
              <div className="custom-control custom-switch m-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="phone-number-required"
                  onChange={this.handlePhoneNumberRequired}
                  checked={this.state.phoneNumberRequired}
                />
                <label
                  className="custom-control-label toggle-btn"
                  for="phone-number-required"
                ></label>
              </div>
            </div>         
          </div>

          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <button
                type="button"
                id="location-setting"
                className="btn setting-save-btn float-right"
                onClick={this.handleSave}
                disabled={!this.state.hasPlan ? true : false}
              >
                {this.state.loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    style={{ margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loading ? "" : "Save"}
              </button>
              <button
                type="button"
                className="btn setting-cancel-btn float-right"
                onClick={this.handleCancel}
                disabled={!this.state.hasPlan ? true : false}
              >
                {this.state.loadingCancel ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "#4f4f4f", margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loadingCancel ? "" : "Cancel"}
              </button>
            </div>
          </div>

          <div
            className="modal fade add-cost-com"
            id="add-cost-com-dinein"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="add-cost-com"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="add-cost-com"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Add Cost Component
                  </h5>

                  <img
                    src={close}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-row">
                      <div className="form-group col input-field">
                        <label htmlFor="typeOfCharge" className="title ml-0">
                          Type of Charge
                        </label>
                        <select
                          id="typeOfCharge"
                          className="form-control"
                          name="typeOfCharge"
                          value={this.state.typeOfCharge}
                          onChange={this.handleChange}
                        >
                          <option>Percentage</option>
                          <option>Value</option>
                        </select>
                      </div>
                      <div className="form-group col">
                        <label htmlFor="value" className="title ml-0">
                          Value
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            className={classnames("form-control", {
                              "is-invalid": this.state.valueError,
                            })}
                            id="value"
                            name="value"
                            value={this.state.value}
                            onChange={this.handleChange}
                          />
                          {this.state.showPerMark ? (
                            <div className="input-group-append">
                              <div className="input-group-text input-prepends">
                                %
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="invalid-feedback">
                          {this.state.valueError}
                        </div>
                      </div>
                    </div>
                    <div className="form-row" style={{ paddingLeft: 5 }}>
                      <div className="form-group mb-0 p-0 input-field">
                        <label className="title ml-0 mb-2">
                          Applicable for
                        </label>
                        <p className="payment-methods">
                          {this.state.card ? (
                            <img
                              src={checkedPay}
                              alt="credit cards"
                              onClick={() =>
                                this.setState({ card: !this.state.card })
                              }
                            />
                          ) : (
                            <img
                              src={uncheckedPay}
                              alt="card credit cards"
                              onClick={() =>
                                this.setState({ card: !this.state.card })
                              }
                            />
                          )}
                          <img src={localCard} alt="card credit cards" />
                          Valid for Card
                        </p>

                        <p className="payment-methods">
                          {this.state.cash ? (
                            <img
                              src={checkedPay}
                              alt="cash"
                              onClick={() =>
                                this.setState({ cash: !this.state.cash })
                              }
                            />
                          ) : (
                            <img
                              src={uncheckedPay}
                              alt="cash"
                              onClick={() =>
                                this.setState({ cash: !this.state.cash })
                              }
                            />
                          )}
                          <img src={cash} alt="local credit cards" />
                          Valid for Cash
                        </p>
                        <p
                          className="invalid-feedback"
                          style={{ margin: "-5px 0 0 0" }}
                        >
                          {this.state.payMethodError}
                        </p>
                      </div>
                    </div>
                    <div className="form-row" style={{ marginTop: 12 }}>
                      <div className="form-group col w-100">
                        <label htmlFor="desc" className="title ml-0">
                          Description
                        </label>
                        <textarea
                          className={classnames("form-control", {
                            "is-invalid": this.state.descError,
                          })}
                          id="desc"
                          rows="3"
                          name="desc"
                          value={this.state.desc}
                          onChange={this.handleChange}
                        ></textarea>
                        <div className="invalid-feedback">
                          {this.state.descError}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row mt-4 m-0 ">
                    <div className="col-md p-0">
                      <button
                        type="button"
                        id="location-setting"
                        className="btn setting-save-btn float-right"
                        onClick={this.AddCostCom}
                      >
                        {this.state.loadingCostCom ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                            style={{ margin: 0 }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loadingCostCom ? "" : "Save"}
                      </button>
                      <button
                        type="button"
                        className="btn setting-cancel-btn float-right"
                        onClick={this.CancelAddingCom}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade add-cost-com"
            id="add-table"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="add-cost-com"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="add-cost-com"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Add Table
                  </h5>

                  <img
                    src={close}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-row">
                      <div className="form-group col">
                        <label htmlFor="tableName" className="title ml-0">
                          Table Name
                        </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text input-prepends">
                              Table
                            </div>
                          </div>
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.tableNameError,
                            })}
                            id="tableName"
                            name="tableName"
                            value={this.state.tableName}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="invalid-feedback">
                          {this.state.tableNameError}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row mt-4 m-0 ">
                    <div className="col-md p-0">
                      <button
                        type="button"
                        id="location-setting"
                        className="btn setting-save-btn float-right"
                        onClick={this.AddTable}
                      >
                        {this.state.loadingAddTable ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                            style={{ margin: 0 }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loadingAddTable ? "" : "Save"}
                      </button>
                      <button
                        type="button"
                        className="btn setting-cancel-btn float-right"
                        onClick={this.cancelAddingTable}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade qr-code"
            id="qr-code"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="qr-code"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-scrollable">
              <div
                className="modal-content"
                style={{ background: "transparent", border: 0 }}
              >
                <div
                  className="modal-body qr-code p-0"
                  style={{ width: 320, background: "#fff" }}
                  id="qr-print"
                >
                  <div
                    className="name"
                    style={
                      JSON.parse(localStorage.getItem("listing")).branding
                        ? JSON.parse(localStorage.getItem("listing")).branding
                            .colorAccent
                          ? {
                              background: JSON.parse(
                                localStorage.getItem("listing")
                              ).branding.colorAccent,
                            }
                          : { background: "#151b26" }
                        : { background: "#151b26" }
                    }
                  >
                    {this.state.name}
                  </div>
                  <p className="title">Scan to Order</p>
                  <img
                    id="img-qr-code"
                    style={
                      JSON.parse(localStorage.getItem("listing")).branding
                        ? JSON.parse(localStorage.getItem("listing")).branding
                            .colorAccent
                          ? {
                              border: `2px solid ${
                                JSON.parse(localStorage.getItem("listing"))
                                  .branding.colorAccent
                              }`,
                            }
                          : { border: "2px solid #151b26" }
                        : { border: "2px solid #151b26" }
                    }
                  />
                  <p className="sub-heading">
                    Scan this QR code using your phone and go to link to start
                    ordering
                  </p>
                  <p className="table-name">Table {this.state.tabName}</p>

                  <div
                    className="footer"
                    style={
                      JSON.parse(localStorage.getItem("listing")).branding
                        ? JSON.parse(localStorage.getItem("listing")).branding
                            .colorFooter
                          ? {
                              background: JSON.parse(
                                localStorage.getItem("listing")
                              ).branding.colorFooter,
                            }
                          : { background: "#333333" }
                        : { background: "#333333" }
                    }
                  >
                    <p className="powered-by">Powered by</p>
                    <p className="spritzer">Spritzer</p>
                  </div>
                </div>
                <div>
                  <div
                    class="btn-group print-qr-btn"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      class="btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{ color: "#828282" }}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn"
                      onClick={this.print}
                      style={{ color: "#006DEE" }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            data-toggle="modal"
            data-target="#delete-dine-modal"
            id="delete-dine-hidden-btn"
            hidden
          ></button>

          <div
            className="modal fade add-cost-com"
            id="delete-dine-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="delete-dine-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="delete-dine-modal"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Are you sure?
                  </h5>

                  <img
                    src={close}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-dine-del-modal"
                    onClick={this.cancelDelete}
                  />
                </div>
                <div className="modal-body">
                  <div className="row m-0 ">
                    <div className="col-md p-0">
                      <p>You won't be able to revert this!</p>
                    </div>
                  </div>
                  <div className="row mt-4 m-0 ">
                    <div className="col-md p-0">
                      <button
                        type="button"
                        id="location-setting"
                        className="btn setting-save-btn float-right"
                        onClick={this.delCostCom}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loading ? "" : "Yes"}
                      </button>
                      <button
                        type="button"
                        className="btn setting-cancel-btn float-right"
                        onClick={this.cancelDelete}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
