import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import CreditCardInput from "react-credit-card-input";

import SecTitle from "../SecTitle";

import close from "../../../images/close-modal.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class Plan extends Component {
  state = {
    listingId: "",
    maskedCardNumber: "",
    paymentId: "",

    name: "",
    cardNumber: "",
    expiry: "",
    cvc: "",

    planType: "",
    planPrice: "",

    // errors
    nameError: "",
    invalidCardError: "",

    // loading
    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    this.getListing();
    this.getPaymentSources();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          planType: res.data[0].subscription.planType,
          planPrice: res.data[0].subscription.price,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false });
      });
  };

  getPaymentSources = () => {
    // get payment sources
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/paymentsources/listing_subscription`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          maskedCardNumber: res.data.maskedCardNumber,
          paymentId: res.data.id,
          loadingCancel: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false });
      });
  };

  // get input values
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCardNumberChange = (e) => {
    this.setState({ cardNumber: e.target.value });
  };

  handleCardExpiryChange = (e) => {
    this.setState({ expiry: e.target.value });
  };

  handleCardCVCChange = (e) => {
    this.setState({ cvc: e.target.value });
  };

  // validate
  validate = () => {
    let nameError = "";
    let invalidCardError = "";

    if (this.state.name === "") {
      nameError = "Name is required";
    }

    if (this.state.cardNumber === "") {
      invalidCardError = "Please type a valid card";
    }

    if (this.state.expiry === "") {
      invalidCardError = "Please type a valid card";
    }

    if (this.state.cvc === "") {
      invalidCardError = "Please type a valid card";
    }

    if (invalidCardError || nameError) {
      this.setState({
        invalidCardError,
        nameError,
      });
      return false;
    }

    return true;
  };

  handleSave = () => {
    if (this.validate()) {
      this.setState({ invalidCardError: "", loading: true });

      const cardExpMonth = this.state.expiry.split("/")[0];
      const cardExpYear = this.state.expiry.split("/")[1];

      const data = {
        cardNumber: this.state.cardNumber,
        cardExpMonth: cardExpMonth.trim(),
        cardExpYear: cardExpYear.trim(),
        cardCvc: this.state.cvc,
        forListingSubscription: true,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, data, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          this.setState({
            loading: false,
            cardNumber: "",
            expiry: "",
            cvc: "",
          });

          document.getElementById("cancel-update-modal").click();

          this.getPaymentSources();

          success();
        })
        .catch((err) => {
          if (err.response.data.code === 1024) {
            fail("Please submit a valid card Error code: 1024");
          } else {
            fail(err.response && err.response.status ? `Error code: ${err.response.status}` : null);
          }

          this.setState({ loading: false });
        });
    }
  };

  handleCancelUpdate = () => {
    document.getElementById("cancel-update-modal").click();
  };

  handlePlan = (type) => {
    // set loading
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/subscriptions`,
        `plan=${type}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    this.getListing();
  };

  render() {
    return (
      <div className="settings-container" style={{ marginBottom: 150 }}>
        <div className="sec">
          <SecTitle
            title="Plan Settings"
            titleDesc="Select your subscription plan and enter payment details"
          />
          <div className="row m-0 content-container">
            <div className="col p-0">
              <form className="setting-form">
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field">
                    <label htmlFor="description" className="title ml-0 mb-0">
                      Subscription Payment Method
                    </label>
                    <p className="inform">
                      Credit card used to make subscription payments{" "}
                      <span
                        className="action"
                        data-toggle="modal"
                        data-target="#sub-pay"
                      >
                        Update
                      </span>
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      value={`************ ${this.state.maskedCardNumber}`}
                      autoComplete="off"
                      disabled
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field">
                    <label htmlFor="name" className="title ml-0">
                      Subscription Plan{" "}
                      <span className="action" style={{ lineHeight: "18px" }}>
                        <Link
                          to="/settings/payment-info/select-subscription-plan"
                          style={{ textDecoration: "none" }}
                        >
                          Change
                        </Link>
                      </span>
                    </label>

                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": this.state.phoneError,
                      })}
                      value={`${this.state.planType} - ${this.state.planPrice}/month`}
                      autoComplete="off"
                      disabled
                      style={{ textTransform: "capitalize" }}
                    />
                    <div className="invalid-feedback">
                      {this.state.phoneError}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade add-cost-com sub-update-plan"
          id="sub-pay"
          tabindex="-1"
          role="dialog"
          aria-labelledby="sub-pay"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="add-cost-com"
                  style={{ fontSize: 22, fontWeight: "normal" }}
                >
                  Subscription Payment Method
                </h5>

                <img
                  src={close}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="cancel-update-modal"
                  alt="close"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-row m-0">
                    <div className="form-group mb-0 p-0 input-field w-100">
                      <label htmlFor="description" className="title ml-0 mb-2">
                        Your Name
                      </label>
                      <input
                        id="card-holder-name"
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.state.nameError,
                        })}
                        placeholder="Card Holder Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">
                        {this.state.nameError}
                      </div>
                    </div>
                  </div>
                  <div className="form-row mx-0 mt-2">
                    <div className="form-group mb-0 p-0 input-field w-100">
                      <label htmlFor="bankCode" className="title ml-0">
                        Card Details
                      </label>
                      <CreditCardInput
                        cardNumberInputProps={{
                          value: this.state.cardNumber,
                          onChange: this.handleCardNumberChange,
                        }}
                        cardExpiryInputProps={{
                          value: this.state.expiry,
                          onChange: this.handleCardExpiryChange,
                        }}
                        cardCVCInputProps={{
                          value: this.state.cvc,
                          onChange: this.handleCardCVCChange,
                        }}
                        // onError={this.validate}
                        fieldClassName="input"
                        cardImageStyle={{ width: 35, height: 25 }}
                        fieldStyle={{ verticalAlign: "middle" }}
                      />
                      <p
                        style={{
                          fontSize: "0.8rem",
                          color: "#ff3860",
                          marginTop: 5,
                          marginBottom: 0,
                        }}
                      >
                        {this.state.invalidCardError}
                      </p>
                    </div>
                  </div>
                </form>

                <div className="row mt-4 m-0 ">
                  <div className="col-md p-0">
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={this.handleSave}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                          style={{ margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Save"}
                    </button>

                    <button
                      type="button"
                      className="btn setting-cancel-btn float-right"
                      onClick={this.handleCancelUpdate}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
