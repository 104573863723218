import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import logo from "../images/spritzer-logo-white.png";

import { isAuthenticated } from "../auth/isAuth";

if (isAuthenticated() && isAuthenticated().providerToken) {
  axios
    .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${
          isAuthenticated() && isAuthenticated().providerToken
        }`,
      },
    })
    .then((res) => {
      if (typeof window !== "undefined") {
        localStorage.setItem("profile", JSON.stringify(res.data));
      }
    })
    .catch((err) => console.log(err));
}

export default class Navbar extends Component {
  state = {
    redirectToLogin: false,
  };

  // logout
  logout = (e) => {
    e.preventDefault();

    if (typeof window !== "undefined") {
      Cookies.remove("auth_dash_token", { path: "", domain: ".spritzer.app" });
      localStorage.removeItem("profile");
      localStorage.removeItem("listing");
    }

    this.setState({ redirectToLogin: true });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.redirectToLogin ? <Redirect to="/" /> : null}

        <nav className="navbar sticky-top navbar-expand-lg navbar-dark py-3 px-0 business-app-navbar">
          <div className="container">
            <Link className="navbar-brand p-0" to="/">
              <img
                src={logo}
                className="company-logo"
                alt="Spritzer Business Logo"
              />
            </Link>

            <div className="ml-auto">
              {isAuthenticated() && isAuthenticated().providerToken ? (
                <div
                  className="btn-group float-right"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="user-name pr-2" style={{ fontSize: 14 }}>
                      {localStorage.getItem("profile") &&
                        JSON.parse(localStorage.getItem("profile")).name}
                    </span>
                    <i className="fas fa-user"></i>
                  </span>
                  <div
                    className="dropdown-menu dropdown-menu-right p-0"
                    style={{ zIndex: 9999, fontSize: 13, top: "122%" }}
                  >
                    <Link
                      className="dropdown-item text-dark"
                      to="#"
                      onClick={this.logout}
                      style={{ lineHeight: "30px" }}
                    >
                      <i className="fas fa-sign-out-alt"></i> &nbsp; Logout
                    </Link>
                  </div>
                </div>
              ) : (
                <Link to="/" className="nav-item">
                  <span style={{ color: "#828282", fontWeight: 300 }}>
                    Have an account? &nbsp;
                  </span>
                  Login
                </Link>
              )}
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
