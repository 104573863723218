import React from "react";
import classnames from "classnames";

const DiscountAddComp = (props) => {
  
  // TODO: make utility and move into it
  const getCurrencySymbol = (currency) => {
    return currency?.symbol || currency?.code;
  };

  return (
    <div className="form-row">
      <label className="title ml-0 mb-0 d-block w-100">{props.title}</label>

      <div className="row m-0 content-container pb-0">
        <div className="col-md col1 p-0">
          <div className="form-row">
            {!props.showHide ? (
              <span>
                {props.form.discountType === "percentage"
                  ? `${props.form.discount}%`
                  : `${getCurrencySymbol(props.currency)} ${props.form.discount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}`}
                {` for orders over ${getCurrencySymbol(props.currency)} `}
                {props.form.threshold.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </span>
            ) : (
              <>
                <div className="form-group col-md input-field mb-0">
                  <label htmlFor="threshold" className="title ml-0">
                    Order Total
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text input-prepends">{`${getCurrencySymbol(props.currency)}`}</div>
                    </div>
                    <input
                      type="number"
                      className={classnames("form-control", {
                        "is-invalid": props.form.thresholdError,
                      })}
                      id="threshold"
                      name="threshold"
                      min="0"
                      value={props.form.threshold}
                      onChange={props.handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="invalid-feedback">
                    {props.form.thresholdError}
                  </div>
                </div>

                <div className="form-group col-md input-field">
                  <label htmlFor="discountType" className="title ml-0">
                    Discount Type
                  </label>
                  <select
                    type="number"
                    className={classnames("form-control", {
                      "is-invalid": props.form.discountTypeError,
                    })}
                    id="discountType"
                    name="discountType"
                    value={props.form.discountType}
                    onChange={props.handleChange}
                  >
                    <option selected hidden>
                      -- Select --
                    </option>
                    <option value="percentage">Percentage</option>
                    <option value="value">Value</option>
                  </select>
                  <div className="invalid-feedback">
                    {props.form.discountTypeError}
                  </div>
                </div>
                <div className="form-group col-md input-field">
                  <label htmlFor="discount" className="title ml-0">
                    Discount
                  </label>
                  <div className="input-group">
                    {props.form.discountType === "value" && (
                      <div className="input-group-prepend">
                        <div className="input-group-text input-prepends">
                          {getCurrencySymbol(props.currency)}
                        </div>
                      </div>
                    )}
                    <input
                      type="number"
                      className={classnames("form-control", {
                        "is-invalid": props.form.discountError,
                      })}
                      id="discount"
                      name="discount"
                      value={props.form.discount}
                      onChange={props.handleChange}
                      autoComplete="off"
                    />
                    {props.form.discountType === "percentage" && (
                      <div className="input-group-prepend">
                        <div className="input-group-text input-prepends">%</div>
                      </div>
                    )}
                  </div>
                  <div className="invalid-feedback">
                    {props.form.discountError}
                  </div>
                </div>
              </>
            )}
            <div className="form-group col-md input-field">
              {!props.showHide ? (
                <span
                  style={delBtn}
                  onClick={() => props.deleteDiscount(props.type)}
                >
                  {props.loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-secondary"
                      role="status"
                      style={{ margin: 0 }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Delete"
                  )}
                </span>
              ) : (
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => props.saveDiscount(props.type)}
                  style={{ width: 70, marginTop: 28 }}
                >
                  {props.loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                      style={{ margin: 0 }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountAddComp;

const delBtn = {
  fontSize: "12px",
  color: "#828282",
  float: "right",
  lineHeight: "24px",
  marginLeft: "25px",
  cursor: "pointer",
};
