import axios from "axios";
import { isAuthenticated } from "../../auth/isAuth";

export const logErrors = (err) => {
  console.log(err);
  const data = {
    clientId: "web",
    response: err?.response?.data?.message ? err.response.data.message : null,
    statusCode: err?.response?.status ? err.response.status : null,
    url: err.request?.responseURL,
    additionalData: {
      agent: navigator.userAgent,
    },
  };
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/api_errors`, data, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
    },
    })
    .then((res) => {})
    .catch((err) => {
      console.log(err);
    });
};
