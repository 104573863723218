import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";

import Navbar from "../components/Navbar";
import TitleOfPage from "../components/TitleOfPage";
import Clients from "../components/Clients";

import "../styles/signUp.css";

import { isAuthenticated } from "../auth/isAuth";

// initiate state
const initialState = {
  // regions
  regions: [],

  // country, region, country code
  country: "",
  region: "",
  countryCode: "",

  // register
  name: "",
  email: "",
  password: "",
  busName: "",
  url: "",

  nameError: "",
  emailError: "",
  passwordError: "",
  busNameError: "",
  regionError: "",
  urlError: "",

  // show - hide
  showWarning: false,
  hideUserFields: false,

  // redirect
  redirectToDashboard: false,
  redirectToGetStarted: false,
  redirectToSignUp: false,
  redirectToContinue: false,

  // loading
  loading: false,
};

export default class SignUpUserDetails extends Component {
  // initial state
  state = initialState;

  componentDidMount = () => {
    if (document.cookie.includes("auth_dash_token")) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          if (typeof window !== "undefined") {
            localStorage.setItem("profile", JSON.stringify(res.data));
          }
        })
        .catch((err) => console.log(err));

      axios
        .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            localStorage.setItem("listing", JSON.stringify(res.data[0]));

            this.setState({ redirectToContinue: true });
          } else {
            this.setState({
              hideUserFields: true,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            hideUserFields: true,
            loading: false,
          });
        });
    }

    // get all regions
    axios
      .get(`${process.env.REACT_APP_API_URL}/services/regions`)
      .then((regions) => {
        // filter regions which are isAvailable === true
        const filterRegions = regions.data.filter((filter) => {
          return filter.isAvailable === true;
        });

        // sort region by Alphabetical Order
        const sortAZOrder = filterRegions.sort((a, b) => {
          return a.country.countryName.localeCompare(b.country.countryName);
        });

        this.setState({ regions: sortAZOrder });

        // get IP info
        axios
          .get(`${process.env.REACT_APP_API_URL}/services/ipinfo`, {
            headers: {
              secret: `7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS`,
            },
          })
          .then((ipInfo) => {
            console.log(ipInfo);
            // declare variables
            var country = "";
            var region = "";
            var countryCode = "";

            // filter by country code
            const countryCodeShort = this.state.regions.filter(
              (codeShort) =>
                codeShort.country.countryCodeShort === ipInfo.data.countryCode
            );

            // identify country, region and country code
            if (countryCodeShort.length > 1) {
              var regionCode = countryCodeShort.filter(
                (code) => code.regionCode === ipInfo.data.regionCode
              );

              country = regionCode[0].country.countryName;
              region = regionCode[0].regionCode;
              countryCode = regionCode[0].country.countryCodeShort;
              console.log(country, region, countryCode);
            } else if (countryCodeShort.length === 1) {
              country = countryCodeShort[0].country.countryName;
              region = countryCodeShort[0].regionCode;
              countryCode = countryCodeShort[0].country.countryCodeShort;
              console.log(country, region, countryCode);
            } else {
              country = "Australia";
              region = "VIC";
              countryCode = "AU";
            }

            this.setState({ country, region, countryCode });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // handle inputs
  handleChange = (e) => {
    if (e.target.name === "url") {
      if (!e.target.value.match(/^[a-zA-Z0-9-_]+$/)) {
        this.setState({
          [e.target.name]: e.target.value.trim(),
          urlError: "Only alphanumeric, dash and underscore are allowed",
        });
      } else {
        this.setState({ urlError: "", [e.target.name]: e.target.value.trim() });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      });
    }
  };

  // change region
  changeRegion = (e) => {
    let arr = e.target.value.split(",");

    this.setState({
      country: arr[0],
      region: arr[1],
      countryCode: arr[2],
    });
  };

  // validate inputs
  validate = () => {
    let nameError = "";
    let emailError = "";
    let passwordError = "";
    let busNameError = "";
    let regionError = "";
    let urlError = "";

    if (!this.state.hideUserFields && this.state.name === "") {
      nameError = "Name is required";
    }

    if (!this.state.hideUserFields && this.state.email === "") {
      emailError = "Email is required";
    } else if (
      !this.state.hideUserFields &&
      !this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      emailError = "Email is invalid";
    }

    if (!this.state.hideUserFields && this.state.password === "") {
      passwordError = "Password is required";
    } else if (
      (!this.state.hideUserFields && 6 > this.state.password.length) ||
      (!this.state.hideUserFields && this.state.password.length > 60)
    ) {
      passwordError = "Password length should be between 6 to 60";
    }

    if (this.state.busName === "") {
      busNameError = "Business name is required";
    }

    if (this.state.region === "") {
      regionError = "Business name is required";
    }

    if (this.state.url === "") {
      urlError = "Online restaurant name (URL) is required";
    } else if (!this.state.url.match(/^[a-zA-Z0-9-_]+$/)) {
      urlError = "Only alphanumeric, dash and underscore are allowed";
    }

    if (
      nameError ||
      emailError ||
      passwordError ||
      busNameError ||
      regionError ||
      urlError
    ) {
      this.setState({
        nameError,
        emailError,
        passwordError,
        busNameError,
        regionError,
        urlError,
      });
      return false;
    }

    return true;
  };

  // submit sign-up form
  handleSignUp = () => {
    if (this.validate()) {
      this.setState({
        nameError: "",
        emailError: "",
        passwordError: "",
        busNameError: "",
        regionError: "",
        urlError: "",
        showWarning: false,
        loading: true,
      });

      // create user data object
      const user = {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        referredBy: "",
        accountType: "email",
        isBusiness: true,
      };

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/name_availability?name=${this.state.url}`
        )
        .then((res) => {
          if (res.data.value) {
            // send post req to register an user
            axios
              .post(`${process.env.REACT_APP_API_URL}/user/register`, user)
              .then((res) => {
                // logged user to take token
                axios
                  .post(`${process.env.REACT_APP_API_URL}/user/login`, {
                    email: this.state.email,
                    password: this.state.password,
                    accountType: "email",
                    clientId: "web",
                  })
                  .then((result) => {
                    // document.cookie = `auth_dash_token=${JSON.stringify(
                    //   result.data
                    // )}`;
                    document.cookie = `auth_dash_token=${JSON.stringify(
                      result.data
                    )};domain=spritzer.app`;

                    const busData = `businessName=${this.state.busName}&storeName=${this.state.url}&regionCode=${this.state.region}`;

                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/v2/user/listings`,
                        busData,
                        {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            Authorization: `Bearer ${result.data.providerToken}`,
                          },
                        }
                      )
                      .then((res) => {
                        this.getListing();
                        this.setState({
                          redirectToGetStarted: true,
                          loading: false,
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                        this.setState({
                          loading: false,
                        });
                      });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => {
                if (err.response.data.code === 1004) {
                  this.setState({
                    emailError: "Email already exists",
                    loading: false,
                  });
                }
              });
          } else {
            this.setState({
              urlError: "Sorry, This is already used by another store",
              showWarning: true,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  };

  getListing = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        localStorage.setItem("listing", JSON.stringify(res.data[0]));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSignBusiness = () => {
    if (this.validate()) {
      this.setState({
        busNameError: "",
        regionError: "",
        urlError: "",
        showWarning: false,
        loading: true,
      });

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/name_availability?name=${this.state.url}`
        )
        .then((res) => {
          if (res.data.value) {
            const busData = `businessName=${this.state.busName}&storeName=${this.state.url}&regionCode=${this.state.region}`;

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/v2/user/listings`,
                busData,
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                  },
                }
              )
              .then((res) => {
                this.getListing();
                this.setState({
                  redirectToGetStarted: true,
                  loading: false,
                });
              })
              .catch((err) => {
                console.log(err);
                this.setState({
                  loading: false,
                });
              });
          } else {
            this.setState({
              urlError: "Sorry, This is already used by another store",
              showWarning: true,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="Sign Up | Spritzer Dashboard" />

        {this.state.redirectToDashboard ? (
          <Redirect to="/manage-orders" />
        ) : null}

        {this.state.redirectToContinue ? (
          <Redirect to="/continue-to-dashboard" />
        ) : null}

        {this.state.redirectToGetStarted ? (
          <Redirect to="/get-started" />
        ) : null}

        <Navbar />

        <div className="row m-0 sign-up-main-div">
          <div className="col px-3 py-0">
            <div className="sign-up-form-div">
              <h1 className="get-started-heading">
                {this.state.hideUserFields
                  ? `Welcome Back ${
                      JSON.parse(localStorage.getItem("profile")).name ===
                      "Business User"
                        ? ""
                        : JSON.parse(localStorage.getItem("profile")).name
                    }`
                  : "Let's get started"}
              </h1>

              <div className="sign-up-form">
                <form className="m-0 mb-4">
                  {this.state.hideUserFields ? (
                    <div className="form-row">
                      <div className="form-group w-100 mb-0 p-0">
                        <label htmlFor="busName" className="input-fields ml-0">
                          Your Account Email
                        </label>
                        <p
                          style={{
                            fontSize: 16,
                            marginBottom: 0,
                            color: "#333333",
                          }}
                        >
                          {JSON.parse(localStorage.getItem("profile")).email}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="form-row">
                        <div className="form-group w-100 mb-0 p-0">
                          <label htmlFor="name" className="input-fields ml-0">
                            Your Name
                          </label>
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.nameError,
                            })}
                            name="name"
                            id="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                            {this.state.nameError}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group w-100 mb-0 p-0">
                          <label htmlFor="email" className="input-fields ml-0">
                            Your Email
                          </label>
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.emailError,
                            })}
                            name="email"
                            id="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                            {this.state.emailError}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group w-100 mb-0 p-0">
                          <label htmlFor="pwd" className="input-fields ml-0">
                            Password
                          </label>
                          <input
                            type="password"
                            className={classnames("form-control", {
                              "is-invalid": this.state.passwordError,
                            })}
                            name="password"
                            id="pwd"
                            value={this.state.password}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                            {this.state.passwordError}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="form-row">
                    <div className="form-group w-100 mb-0 p-0">
                      <label htmlFor="busName" className="input-fields ml-0">
                        Business Name
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.state.busNameError,
                        })}
                        name="busName"
                        id="busName"
                        value={this.state.busName}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">
                        {this.state.busNameError}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group w-100 mb-0 p-0">
                      <label htmlFor="region" className="input-fields ml-0">
                        Region
                      </label>

                      <select
                        id="region"
                        className="form-control"
                        value={
                          this.state.country +
                          "," +
                          this.state.region +
                          "," +
                          this.state.countryCode
                        }
                        onChange={this.changeRegion}
                      >
                        {this.state.regions.map((region, i) => (
                          <option
                            key={i}
                            value={
                              region.country.countryName +
                              "," +
                              region.regionCode +
                              "," +
                              region.country.countryCodeShort
                            }
                          >
                            {region.country.countryName} ({region.regionCode})
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group w-100 mb-0 mb-2 p-0">
                      <label htmlFor="url" className="input-fields ml-0">
                        Online Restaurant Name (URL)
                      </label>
                      <p className="info">
                        <i class="fas fa-info-circle mr-1"></i> You can change
                        this name or link your own domain later
                      </p>
                      <div class="input-group mb-2">
                        <input
                          type="text"
                          className={classnames("form-control", {
                            "is-invalid": this.state.urlError,
                          })}
                          name="url"
                          id="url"
                          value={this.state.url}
                          onChange={this.handleChange}
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">
                            .spritzer.app{" "}
                            {this.state.showWarning && (
                              <i class="fas fa-exclamation-triangle ml-1"></i>
                            )}
                          </div>
                        </div>
                        <div className="invalid-feedback">
                          {this.state.urlError}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row mx-0">
                  <div className="col p-0">
                    {!this.state.hideUserFields ? (
                      <button
                        className="register-btn"
                        onClick={this.handleSignUp}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {this.state.loading ? (
                          ""
                        ) : (
                          <span>Create My Restaurant</span>
                        )}
                      </button>
                    ) : (
                      <button
                        className="register-btn"
                        onClick={this.handleSignBusiness}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {this.state.loading ? (
                          ""
                        ) : (
                          <span>Create My Restaurant</span>
                        )}
                      </button>
                    )}

                    <p className="agree-policy">
                      By signing up you are agreeing to our{" "}
                      <a
                        href="https://www.spritzer.app/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href="https://www.spritzer.app/terms" target="_blank">
                        Terms of Use
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Clients />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
