import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

import { Lines } from "react-preloaders";

import Breadcrumb from "../../Breadcrumb";
import SecTitle from "../SecTitle";

import localCard from "../../../images/local-card.png";
import foreignCard from "../../../images/foreign-card.png";
import cash from "../../../images/cash.png";
import genie from "../../../images/genie.png";
import checkedPay from "../../../images/checked-pay.png";
import uncheckedPay from "../../../images/unchecked-pay.png";
import uncheckedPayInvisible from "../../../images/unchecked-pay-invisible.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class PaymentMethods extends Component {
  state = {
    listingId: "",
    local: false,
    cash: false,
    foreign: false,
    genie: false,

    // hide - show
    showPlan: false,

    // erors
    payMethodError: "",

    // loading
    loading: false,
    loadingCancel: false,

    // redirects
    redirectToSelectSubPlan: false,

    // pre loading
    preLoading: true,
  };

  componentDidMount = () => {
    this.getListing();
    this.getPaymentSource();
  };

  getListing = () => {
    // get listing info
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          local: res.data[0].deliveryDetail.acceptsCard,
          cash: res.data[0].deliveryDetail.acceptsCash,
          loadingCancel: false,
          preLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);

        this.setState({ loadingCancel: false, preLoading: false });
      });
  };

  getPaymentSource = () => {
    // get payment sources
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/paymentsources/listing_subscription`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            showPlan: false,
          });
        } else {
          this.setState({
            showPlan: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // validate
  validate = () => {
    let payMethodError = "";

    if (this.state.local === false && this.state.cash === false) {
      payMethodError = "At least one payment method is required";
    }

    if (payMethodError) {
      this.setState({
        payMethodError,
      });
      return false;
    }

    return true;
  };

  handleSave = () => {
    if (this.validate()) {
      this.setState({ payMethodError: "" });

      const data = {
        acceptsCard: this.state.local,
        acceptsCash: this.state.cash,
      };

      // set loading
      this.setState({ loading: true });
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_details`,
          data,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          success();
        })
        .catch((err) => {
          this.setState({ loading: false });
          fail(err.response && err.response.status ? `Error code: ${err.response.status}` : null);
        });
    }
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true, payMethodError: "" });
    this.getListing();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#fff"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            <div className="settings-container">
              {this.state.redirectToSelectSubPlan && (
                <Redirect to="/settings/payment-info/select-subscription-plan" />
              )}
              <Breadcrumb mainPage="Settings" subPage="Payment and Payout" />

              {/* {this.state.showPlan && (
                <div className="row mx-0 mt-0 select-plan-div">
                  <div className="col-12 col-sm-8 p-0">
                    <p className="sec-title">Select a Plan</p>
                    <p className="sec-title-desc">
                      You need to select a subscription plan to publish your
                      listing and start accepting orders.
                    </p>
                  </div>
                  <div className="col-12 col-sm-4 p-0">
                    <button
                      type="button"
                      className="publish-btn"
                      onClick={() =>
                        this.setState({ redirectToSelectSubPlan: true })
                      }
                    >
                      Select Plan
                    </button>
                  </div>
                </div>
              )} */}

              <div className="sec">
                <SecTitle
                  title="Payment Methods"
                  titleDesc="Set accept payment methods and payment processing methods"
                />
                <div className="row m-0 content-container">
                  <div className="col p-0">
                    <form className="setting-form">
                      <div className="form-row">
                        <div className="form-group mb-0 p-0 input-field">
                          <label className="title ml-0 mb-3">
                            Accepted Payment Methods
                          </label>
                          <p className="payment-methods">
                            {this.state.local ? (
                              <img
                                src={checkedPay}
                                alt="local credit cards"
                                onClick={() =>
                                  this.setState({ local: !this.state.local })
                                }
                              />
                            ) : (
                              <img
                                src={uncheckedPay}
                                alt="local credit cards"
                                onClick={() =>
                                  this.setState({ local: !this.state.local })
                                }
                              />
                            )}
                            <img src={localCard} alt="local credit cards" />{" "}
                            Credit/Debit Cards
                          </p>

                          <p className="payment-methods">
                            {this.state.cash ? (
                              <img
                                src={checkedPay}
                                alt="cash"
                                onClick={() =>
                                  this.setState({ cash: !this.state.cash })
                                }
                              />
                            ) : (
                              <img
                                src={uncheckedPay}
                                alt="cash"
                                onClick={() =>
                                  this.setState({ cash: !this.state.cash })
                                }
                              />
                            )}
                            <img src={cash} alt="local credit cards" /> Cash
                          </p>

                          {/* <p className="payment-methods">
                            {this.state.foreign ? (
                              <img
                                src={checkedPay}
                                alt="foreign credit cards"
                                // onClick={() =>
                                //   this.setState({ foreign: !this.state.foreign })
                                // }
                              />
                            ) : (
                              <img
                                src={uncheckedPayInvisible}
                                alt="foreign credit cards"
                                // onClick={() =>
                                //   this.setState({ foreign: !this.state.foreign })
                                // }
                                style={{ cursor: "default" }}
                              />
                            )}
                            <img src={foreignCard} alt="local credit cards" />{" "}
                            <span className="comming-soon">Coming Soon</span>
                            <span className="pay-dissable">
                              Foreign Credit Cards
                            </span>
                          </p>
                          <p className="payment-methods">
                            {this.state.genie ? (
                              <img
                                src={checkedPay}
                                alt="genie"
                                // onClick={() =>
                                //   this.setState({ genie: !this.state.genie })
                                // }
                              />
                            ) : (
                              <img
                                src={uncheckedPayInvisible}
                                alt="genie"
                                // onClick={() =>
                                //   this.setState({ genie: !this.state.genie })
                                // }
                                style={{ cursor: "default" }}
                              />
                            )}
                            <img src={genie} alt="local credit cards" />
                            <span className="comming-soon">Coming Soon</span>
                            <span className="pay-dissable">Dialog Genie</span>
                          </p> */}

                          <p
                            className="invalid-feedback"
                            style={{ margin: "-10px 0 0 0" }}
                          >
                            {this.state.payMethodError}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row m-0 content-container">
                  <div className="col-md p-0">
                    <button
                      type="button"
                      id="location-setting"
                      className="btn setting-save-btn float-right"
                      onClick={this.handleSave}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                          style={{ margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loading ? "" : "Save"}
                    </button>
                    <button
                      type="button"
                      className="btn setting-cancel-btn float-right"
                      onClick={this.handleCancel}
                    >
                      {this.state.loadingCancel ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#4f4f4f", margin: 0 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loadingCancel ? "" : "Cancel"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
