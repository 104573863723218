import axios from "axios";
import { isAuthenticated } from "../../auth/isAuth";

export const getListingAPI = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then((response) => {
      onSuccess(response.data[0]);
    })
    .catch((error) => {
      onError(error);
    });
};
