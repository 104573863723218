import React, { useState, useEffect } from 'react'
import { Lines } from "react-preloaders";
import { isAuthenticated } from "../auth/isAuth";
import axios from "axios";
import qs from "qs";
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';

const AllAddOns = (props) => {

    const [state, setState] = useState([]);
    const [preloader, setPreloader] = useState(true);
    const [listingId, setListingId] = useState("");
    const [showDelItemAlert, setShowDelItemAlert] = useState(false);
    const [onDelete, setOnDelete] = useState({ itemId: "" });

    useEffect(() => {
        getAddOns();
    }, []);

    let count = 1;

    const getAddOns = () => {
        // get listing info
        axios.get(`${process.env.REACT_APP_API_URL}/listing/my`, {
            headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
        })
            .then((res) => {
                setListingId(res.data[0].id)
                axios.get(`${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/addon_items`,
                    {
                        headers: {
                            Authorization: `Bearer ${isAuthenticated().providerToken}`,
                        },
                    }
                )
                    .then((res) => {
                        setState(res.data)
                        setPreloader(false)
                    })
                    .catch((err) => console.log(err));

            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleItemAvailability = (addonId, status) => {
        axios.post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${listingId}/addon_items/${addonId}`,
            qs.stringify({ availability: status }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            })

            .then((res) => {
                getAddOns();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const showDelItemModal = (itemId) => {
        setShowDelItemAlert(true)
        setOnDelete({ ...onDelete, itemId: itemId })
    };

    const handleRemoveAddon = (addonId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/v2/user/listings/${listingId}/addon_items/${addonId}`,
            {
                headers: {
                    Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
            })

            .then((res) => {
                getAddOns();
                toast.success("Item Deleted Successfully");
                setShowDelItemAlert(false)
            })
            .catch((err) => {
                toast.error("Error! Please try again" + err.response && err.response.status ? `Error code: ${err.response.status}` : null);
                setShowDelItemAlert(false)
                setOnDelete({ ...onDelete, itemId: "" })
            });
    }

    const onCancel = () => {
        setShowDelItemAlert(false)
    };

    return (
        <div>
            <div className="menu-breadcrumb">
                <span>Manage Menu</span> {">"} <span className="menu-breadcrumb-bold"> AddOns</span>
                <span className="menu-breadcrumb-line"></span>
            </div>

            {preloader ?
                <Lines
                    color="#ff6961"
                    background="#F2F2F2"
                    animation="slide-down"
                    customLoading={preloader}
                />
                :
                <div>
                    {state.length ?
                        <div className="show-all-addon-items">
                            <div className="add-ons-title"><h6>Available AddOns</h6></div>
                            <div className="add-ons-details-wrapper">
                                <div className="add-ons-table-header-title">
                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                    <div className="addons-details-col">AddOn Name</div>
                                    <div className="addons-availability-col">Availability</div>
                                    <div className="remove-addon-header">&nbsp;&nbsp;&nbsp;</div>
                                </div>

                                {state && state.map((item, index) => (
                                    <div className="add-ons-table-body-inner" key={item.id}>
                                        <div className="all-addons-count">{count++}</div>
                                        <div className="addons-details-col">{item.name}</div>
                                        <div className="addons-availability-col">
                                            <div className="custom-control custom-switch m-0">
                                                <input type="checkbox" className="custom-control-input" id={item.id} checked={item.isAvailable} onChange={() => handleItemAvailability(item.id, !item.isAvailable)} />
                                                <label className="custom-control-label" htmlFor={item.id}></label>
                                            </div>
                                        </div>
                                        <div className="remove-addon" onClick={() => showDelItemModal(item.id)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div> :
                        <div className="no-addons-wrapper">
                            <div className="add-ons-title"><h6>No any AddOn Available</h6></div>
                            <div className="addon-add-flow">Please go to the Menu and add addons to the menu item</div>
                        </div>
                    }
                </div>
            }


            {showDelItemAlert ? (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => handleRemoveAddon(onDelete.itemId)}
                    onCancel={onCancel}
                    focusCancelBtn
                    btnSize="sm"
                    cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
                >
                    You will not be able to recover this item!
                </SweetAlert>
            ) : null}

        </div>
    )
}

export default AllAddOns
