import React, { Component } from "react";
import classnames from "classnames";
import axios from "axios";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";

import SecTitle from "../SecTitle";

import close from "../../../images/close-modal.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { googleAPIKey } from "../../../config/keys";
import { success, fail } from "../../../methods/Alert";

import Geocode from "react-geocode";
Geocode.setApiKey(googleAPIKey);
Geocode.enableDebug();

export default class Location extends Component {
  state = {
    listingId: "",
    line1: "",
    city: "",
    country: "",
    countryCode: "",
    latitude: JSON.parse(localStorage.getItem("listing")).address
      ? JSON.parse(localStorage.getItem("listing")).address.latitude
      : 0,
    longitude: JSON.parse(localStorage.getItem("listing")).address
      ? JSON.parse(localStorage.getItem("listing")).address.longitude
      : 0,

    // loading
    loadingAdd: false,
    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    // get lising info
    this.getListing();
  };

  getListing = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          countryCode: res.data[0].region.country.countryCodeShort,
          country: res.data[0].region.country.countryName,
          address:
            res.data[0].address.pretty === "undefined"
              ? ""
              : res.data[0].address.pretty,
          latitude: res.data[0].address.latitude,
          longitude: res.data[0].address.longitude,
          locality:
            res.data[0].address.locality === "undefined"
              ? ""
              : res.data[0].address.locality,
          loadingCancel: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingCancel: false });
      });
  };

  WrappedMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{
          lat: this.state.latitude,
          lng: this.state.longitude,
        }}
      >
        <Marker
          position={{
            lat: this.state.latitude,
            lng: this.state.longitude,
          }}
          draggable={true}
          onDragEnd={this.onMarkerDragEnd}
        />
      </GoogleMap>
    ))
  );

  // marker drag and drop
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng)
      .then((res) => {
        this.setState({
          latitude: newLat,
          longitude: newLng,
        });

        this.WrappedMap = withScriptjs(
          withGoogleMap((props) => (
            <GoogleMap
              defaultZoom={16}
              defaultCenter={{
                lat: this.state.latitude,
                lng: this.state.longitude,
              }}
            >
              <Marker
                position={{
                  lat: this.state.latitude,
                  lng: this.state.longitude,
                }}
                draggable={true}
                onDragEnd={this.onMarkerDragEnd}
              />
            </GoogleMap>
          ))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // get input values
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // validate
  validateAddress = () => {
    let line1Error = "";
    let cityError = "";

    if (this.state.line1 === "") {
      line1Error = "Address No & street is required";
    }

    if (this.state.city === "") {
      cityError = "City is required";
    }

    if (line1Error || cityError) {
      this.setState({
        line1Error,
        cityError,
      });
      return false;
    }
    return true;
  };

  addAddress = () => {
    if (this.validateAddress()) {
      this.setState({
        line1Error: "",
        cityError: "",
      });

      this.setState({ loadingAdd: true });

      const google = window.google;
      let service = new google.maps.Geocoder();

      service.geocode(
        {
          address: `${this.state.line1} ${this.state.line2}, ${this.state.city}`,
          componentRestrictions: {
            country: this.state.countryCode,
          },
        },
        (geocoderResult, status) => {
          if (status === "OK") {
            this.setState({
              latitude: geocoderResult[0].geometry.location.lat(),
              longitude: geocoderResult[0].geometry.location.lng(),
            });

            this.WrappedMap = withScriptjs(
              withGoogleMap((props) => (
                <GoogleMap
                  defaultZoom={16}
                  defaultCenter={{
                    lat: geocoderResult[0].geometry.location.lat(),
                    lng: geocoderResult[0].geometry.location.lng(),
                  }}
                >
                  <Marker
                    position={{
                      lat: geocoderResult[0].geometry.location.lat(),
                      lng: geocoderResult[0].geometry.location.lng(),
                    }}
                    draggable={true}
                    onDragEnd={this.onMarkerDragEnd}
                  />
                </GoogleMap>
              ))
            );

            this.setState({
              loadingAdd: false,
              address: `${this.state.line1} ${this.state.city} ${this.state.country}`,
            });

            document.getElementById("close-address-modal").click();
          } else {
            this.setState({ loadingAdd: false });
          }
        }
      );
    }
  };

  CancelAddress = () => {
    this.setState({ line1: "", city: "" });
    document.getElementById("close-address-modal").click();
  };

  handleSave = (e) => {
    e.preventDefault();

    if (this.state.address) {
      this.setState({ loading: true });

      const addressData = {
        address: {
          pretty: this.state.line1
            ? `${this.state.line1} ${this.state.city} ${this.state.country}`
            : this.state.address,
          locality: this.state.city,
          adminArea1: null,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          postalCode: null,
        },
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/listing/${this.state.listingId}`,
          addressData,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          success();

          axios
            .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
            })
            .then((res) => {
              localStorage.setItem("listing", JSON.stringify(res.data[0]));

              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
            });

          this.getListing();
        })
        .catch((err) => {
          this.setState({ loading: false });
          fail();
        });
    }
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true });
    this.getListing();
  };

  render() {
    return (
      <div className="settings-container">
        <div className="sec">
          <SecTitle
            title="Location"
            titleDesc="Set the address and location of your restaurant"
          />

          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <form className="setting-form d-inline-block form-location w-100">
                <div className="form-row mb-0">
                  <div className="form-group mb-0 p-0 input-field">
                    <label className="title ml-0 mb-0">Address</label>
                    <span
                      className="change-settings-btn"
                      data-toggle="modal"
                      data-target="#address-modal"
                    >
                      {this.state.longitude === 0 && this.state.latitude === 0
                        ? "Add"
                        : "Change"}
                    </span>
                    <p className="inform">Set the address</p>
                  </div>
                </div>
                {this.state.address ? (
                  <div className="form-row mt-0">
                    <div className="form-group mb-0 p-0 input-field">
                      <p className="address-dissable">{this.state.address}</p>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
            <div className="col-md p-0">
              {this.state.address ? (
                <div className="choose-map">
                  <label className="title ml-0 mb-0">Location</label>
                  <p className="inform">
                    Move the map around and place your location underthe pin
                  </p>
                  <this.WrappedMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC8EFXuIwNdJVSEzVOCIDJeCRM2Rhuj8VY`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: 260 }} />}
                    mapElement={<div style={{ height: 260 }} />}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {this.state.address && (
            <div className="row m-0 content-container pt-3">
              <div className="col-md p-0">
                <button
                  type="button"
                  id="location-setting"
                  className="btn setting-save-btn float-right"
                  onClick={this.handleSave}
                >
                  {this.state.loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                      style={{ margin: 0 }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}

                  {this.state.loading ? "" : "Save"}
                </button>
                <button
                  type="button"
                  className="btn setting-cancel-btn float-right"
                  onClick={this.handleCancel}
                >
                  {this.state.loadingCancel ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                      style={{ color: "#4f4f4f", margin: 0 }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}

                  {this.state.loadingCancel ? "" : "Cancel"}
                </button>
              </div>
            </div>
          )}
          <div
            className="modal fade add-cost-com"
            id="address-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="address-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="address-modal"
                    style={{ fontSize: 22, fontWeight: "normal" }}
                  >
                    Address
                  </h5>

                  <img
                    src={close}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-address-modal"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-row ">
                      <div className="form-group col mb-2">
                        <label htmlFor="line1" className="title ml-0">
                          Address No & Street
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.line1Error,
                            })}
                            id="line1"
                            name="line1"
                            value={this.state.line1}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="invalid-feedback">
                          {this.state.line1Error}
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col mb-2">
                        <label htmlFor="city" className="title ml-0">
                          City
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.state.cityError,
                            })}
                            id="city"
                            name="city"
                            value={this.state.city}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="invalid-feedback">
                          {this.state.cityError}
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col mb-2">
                        <label htmlFor="country" className="title ml-0">
                          Country
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="country"
                            value={this.state.country}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row mt-4 m-0 ">
                    <div className="col-md p-0">
                      <button
                        type="button"
                        id="location-setting"
                        className="btn setting-save-btn float-right"
                        onClick={this.addAddress}
                      >
                        {this.state.loadingAdd ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                            style={{ margin: 0 }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loadingAdd ? "" : "Save"}
                      </button>
                      <button
                        type="button"
                        className="btn setting-cancel-btn float-right"
                        onClick={this.CancelAddress}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
