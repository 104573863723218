import React, { Component } from "react";

export default class SecTitle extends Component {
  render() {
    return (
      <div className="row m-0 sec-container">
        <div className="col p-0">
          <p className="sec-title">{this.props.title}</p>
          <p className="sec-title-desc">{this.props.titleDesc}</p>
        </div>
      </div>
    );
  }
}
