import React, { Component } from "react";
import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import TitleOfPage from "../components/TitleOfPage";
import Domain from "../components/settings/website/Domain";
import SEO from "../components/settings/website/SEO";
import Theme from "../components/settings/website/Theme";
// import LogoFavCover from "../components/settings/website/LogoFavCover";
import LogoFavCoverWithoutCrop from "../components/settings/website/LogoFavCoverWithoutCrop";
import SocialMedia from "../components/settings/website/SocialMedia";

import "../styles/settings.css";

export default class Website extends Component {
  state = {
    show: true,
  };

  handleSideMenu = () => {
    if (window.screen.width < 768) {
      this.setState({ show: false });
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
    }
  };

  componentDidMount = () => {
    this.handleSideMenu();
    window.addEventListener("resize", this.handleSideMenu);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSideMenu);
  }

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div
              className="dashboard-content-col pb-4"
              style={{ background: "#f2f2f2" }}
            >
              {this.state.show ? null : (
                <i
                  class="fas fa-chevron-circle-right side-menu-show"
                  onClick={() => this.showMenu(true)}
                ></i>
              )}
              <TitleOfPage title="Website Info - Spritzer Dashboard" />
              <Domain />
              <LogoFavCoverWithoutCrop />
              <Theme />
              <SEO />
              <SocialMedia />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
