import React from "react";

export default function Heading(props) {
  return (
    <React.Fragment>
      <nav className="navbar sticky-top thin-hr p-0 border-bottom" style={{background: "#e4e4e4", height: 47}}>
        <p className="page-section-heading">{props.heading}</p>
      </nav>
    </React.Fragment>
  );
}