import axios from 'axios';
import { isAuthenticated } from "../../auth/isAuth";

export const getAllCuisines = (onSuccess, onError) => {
    axios.get(`${process.env.REACT_APP_API_URL}/v2/remote_config/mobile`, {
        headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
    })
        .then((response) => {
            onSuccess(response)
        })
        .catch((error) => {
            onError(error)
        })
};
