import React, { useState, useEffect } from "react";
import { Lines } from "react-preloaders";
import classnames from "classnames";
import Breadcrumb from "../../Breadcrumb";
import SecTitle from "../../settings/SecTitle";
import { saveIntegrationAPI } from "../../api/integrations.js";
import { getListingAPI } from "../../api/getListing.js";
import { fail, success } from "../../../methods/Alert";
import { isEmpty } from "lodash";

const Shipday = () => {
  const [preLoading, setPreLoading] = useState(false);
  const [isLoadingShipday, setIsLoadingShipday] = useState(false);
  const [isLoadingSms160, setIsLoadingSms160] = useState(false);
  const [shipdayApi, setShipdayApi] = useState("");
  const [shipdayApiKeyError, setShipdayApiKeyError] = useState(false);
  const [listingId, setListingId] = useState("");

  const [sms160Api, setSms160Api] = useState("");
  const [senderId, setSenderId] = useState("")
  const [sms160ApiKeyError, setsms160ApiKeyError] = useState(false);
  const [sms160UserIdError, setsms160UserIdError] = useState(false);

  const [integrations, setIntegrations] = useState({});

  const handleChangeShipday = (event) => {
    setShipdayApi(event.target.value);
  };

  const handleChangeSms160 = (event) => {
    setSms160Api(event.target.value);
  };

  const handleSenderId = (event) => {
    setSenderId(event.target.value);
  };

  const handleGetListingData = () => {
    setPreLoading(true);
    getListingAPI(
      (data) => {
        setPreLoading(false);

        const _integrations = data.integrations || [];
        const tempObj = {};
        _integrations.forEach(e => {tempObj[e.integrationType] = e });
        setIntegrations(tempObj);

        setListingId(data.id);
      },
      (error) => {
        setPreLoading(false);
        console.log(error);
      }
    );
  };

  const handleSave = (integrationType) => {
    if(integrationType === 'SHIPDAY'){
      if (shipdayApi !== "") {
        setIsLoadingShipday(true);
        saveIntegrationAPI(
          listingId,
          integrationType,
          {shipday:{apiKey:shipdayApi}},
          (data) => {
            success(data?.data?.message);
            setIsLoadingShipday(false);
          },
          (error) => {
            fail();
            console.log(error);
            setIsLoadingShipday(false);
          }
        );
        setShipdayApiKeyError(false);
      } else {
        setShipdayApiKeyError(true);
      }
    }
    if(integrationType === 'SMS160'){
      if (sms160Api !== "" && senderId !== "") {
        setIsLoadingSms160(true);
        saveIntegrationAPI(
          listingId,
          integrationType,
          {sms160:{apiKey:sms160Api, senderId}},
          (data) => {
            success(data?.data?.message);
            setIsLoadingSms160(false);
            setsms160UserIdError(false);
          },
          (error) => {
            fail();
            console.log(error);
            setIsLoadingSms160(false);
            setsms160UserIdError(false);
          }
        );
        setsms160ApiKeyError(false);
      } else if (senderId == "") {
        setsms160UserIdError(true);
      }else  {
        setsms160ApiKeyError(true);
      }
    }
  };

  useEffect(() => {
    handleGetListingData();
  }, []);

  useEffect(() => {
    if (isEmpty(integrations)) return;

    setShipdayApi(integrations["SHIPDAY"]?.meta?.shipday?.apiKey || "");
    setSms160Api(integrations["SMS160"]?.meta?.sms160?.apiKey || "");
  }, [integrations])

  return (
    <>
      {preLoading ? (
        <Lines
          color="#ff6961"
          background="#f2f2f2"
          animation="slide-down"
          customLoading={preLoading}
        />
      ) : (
        <React.Fragment>
          <div className="settings-container integrations-wrapper">
            <Breadcrumb mainPage="Restaurant" subPage="Integrations" />
            <span className="menu-breadcrumb-line"></span>
            <div className="sec" style={{ marginTop: "1rem" }}>
              <SecTitle
                title="Third Party Integrations"
                titleDesc="Integrate third party services to extend and enhance Spritzer functionality"
              />
              <div className="row m-0 content-container mt-20">
                <div className="col p-0 one-input-item">
                  <div className="form-group mb-0 p-0 input-field">
                    <label htmlFor="name" className="title ml-0">
                      Shipday API Key
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": shipdayApiKeyError,
                      })}
                      placeholder="Please enter your API key here"
                      id="shipdayApi"
                      name="shipdayApi"
                      value={shipdayApi}
                      onChange={handleChangeShipday}
                      autoComplete="off"
                    />
                    {shipdayApiKeyError && (
                      <div className="invalid-feedback">
                        Please enter your API key
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md p-0" style={{ marginTop: "3rem" }}>
                  <button
                    type="button"
                    className="btn setting-save-btn float-right"
                    onClick={()=>handleSave('SHIPDAY')}
                    disabled={isLoadingShipday}
                  >
                    {isLoadingShipday ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                        style={{ margin: 0 }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {isLoadingShipday ? "" : "Save"}
                  </button>
                </div>
              </div>

              <div className="row m-0 content-container">
                <div className="col p-0 one-input-item">
                  <div className="form-group mb-0 p-0 input-field">
                    <label htmlFor="name" className="title ml-0">
                      SMS160 API Key
                    </label>
                    <input 
                      type="text" 
                      className={classnames("form-control", {
                        "is-invalid": shipdayApiKeyError,
                      })}
                      placeholder="Please enter sender Id"
                      id="senderId"
                      name="senderId"
                      value={senderId}
                      onChange={handleSenderId}
                      autoComplete="off"
                      style={{marginBottom: "10px"}}
                    />
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": shipdayApiKeyError,
                      })}
                      placeholder="Please enter your API key here"
                      id="sms160Api"
                      name="sms160Api"
                      value={sms160Api}
                      onChange={handleChangeSms160}
                      autoComplete="off"
                    />
                    {sms160ApiKeyError && (
                      <div className="invalid-feedback">
                        Please enter your API key
                      </div>
                    )}
                    {sms160UserIdError && (
                      <div className="invalid-feedback">
                        Please enter your User Id
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md p-0" style={{ marginTop: "6rem" }}>
                  <button
                    type="button"
                    className="btn setting-save-btn float-right"
                    onClick={()=>handleSave('SMS160')}
                    disabled={isLoadingSms160}
                  >
                    {isLoadingSms160 ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                        style={{ margin: 0 }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {isLoadingSms160 ? "" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default Shipday;
