import React, { Component } from "react";
import axios from "axios";

import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import Heading from "../components/dashboard/Heading";
import ItemMenu from "../components/ItemMenu";
import AddOns from "../views/AddOns";
import TitleOfPage from "../components/TitleOfPage";

import CompleteInfo from "../components/settings/CompleteInfo";

import { isAuthenticated } from "../auth/isAuth";
import PortionSize from "./PortionSize";

export default class ManageMenu extends Component {
  state = {
    show: true,
    isComMenu: true,
    selectedScreen: "menuPage",
    portionSizesList: [],
    currency: {},

    itemId: "",
    itemName: "",
    listingId: "",
    selectedItemPrice: "",
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    // get listing info
    axios.get(`${process.env.REACT_APP_API_URL}/listing/my`, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
      .then((res) => {
        this.setState({
          status: res.data[0].status,
          currency: res.data[0].currency,
        });

        //  get menus
        axios.get(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/menu_items`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
          .then((res) => {
            if (res.data.length > 0) {
              this.setState({ isComMenu: true });
            } else {
              this.setState({ isComMenu: false });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "100%";
    }
  };

  showAddOnsPage = (condition, portionSizes, itemPrice) => {
    this.setState({
      selectedScreen: condition,
      portionSizesList: portionSizes,
      selectedItemPrice: itemPrice
    })
  }

  getSelectedItem = (item) => {
    this.setState({
      itemId: item.itemId,
      itemName: item.itemName,
      listingId: item.listingId,
    })
  }

  renderScreen = () => {
    switch (this.state.selectedScreen) {
      case 'menuPage':
        return <ItemMenu
          showAddOnsPage={this.showAddOnsPage}
          getSelectedItem={this.getSelectedItem}
          currency={this.state.currency}
        />;

      case 'addOnsPage':
        return <AddOns
          showAddOnsPage={this.showAddOnsPage}
          selectedItemTitle={this.state.itemName}
          itemId={this.state.itemId}
          listingId={this.state.listingId}
          currency={this.state.currency}
        />;

      case 'portionSizePage':
        return <PortionSize
          showAddOnsPage={this.showAddOnsPage}
          selectedItemTitle={this.state.itemName}
          itemId={this.state.itemId}
          listingId={this.state.listingId}
          portionSizesList={this.state.portionSizesList}
          selectedItemPrice={this.state.selectedItemPrice}
          currency={this.state.currency}
        />;

      default:
        return "";
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div className="dashboard-content-col pb-4">
              {this.state.show ? null : (
                <i class="fas fa-chevron-circle-right side-menu-show" onClick={() => this.showMenu(true)}></i>
              )}
              <TitleOfPage title="Manage Menu - Spritzer Dashboard" />
              <div style={{ maxWidth: "93%", margin: "auto" }}>
                {this.renderScreen()}
              </div>
              {this.state.status === "verified" ? null : this.state.isComMenu ? null : (
                <CompleteInfo title="Add Items to Your Menu" desc="At least one item is required to publish your website." />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
