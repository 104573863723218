import React, { Component } from "react";
import axios from "axios";

import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import TitleOfPage from "../components/TitleOfPage";
import PaymentMethods from "../components/settings/payment/PaymentMethods";
import Payout from "../components/settings/payment/Payout";
import Plan from "../components/settings/payment/Plan";

import "../styles/payment.css";

import { isAuthenticated } from "../auth/isAuth";

export default class Payment extends Component {
  state = {
    show: true,
    showPlan: false,
  };

  handleSideMenu = () => {
    if (window.screen.width < 768) {
      this.setState({ show: false });
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
    }
  };

  componentDidMount = () => {
    this.handleSideMenu();
    window.addEventListener("resize", this.handleSideMenu);

    // get payment sources
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/paymentsources/listing_subscription`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            showPlan: true,
          });
        } else {
          this.setState({
            showPlan: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSideMenu);
  }

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div
              className="dashboard-content-col pb-4"
              style={{ background: "#f2f2f2" }}
            >
              {this.state.show ? null : (
                <i
                  class="fas fa-chevron-circle-right side-menu-show"
                  onClick={() => this.showMenu(true)}
                ></i>
              )}
              <TitleOfPage title="Payment Info - Spritzer Dashboard" />
              <PaymentMethods />
              <Payout />
              {/* {this.state.showPlan && <Plan />} */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
