import React, { Component } from "react";
import axios from "axios";

import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import Heading from "../components/dashboard/Heading";
import TitleOfPage from "../components/TitleOfPage";

import AllAddOns from "./AllAddOns";

export default class ManageAddOns extends Component {
  state = {
    show: true,
    isComMenu: true,
    isAddOns: false,
  };


  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "";
      document.getElementsByClassName("page-section-heading")[0].style.marginLeft = "20px";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width = "100%";
      document.getElementsByClassName("page-section-heading")[0].style.marginLeft = "50px";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div className="dashboard-content-col pb-4">
              {/* <Heading heading="Manage Add-Ons" /> */}
              {this.state.show ? null : (
                <i class="fas fa-chevron-circle-right side-menu-show" onClick={() => this.showMenu(true)}></i>
              )}
              <TitleOfPage title="Manage Menu - Spritzer Dashboard" />
              <div style={{ maxWidth: "93%", margin: "auto" }}>
                <AllAddOns
                  listingId={this.state.listingId}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
