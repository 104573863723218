import React, { Component } from "react";
import axios from "axios";

import Navbar from "../components/dashboard/Navbar";
import SideMenu from "../components/dashboard/SideMenu";
import TitleOfPage from "../components/TitleOfPage";
import ToDo from "../components/getStarted/ToDo";

import "../styles/settings.css";

import { isAuthenticated } from "../auth/isAuth";

export default class GetStarted extends Component {
  state = {
    show: true,
  };

  componentDidMount = () => {
    if (JSON.parse(localStorage.getItem("listing")).deliveryDetail) {
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${
            JSON.parse(localStorage.getItem("listing")).id
          }/delivery_optin`,
          {},
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
            })
            .then((res) => {
              localStorage.setItem("listing", JSON.stringify(res.data[0]));
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    }

    this.handleSideMenu();
    window.addEventListener("resize", this.handleSideMenu);
  };

  handleSideMenu = () => {
    if (window.screen.width < 768) {
      this.setState({ show: false });
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSideMenu);
  }

  showMenu = (condition) => {
    this.setState({ show: condition });

    if (condition === true) {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "";
      document.getElementsByClassName("to-do-select-plan")[0].style.width = "";
    } else {
      document.getElementsByClassName("dashboard-content-col")[0].style.width =
        "100%";
      document.getElementsByClassName("to-do-select-plan")[0].style.width =
        "100%";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid p-0">
          <div className="row m-0">
            {this.state.show ? (
              <SideMenu show={this.state.show} showMenu={this.showMenu} />
            ) : null}
            <div
              className="dashboard-content-col pb-4"
              style={{ background: "#f2f2f2" }}
            >
              {this.state.show ? null : (
                <i
                  class="fas fa-chevron-circle-right side-menu-show"
                  onClick={() => this.showMenu(true)}
                ></i>
              )}
              <TitleOfPage title="Get Started - Spritzer Dashboard" />
              <ToDo />
              {/* <SelectPlan /> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
