import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { isAuthenticated } from "../../auth/isAuth";
import logo from "../../images/spritzer-logo.png";

export default class Navbar extends Component {
  state = {
    redirectToLogin: false,
    value: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).extId
      : "",
    customDomain: "",
    status: localStorage.getItem("listing")
      ? JSON.parse(localStorage.getItem("listing")).status
      : "",
    copied: false,
  };

  componentDidMount = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated() && isAuthenticated().providerToken
            }`,
        },
      })
      .then((res) => {
        this.setState({
          value: res.data[0].extId,
          customDomain: res.data[0].customDomain
            ? res.data[0].customDomain
            : "",
          status: res.data[0].status,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // logout
  logout = (e) => {
    e.preventDefault();

    if (typeof window !== "undefined") {
      Cookies.remove("auth_dash_token", { path: "", domain: ".spritzer.app" });
      document.cookie = "auth_dash_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("profile");
      localStorage.removeItem("listing");
    }

    this.setState({ redirectToLogin: true });
    window.location.reload();
  };

  copy = () => {
    const link = this.state.customDomain ? `https://${this.state.customDomain}` : `https://${this.state.value}.spritzer.app`;
    navigator.clipboard.writeText(link);
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 10000);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.redirectToLogin ? <Redirect to="/" /> : null}
        <nav
          className="navbar sticky-top navbar-light dashboard-nav"
          style={{ background: "#151B26", zIndex: 1025, height: 65 }}
        >
          <img
            src={logo}
            className="company-logo"
            alt="Spritzer Business Logo"
          />
          <div className="ml-auto nav-main-buttons-wrapper">
            {this.state.status === "verified" ? (
              <div className="copy-rest-link-div">
                {this.state.copied ? (
                  <span style={{ color: "rgb(0 183 7)", fontSize: 12, paddingRight: 10 }}>
                    <i className="fas fa-check"></i> Link copied to clipboard
                  </span>
                ) : null}

                <a className="copy-rest-link" href={this.state.customDomain
                    ? `https://${this.state.customDomain}`
                    : `https://${this.state.value}.spritzer.app`} target="_blank">
                  {this.state.customDomain
                    ? `https://${this.state.customDomain}`
                    : `https://${this.state.value}.spritzer.app`}
                  <i className="fas fa-external-link-alt"></i>
                </a>

                <span className="copy-res-link-span" onClick={this.copy}>
                  <i className="far fa-copy"></i>
                </span>
              </div>
            ) : null}

            <div className="nav-main-buttons ml-4">
              <div className="btn-group float-right user-display-btn" style={{ cursor: "pointer" }} >
                <span className="dropdown-toggle text-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i className="fas fa-user-circle" style={{ verticalAlign: "middle", fontSize: "27px" }}></i>
                </span>

                <div className="dropdown-menu dropdown-menu-right p-0 user-display-dropdown" style={{ zIndex: 9999, fontSize: 13, top: "122%" }}>
                  <span className="user-name pl-2">
                    {localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).name}
                  </span>
                  <span className="user-email pl-2">
                    {localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).email}
                    {localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).isActivated ? <i className="fas fa-check-circle"></i> : null}
                  </span>
                  <span className="user-active-state pl-2">
                    {localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).isActivated ? "(Activated)" : "(Not activated) "}
                  </span>
                  <Link className="dropdown-item text-dark logout-link" to="#" onClick={this.logout} style={{ lineHeight: "30px" }}>
                    <i className="fas fa-sign-out-alt"></i> &nbsp; Logout
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
