import React, { Component } from "react";
import classnames from "classnames";
import axios from "axios";

import SecTitle from "../SecTitle";

import close from "../../../images/close-modal.png";

import { isAuthenticated } from "../../../auth/isAuth";
import { googleAPIKey } from "../../../config/keys";
import { success, fail, deleted } from "../../../methods/Alert";

import Geocode from "react-geocode";
Geocode.setApiKey(googleAPIKey);
Geocode.enableDebug();

export default class Tax extends Component {
  state = {
    listingId: "",

    taxComponents: [],

    id: "",
    description: "",
    included: false,
    percentage: 0,

    deleteId: "",

    loading: false,
    loadingDelete: false,
    loadingCostCom: false,

    valueError: "",
    descError: "",
  };

  componentDidMount = () => {
    // get lising info
    this.getListing();
  };

  getListing = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          listingId: res.data[0].id,
          taxComponents: res.data[0].deliveryDetail.taxComponents,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleChange = (e) => {
    if (e.target.name === "percentage") {
      const limit = 4;
      this.setState({ [e.target.name]: e.target.value.slice(0, limit) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  CancelAddingCom = () => {
    this.setState(
      {
        id: "",
        description: "",
        included: true,
        percentage: 0,
        valueError: "",
        descError: "",
      },
      () => {
        document.getElementById("add-cost-com-tax").click();
      }
    );
  };

  openDelModal = (id) => {
    this.setState({ deleteId: id });
    document.getElementById("tax-delete-hidden-btn").click();
  };

  cancelDelete = () => {
    this.setState({ deleteId: "" });
    document.getElementById("close-tax-del-modal").click();
  };

  validateTaxCom = () => {
    let valueError = "";
    let descError = "";

    if (this.state.percentage === 0 || this.state.percentage === "") {
      valueError = "Percentage is required";
    }
    if (this.state.description === "") {
      descError = "Description is required";
    }

    if (valueError || descError) {
      this.setState({
        valueError,
        descError,
      });
      return false;
    }

    return true;
  };

  AddCostCom = () => {
    if (this.validateTaxCom()) {
      const data = {
        id: this.state.id,
        description: this.state.description,
        included: this.state.included,
        percentage: this.state.percentage,
      };

      this.setState({ loadingCostCom: true });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/tax_components`, data, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          this.setState({
            id: "",
            description: "",
            included: false,
            percentage: 0,
            loadingCostCom: false,
          });

          success();
          this.getListing();
          document.getElementById("add-cost-com-tax").click();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loadingCostCom: false });
          fail();
        });
    }
  };

  deleteTaxCom = () => {
    this.setState({ loadingDelete: true });
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/tax_components/${this.state.deleteId}`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        document.getElementById("close-tax-del-modal").click();

        this.setState({ loadingDelete: false });

        deleted();
        this.getListing();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingDelete: false });
        fail();
      });
  };

  render() {
    return (
      <div className="settings-container" style={{ marginBottom: 150 }}>
        <div className="sec">
          <SecTitle title="Taxes" titleDesc="Add tax components specific to your operating region. Taxes will be calculated on top of item total and extra costs." />

          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <form className="setting-form order-forms d-inline-block w-100">
                <div className="form-row mb-3">
                  <div className="form-group mb-0 p-0 input-field">
                    <span className="change-settings-btn tax-comp" data-toggle="modal" data-target="#add-cost-com-tax">
                      Add Tax Component
                    </span>
                  </div>
                </div>
                {this.state.taxComponents?.map((com, i) => (
                  <div className="form-row mt-0" key={i}>
                    <div className="form-group extra-cost-col1 pl-0">
                      <label className="title ml-0 mb-0">
                        {`${com.percentage}%`} - {com.description}
                      </label>
                    </div>
                    <div className="form-group extra-cost-col2 pl-0">
                      <span className="com-del-btn" onClick={() => this.openDelModal(com.id)}>
                        Delete
                      </span>
                    </div>
                  </div>
                ))}
              </form>
            </div>

            <div className="col-md p-0"></div>
          </div>
        </div>

        <div className="modal fade add-cost-com" id="add-cost-com-tax" tabIndex="-1" role="dialog" aria-labelledby="add-cost-com" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ fontSize: 22, fontWeight: "normal" }}>
                  Add Tax Component
                </h5>

                <img src={close} type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({ valueError: "", descError: "" })} />
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-row">
                    <div className="form-group col">
                      <label htmlFor="value" className="title ml-0">
                        Percentage
                      </label>

                      <div className="input-group">
                        <input
                          type="number"
                          className={classnames("form-control", {
                            "is-invalid": this.state.valueError,
                          })}
                          id="tax-percentage"
                          name="percentage"
                          value={this.state.percentage}
                          onChange={this.handleChange}
                          maxLength="4"
                          autoComplete="off"
                        />
                        <div className="input-group-prepend">
                          <div className="input-group-text input-prepends">%</div>
                        </div>
                      </div>

                      <div className="invalid-feedback">{this.state.valueError}</div>
                    </div>

                    <div className="form-group col"></div>
                  </div>

                  <div className="form-row" style={{ marginTop: 12 }}>
                    <div className="form-group col w-100">
                      <label htmlFor="desc" className="title ml-0">
                        Description
                      </label>
                      <textarea
                        className={classnames("form-control", {
                          "is-invalid": this.state.descError,
                        })}
                        id="tax-description"
                        rows="3"
                        name="description"
                        value={this.state.description}
                        onChange={this.handleChange}
                        maxLength="100"
                      ></textarea>
                      <div className="invalid-feedback">{this.state.descError}</div>
                    </div>
                  </div>
                </form>
                <div className="row mt-4 m-0 ">
                  <div className="col-md p-0">
                    <button type="button" id="location-setting" className="btn setting-save-btn float-right" onClick={this.AddCostCom}>
                      {this.state.loadingCostCom ? (
                        <div className="spinner-border spinner-border-sm text-light" role="status" style={{ margin: 0 }}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loadingCostCom ? "" : "Save"}
                    </button>
                    <button type="button" className="btn setting-cancel-btn float-right" onClick={this.CancelAddingCom}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* DELETE */}
        <button type="button" data-toggle="modal" data-target="#tax-delete-modal" id="tax-delete-hidden-btn" hidden></button>

        <div className="modal fade add-cost-com" id="tax-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="tax-delete-modal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="tax-delete-modal" style={{ fontSize: 22, fontWeight: "normal" }}>
                  Are you sure?
                </h5>

                <img src={close} type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-tax-del-modal" onClick={this.cancelDelete} />
              </div>
              <div className="modal-body">
                <div className="row m-0 ">
                  <div className="col-md p-0">
                    <p>You won't be able to revert this!</p>
                  </div>
                </div>
                <div className="row mt-4 m-0 ">
                  <div className="col-md p-0">
                    <button type="button" id="location-setting" className="btn setting-save-btn float-right" onClick={this.deleteTaxCom}>
                      {this.state.loadingDelete ? (
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loadingDelete ? "" : "Yes"}
                    </button>
                    <button type="button" className="btn setting-cancel-btn float-right" onClick={this.cancelDelete}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
