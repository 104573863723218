import React from "react";

export const OrderTableHeader = ({ currency }) => {
  
  // TODO: make utility and move into it
  const getCurrencySymbol = (currency) => {
    return currency?.symbol || currency?.code || "";
  };

  return (
    <div>
      <div className="col">
        <div className="row order-menu-header">
          <div className="orderid-col">Order ID</div>
          <div className="date-col">Requested For</div>
          <div className="customer-col">Customer</div>
          <div className="suburb-col">Suburb</div>
          <div className="channel-col ">Order Type</div>
          <div className="amount-col">Amount ({getCurrencySymbol(currency)})</div>
          <div className="pay-method-col">Payment Method</div>
        </div>
      </div>
    </div>
  );
};
