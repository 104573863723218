import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";

import Breadcrumb from "../../Breadcrumb";
import SecTitle from "../SecTitle";

import { isAuthenticated } from "../../../auth/isAuth";
import { success, fail } from "../../../methods/Alert";

export default class Change extends Component {
  state = {
    email: "",
    oldPassword: "",
    newPassword: "",

    // errors
    oldPasswordError: "",
    newPasswordError: "",

    // loading
    loading: false,
    loadingCancel: false,
  };

  componentDidMount = () => {
    this.getListing();
  };

  getListing = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          email: res.data.email,
          oldPassword: "",
          newPassword: "",
          oldPasswordError: "",
          newPasswordError: "",
          loadingCancel: false,
        });
      })
      .catch((err) => console.log(err));
  };

  // get input values
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // validate
  validate = () => {
    let oldPasswordError = "";
    let newPasswordError = "";

    if (this.state.oldPassword === "") {
      oldPasswordError = "Current password is required";
    }

    if (this.state.newPassword === "") {
      newPasswordError = "New password is required";
    }

    if (oldPasswordError || newPasswordError) {
      this.setState({
        oldPasswordError,
        newPasswordError,
      });
      return false;
    }

    return true;
  };

  handleSave = (e) => {
    e.preventDefault();

    if (this.validate()) {
      const data = {
        email: this.state.email,
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        clientId: "web",
      };

      // set loading
      this.setState({
        loading: true,
        oldPasswordError: "",
        newPasswordError: "",
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/user/password/change`, data, {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        })
        .then((res) => {
          document.cookie = `auth_dash_token=${JSON.stringify(
            res.data
          )};domain=spritzer.app`;

          this.setState({
            successAlert: true,
            loading: false,
            oldPassword: "",
            newPassword: "",
          });

          success();
        })
        .catch((err) => {
          if (err.response.data.code === 1010) {
            this.setState({
              oldPasswordError: "Current password is incorrect",
              loading: false,
            });
          } else if (err.response.data.code === 1006) {
            this.setState({
              newPasswordError: err.response.data.message,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });

            fail();
          }
        });
    }
  };

  handleCancel = () => {
    this.setState({ loadingCancel: true, nameError: "", phoneError: "" });
    this.getListing();
  };

  handleClose = () => {
    this.setState({ successAlert: false, errorAlert: false });
  };

  render() {
    return (
      <div className="settings-container">
        <Breadcrumb mainPage="Settings" subPage="Change Password" />
        <div className="sec">
          <SecTitle
            title="Change Password"
            titleDesc="You can change your password as you wish"
          />
          <div className="row m-0 content-container">
            <div className="col p-0">
              <form className="setting-form">
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field">
                    <label htmlFor="oldPassword" className="title ml-0">
                      Current Password
                    </label>
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": this.state.oldPasswordError,
                      })}
                      id="oldPassword"
                      name="oldPassword"
                      value={this.state.oldPassword}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {this.state.oldPasswordError}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group mb-0 p-0 input-field">
                    <label htmlFor="newPassword" className="title ml-0 mb-0">
                      New Password
                    </label>
                    <p className="inform">
                      There should be minimum six characters
                    </p>
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": this.state.newPasswordError,
                      })}
                      id="newPassword"
                      name="newPassword"
                      value={this.state.newPassword}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <div className="invalid-feedback">
                      {this.state.newPasswordError}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row m-0 content-container">
            <div className="col-md p-0">
              <button
                type="button"
                id="location-setting"
                className="btn setting-save-btn float-right"
                onClick={this.handleSave}
              >
                {this.state.loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    style={{ margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loading ? "" : "Save"}
              </button>
              <button
                type="button"
                className="btn setting-cancel-btn float-right"
                onClick={this.handleCancel}
              >
                {this.state.loadingCancel ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "#4f4f4f", margin: 0 }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                {this.state.loadingCancel ? "" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
