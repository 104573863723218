import React, { Component } from "react";

import Logo from "../images/spritzer-logo-black.png";

import "../styles/clients.css";

var timer;

export default class Clients extends Component {
  state = {
    move: 1,
    time: 1,
  };

  componentDidMount = () => {
    if (this.state.move === 1) {
      this.setState({ move: 2 });

      timer = setInterval(this.optTimer, 1000);
    }

    setInterval(() => {
      if (this.state.move === 1) {
        this.setState({ move: 2 });

        timer = setInterval(this.optTimer, 1000);
      } else if (this.state.move === 2) {
        this.setState({ move: 3 });
        timer = setInterval(this.optTimer, 1000);
      } else {
        this.setState({ move: 1 });
        timer = setInterval(this.optTimer, 1000);
      }
    }, 7000);
  };

  optTimer = () => {
    console.log(this.state.time);
    if (this.state.time === 6) {
      clearInterval(timer);
      this.setState({ time: 1 });
    } else {
      this.setState({ time: this.state.time + 1 });
    }
  };

  render() {
    // console.log(this.state.time);
    return (
      <div className="container p-0">
        <div className="row m-0 our-clients-sec">
          <div className="col p-0">
            <div className="clients-heading-div">
              <img src={Logo} alt="logo" className="spritzer-logo" />
              <p className="sub-heading">
                A brand trusted by 100s of restaurants over two years
              </p>
            </div>

            <div className="row m-0">
              <div className="col p-0">
                {this.state.move === 1 ? (
                  <React.Fragment>
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/11.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/2.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/19.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/18.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/5.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/14.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/7.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/15.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/9.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/10.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                  </React.Fragment>
                ) : this.state.move === 2 ? (
                  <React.Fragment>
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/21.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/22.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/23.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/31.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/25.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/26.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/27.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/28.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/29.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/30.png"
                      alt="restaurant"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                      className="client-logo"
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/32.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/33.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/34.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/35.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/36.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/37.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/42.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/39.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/40.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                    <img
                      src="https://static.spritzer.app/assets/partner-logos/grey/41.png"
                      alt="restaurant"
                      className="client-logo"
                      style={{
                        opacity: (this.state.time / 10) * 3,
                        transition: `visibility 0s ${
                          (this.state.time / 10) * 3
                        }s, opacity ${(this.state.time / 10) * 3}s linear`,
                      }}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
